import { Component, OnInit, OnChanges, OnDestroy, AfterViewInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as moment from "moment";
import { MenuItem } from 'primeng/api';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel, ButtonItem, Action, EventModelTyped } from '../../ux-models';
import { ApiOperationType, IApiResponseWrapperTyped, ApiProperties, ApiCall, Query, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { takeUntil } from 'rxjs/operators';
import { CanDoWhat } from 'projects/core-lib/src/lib/models/security';
import { AssetService } from 'projects/core-lib/src/lib/services/asset.service';
import { TableOptions } from '../../table/table-options';
import { TableColumnOptions } from '../../table/table-column-options';
import { IconHelper } from '../../image/icon/icon-helper';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { AlertItemType } from '../../alert/alert-manager';
import { TableHelper } from '../../table/table-helper';
import { Dictionary } from 'projects/core-lib/src/lib/models/dictionary';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { FormGroupFluentModel } from '../../form/form-fluent-model';
import { FormStatusService } from 'projects/core-lib/src/lib/services/form-status.service';
import { FormBaseClass } from '../../form/form-base-class';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'ib-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.css'],
  providers: [FormStatusService]
})
export class FileManagementComponent extends FormBaseClass<m5.AssetListViewModel[]> implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() ownerType: string = null;
  @Input() ownerId: number = null;

  // The attachment secondary owner type (if any)
  @Input() secondaryOwnerType: string = null;
  // The attachment secondary owner id (if any)
  @Input() secondaryOwnerId: number = null;

  @Input() allowUpload: boolean = undefined;       // Flag if uploads are allowed.  Default is true if user has asset add permission.
  @Input() allowDownload: boolean = undefined;     // Flag if downloads are allowed.  Default is true if user has asset read permission.
  @Input() allowView: boolean = undefined;         // Flag if asset view is allowed.  Default is true if user has asset read permission.
  @Input() allowEdit: boolean = undefined;         // Flag if edit is allowed.  Default is true if user has asset edit permission.
  @Input() allowDelete: boolean = undefined;       // Flag if delete is allowed.  Default is true if user has asset delete permission.
  @Input() allowAddLink: boolean = undefined;      // Flag if add link is allowed.  Default is true if user has asset add permission.

  @Input() addLinkButtonIcon: string = "external-link";
  @Input() addLinkButtonText: string = "Add Link";
  @Input() addLinkButtonColor: string = "primary"; // Options are: default, primary, info, success, warning, danger

  @Input() uploadIcon: string = "cloud-upload (solid)";
  @Input() uploadText: string = "<strong>Drop files or click to upload</strong>";
  @Input() uploadButtonIcon: string = "cloud-upload";
  @Input() uploadButtonText: string = "Upload Files";
  @Input() uploadButtonColor: string = "primary"; // Options are: default, primary, info, success, warning, danger
  /**
  This is a comma separated list of mime types or file extensions. e.g.: image/*,application/pdf,.docx.  Defaults to any file type.
  */
  @Input() acceptedFileTypes: string = null;
  /**
  The maximum file size in MB.  Defaults to 100.
  */
  @Input() maxFileSize: number = 100;

  @Input() attachmentCategory: string = null;

  @Input() availableViews: string[] = ["DET", "ICO", "ILG", "IXL", "GAL"]; // DET = Detail, ICO = Icon, ILG = Icon Large, IXL = Icon Extra Large, GAL = Gallery
  @Input() currentView: string = "DET";

  @Output() onUploadSuccess: EventEmitter<EventModel> = new EventEmitter();
  @Output() onUploadError: EventEmitter<EventModel> = new EventEmitter();
  @Output() onEdit: EventEmitter<EventModel> = new EventEmitter();
  @Output() onDelete: EventEmitter<EventModel> = new EventEmitter();

  /**
  Toggle flag if upload component is visible.
  */
  uploadVisible: boolean = false;

  filesTableReloadCount: number = 0;
  filesTableOptions: TableOptions;

  fileContextMenu: MenuItem[] = [];
  fileContextMenuAsset: m5.AssetListViewModel = null;
  fileContactMenuIndex: number = -1;

  galleryImages: any[] = [];
  galleryResponsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  editImage: m5.AssetListViewModel = null;
  editImageOptions: any = {};
  /*
   * After editing the image we need to refresh the img elements but they won't refresh without us cache busting
   * the src so append a counter to the url.  Yes this will cache bust all pictures not just the one edited.
   */
  editImageCounter: number = 0;

  @ViewChild('myDoka') myDoka: any;


  /**
  Attachment data
  */
  query: Query = new Query();
  apiProperties: ApiProperties;
  apiCall: ApiCall;
  loading: boolean = false;
  //public responseScope: m5.IResponseScope = new m5.ResponseScope();

  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected formService: FormStatusService,
    protected assetService: AssetService,
    protected sanitizer: DomSanitizer,
    protected ngbModalService: NgbModal) {

    super(appService, uxService, formService, false, null);

  }


  // If any of these are implemented here they need to call super.x() to get the base class implementation executed.
  ngOnInit() {

    super.ngOnInit();

    this.appService.tryGetUser().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      // Default actions are based on available permissions
      this.setPermissionArea("Asset");
      if (this.allowUpload === undefined) {
        this.allowUpload = this.permissions.add;
      }
      if (this.allowDownload === undefined) {
        this.allowDownload = this.permissions.read;
      }
      if (this.allowView === undefined) {
        this.allowView = this.permissions.read;
      }
      if (this.allowEdit === undefined) {
        this.allowEdit = this.permissions.edit;
      }
      if (this.allowDelete === undefined) {
        this.allowDelete = this.permissions.delete;
      }
      if (this.allowAddLink === undefined) {
        this.allowAddLink = this.permissions.add;
      }
    });

    this.fileContextMenu = this.getFileContextMenu();
    this.filesTableOptions = this.getTableOptions();
    this.query.Size = Constants.RowsToReturn.All;
    this.apiProperties = Api.Asset();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
    // We have our own loading indicator specific to this component so set silent for global loading indicators
    this.apiCall.silent = true;
    this.buildFilter();
    this.load();

  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.buildFilter();
    this.load();
  }

  //ngOnDestroy() {
  //  super.ngOnDestroy();
  //}
  //ngAfterViewInit() {
  //  super.ngAfterViewInit();
  //}

  getTableOptions(): TableOptions {

    const options = new TableOptions();
    options.tableId = null; // no save/load customizations
    options.rowsPerPage = 10;
    options.loadDataFromServer = false;
    options.theme = "striped";

    options.sort = "Title";
    options.columns = [];
    //options.columns.push(new TableColumnOptions("ExternalAssetId", "Id"));
    //options.columns.push(new TableColumnOptions("Title", "Title"));
    options.columns.push(new TableColumnOptions("FriendlyName", "File Name"));
    options.columns.push(new TableColumnOptions("FileType", "File Type", "function"));
    options.columns.slice(-1)[0].render = (row: m5.AssetListViewModel) => {
      const iconDescription = this.assetService.assetIcon(row.FileType, row.AssetType);
      let html = IconHelper.iconDataFromIconDescription(iconDescription, false, true, "", "mr-1").html;
      if (row.AssetType === "U") {
        html += "link";
      } else {
        html += row.FileType || "";
      }
      return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    //options.columns.push(new TableColumnOptions("AssetType", "Asset Type", "picklist", Constants.PickList.__Asset_AssetType));
    TableHelper.setAllColumnFilterType(options.columns, "multiselect");
    options.columns.push(new TableColumnOptions("SizeBytes", "File Size", "function"));
    options.columns.slice(-1)[0].filterType = "none";
    options.columns.slice(-1)[0].render = (row: m5.AssetListViewModel) => {
      if (Helper.equals(row.AssetType, "U", true)) {
        return "link";
      }
      return this.assetService.getSizeDescription(row.FileType, row.AssetType, row.SizeBytes, row.SizeOther, row.SizeInformation, row.Width, row.Height);
    };
    options.columns.push(new TableColumnOptions("AddedDateTime", "Created", "date"));
    options.columns.slice(-1)[0].filterType = "none";

    options.rowActionButton = new ButtonItem("", "bars", "default");
    options.rowActionButton.options.push(new Action("download-or-open", "Download / Open", "download", "", (event) => {
      if (!event || !event.data) {
        Log.errorMessage("Download/Open was called with null event data object.");
        return;
      }
      this.downloadOrOpenFile(event.data);
    }));
    options.rowActionButton.options.push(new Action("divider"));
    options.rowActionButton.options.push(new Action("edit", "Edit", "pencil", "", (event) => {
      if (!event || !event.data) {
        Log.errorMessage("Edit was called with null event data object.");
        return;
      }
      this.editFile(event.data);
    }));
    options.rowActionButton.options.push(new Action("edit-image", "Edit Image", "image", "", (event) => {
      if (!event || !event.data) {
        Log.errorMessage("Edit Image was called with null event data object.");
        return;
      }
      this.editImageOptions = this.getDokaOptions(event.data);
      this.editImage = event.data;
    }));
    options.rowActionButton.options.push(new Action("divider"));
    options.rowActionButton.options.push(new Action("delete", "Delete", "times", "", (event) => {
      if (!event || !event.data) {
        Log.errorMessage("Delete was called with null event data object.");
        return;
      }
      this.deleteFile(event.data);
    }));

    return options;

  }

  getFileContextMenu(): MenuItem[] {

    const downloadFile: MenuItem = {
      label: "Download / Open", icon: "far fa-download fa-fw", command: ($event) => {
        if (!this.fileContextMenuAsset) {
          Log.errorMessage("No file selected.  Please right click on a file.");
          return;
        }
        this.downloadOrOpenFile(this.fileContextMenuAsset);
      }
    };
    const editFile: MenuItem = {
      label: "Edit", icon: "far fa-pencil fa-fw", command: ($event) => {
        if (!this.fileContextMenuAsset) {
          Log.errorMessage("No file selected.  Please right click on a file.");
          return;
        }
        this.editFile(this.fileContextMenuAsset);
      }
    };
    const editImage: MenuItem = {
      label: "Edit Image", icon: "far fa-image fa-fw", command: ($event) => {
        if (!this.fileContextMenuAsset) {
          Log.errorMessage("No file selected.  Please right click on a file.");
          return;
        }
        this.editImageOptions = this.getDokaOptions(this.fileContextMenuAsset);
        this.editImage = this.fileContextMenuAsset;
      }
    };
    const deleteFile: MenuItem = {
      label: "Delete", icon: "far fa-times fa-fw", command: ($event) => {
        if (!this.fileContextMenuAsset) {
          Log.errorMessage("No file selected.  Please right click on a file.");
          return;
        }
        this.deleteFile(this.fileContextMenuAsset);
      }
    };

    const menu: MenuItem[] = [];
    menu.push(downloadFile);
    menu.push({ separator: true });
    menu.push(editFile);
    menu.push(editImage);
    menu.push({ separator: true });
    menu.push(deleteFile);
    return menu;

  }

  protected buildFilter = () => {
    // If we don't have owner type/id binding yet then no filter to build
    if (!this.ownerType || !this.ownerId) {
      return;
    }
    // By default we do not show items that are named identifiers or child assets
    this.query.Filter = `SystemAssetId == "" && ParentAssetId == null && SystemAssetGroup == "Attachment" && OwnerResourceType == "${this.ownerType}" && OwnerResourceId == ${this.ownerId}`;
    if (this.attachmentCategory) {
      this.query.Filter += ` && AssetCategory == "${this.attachmentCategory}"`;
    }
    //console.error("build filter", this.ownerType, this.ownerId, this.query.Filter);
  }

  public load = (cacheIgnore: boolean = false) => {
    if (!this.query.Filter) {
      this.buildFilter();
    }
    // If we don't have a filter defined yet then we have nothing to load
    if (!this.apiCall || !this.query.Filter) {
      return;
    }
    this.apiCall.cacheIgnoreOnRead = cacheIgnore;
    // Get the attachment data
    this.loading = true;
    this.apiService.execute(this.apiCall, this.query).subscribe((response: IApiResponseWrapperTyped<m5.AssetListViewModel[]>) => {
      this.loading = false;
      if (response.Data.Success) {
        this.data = response.Data.Data;
        //console.error("data", this.data);
        this.urlCache = new Dictionary<SafeUrl>();
        this.filesTableReloadCount++;
        this.buildGalleryList();
        // old component kept track of this scope... not sure why
        //this.responseScope = response.Data.Scope;
      } else {
        this.appService.alertManager.addAlertFromApiResponse(response, this.apiCall);
      }
    });
  }

  buildGalleryList() {
    // Now only those that are images make it into our gallery
    this.galleryImages = [];
    this.data.forEach(item => {
      if (this.isImage(item)) {
        this.galleryImages.push({ source: this.getUrlString(item), alt: item.ShortDescription, title: (item.Title || item.FriendlyName) });
      }
    });
    //console.error(this.galleryImages);
  }

  downloadOrOpenFile = (asset: m5.AssetListViewModel) => {
    if (!asset) {
      Log.errorMessage("Download/Open was called with null asset object.");
      return;
    }
    const url = this.getUrlString(asset);
    this.appService.redirectToWebsiteNewTab(url);
  }

  deleteFile = (asset: m5.AssetListViewModel) => {
    if (!asset) {
      Log.errorMessage("Delete was called with null asset object.");
      return;
    }
    const description = Helper.getFirstDefinedStringFromProperties(asset, "Title", "FriendlyName");
    const promise = this.uxService.modal.confirmDelete(`Delete this ${description} file?`, null);
    promise.then((answer) => {
      const apiProp = Api.Asset();
      const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Delete);
      this.apiService.execute(apiCall, asset).subscribe((result: IApiResponseWrapper) => {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        if (result.Data.Success) {
          this.load();
          const payload: EventModel = new EventModel("delete", null, asset);
          this.onDelete.emit(payload);
        }
      });
    }, (cancelled) => {
      // No action
    });
  }

  addLink = () => {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    options.title = "Add Link";

    // Build the form
    const group = new FormGroupFluentModel("block");
    group.HasInputString("Description", "Description", "Link", "Description", "W", true);
    group.HasInputString("Link", "Link", "Link", "Link", "W", true);
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Link: { Description: string, Link: string } } = { Link: { Description: "", Link: "" } };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Link: { Description: string, Link: string } }>) => {
      const asset: m5.AssetEditViewModel = new m5.AssetEditViewModel();
      asset.SystemAssetGroup = "Attachment";
      asset.AssetType = "U";
      asset.OwnerResourceType = this.ownerType;
      asset.OwnerResourceId = this.ownerId;
      asset.SecondaryOwnerResourceType = this.secondaryOwnerType;
      asset.SecondaryOwnerResourceId = this.secondaryOwnerId;
      if (this.attachmentCategory) {
        asset.AssetCategory = this.attachmentCategory;
      }
      asset.ExternalUrl = event.data.Link.Link;
      asset.FriendlyName = event.data.Link.Description;
      asset.Title = event.data.Link.Description;
      const apiProp: ApiProperties = Api.Asset();
      const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
      this.apiService.execute(apiCall, asset).subscribe(response => {
        if (response.Data.Success) {
          this.load();
        } else {
          this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "Unable to save link: " + response.Data.Message, 0);
        }
      });
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }

  editFile = (asset: m5.AssetListViewModel) => {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    if (asset.AssetType === "U") {
      options.title = "Edit Link";
    } else {
      options.title = "Edit File";
    }

    // Build the form
    const group = new FormGroupFluentModel("block");
    if (asset.AssetType == "U") {
      group.HasInputString("Description", "Description", "Asset", "Description", "W", true);
      group.HasInputString("Link", "Link", "Asset", "Link", "W", true);
    } else {
      group.HasInputString("File Name", "File Name", "Asset", "Description", "W", true);
    }
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Asset: { Description: string, Link: string } } = { Asset: { Description: asset.Title, Link: asset.ExternalUrl } };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Asset: { Description: string, Link: string } }>) => {
      const apiProp: ApiProperties = Api.Asset();
      const apiRead: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
      // Our object here is a list object we need the full object from the server for edits
      this.apiService.execute(apiRead, asset.AssetId).subscribe(response => {
        if (response.Data.Success) {
          const model = response.Data.Data;
          if (asset.AssetType == "U") {
            model.ExternalUrl = event.data.Asset.Link;
            model.FriendlyName = event.data.Asset.Description;
            model.Title = event.data.Asset.Description;
          } else {
            model.FriendlyName = event.data.Asset.Description;
            model.Title = event.data.Asset.Description;
          }
          const apiEdit: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Edit);
          this.apiService.execute(apiEdit, model).subscribe(response => {
            if (response.Data.Success) {
              this.load();
              const payload: EventModel = new EventModel("edit", null, response.Data.Data);
              this.onEdit.emit(payload);
            } else {
              this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "Unable to save asset: " + response.Data.Message, 0);
            }
          });
        } else {
          this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "Unable to save asset: " + response.Data.Message, 0);
        }
      });
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }

  protected urlCache: Dictionary<SafeUrl> = new Dictionary<SafeUrl>();
  getUrl = (asset: m5.AssetListViewModel): string | SafeUrl => {
    if (!asset) {
      return "";
    }
    if (this.urlCache.containsKey(`${asset.AssetId}-${this.editImageCounter}`)) {
      return this.urlCache.item(`${asset.AssetId}-${this.editImageCounter}`);
    }
    const url = this.getUrlString(asset);
    const safe = this.sanitizer.bypassSecurityTrustUrl(url);
    this.urlCache.add(`${asset.AssetId}-${this.editImageCounter}`, safe);
    return safe;
  }

  getUrlString = (asset: m5.AssetListViewModel): string => {
    if (!asset) {
      return "";
    }
    let url: string = "";
    if (asset.AssetType === "U") {
      url = asset.ExternalUrl;
      if (url && !url.startsWith("http")) {
        url = "http://" + url;
      }
    } else {
      url = <string>this.assetService.buildFileViewUrl(asset.AssetId, asset.FriendlyName, asset.FileType, false, true, false);
    }
    if (this.editImageCounter) {
      url = ApiHelper.addQueryStringToUrl(url, `count=${this.editImageCounter}`);
    }
    return url;
  }

  isImage(asset: m5.AssetListViewModel): boolean {
    if (!asset) {
      return false;
    }
    return this.assetService.isImage(asset.FileType);
  }

  getIcon(asset: m5.AssetListViewModel): string {
    if (!asset) {
      return "";
    }
    return this.assetService.assetIcon(asset.FileType, asset.AssetType);
  }

  onFileAreaClick($event) {
    // Someone clicked in our context menu area but not a file so clear any file context
    this.fileContextMenuAsset = null;
    this.fileContactMenuIndex = -1;
  }

  onFileClick($event, asset: m5.AssetListViewModel, index: number) {

    if (!asset) {
      this.fileContextMenuAsset = null;
      this.fileContactMenuIndex = -1;
      return;
    }

    if (Helper.htmlEventIsRightMouseButton($event)) {
      this.fileContextMenuAsset = asset;
      this.fileContactMenuIndex = index;
      try {
        // Don't let event propagate up to onFileAreaClick()
        $event.stopPropagation();
        $event.preventDefault();
      } catch (err) {
        //Log.errorMessage(err);
      }
    } else {
      this.fileContextMenuAsset = null;
      this.fileContactMenuIndex = -1;
    }

  }

  fireUploadSuccess($event) {
    //console.error("upload success", $event);
    // Reload data
    this.load(true);
    // Fire registered event handler(s)
    const payload: EventModel = new EventModel("upload", $event);
    this.onUploadSuccess.emit(payload);
  }

  fireUploadError($event) {
    //console.error("upload error", $event);
    // Fire registered event handler(s)
    const payload: EventModel = new EventModel("upload", $event);
    this.onUploadError.emit(payload);
  }





  getDokaOptions(asset: m5.AssetListViewModel): any {

    // doka configuration options
    const options: Object = {
      utils: ['crop', 'filter', 'color', 'markup', 'resize'],
      outputData: true,
      //outputType: imageType,
      //cropAspectRatio: 1,
      //cropAspectRatioOptions: [
      //  {
      //    label: 'Free',
      //    value: null
      //  },
      //  {
      //    label: 'Portrait',
      //    value: 1.5
      //  },
      //  {
      //    label: 'Square',
      //    value: 1
      //  },
      //  {
      //    label: 'Landscape',
      //    value: .75
      //  }
      //]
    }

    return options;

  }

  handleDokaInit = () => {
    //console.log('Doka has initialised', this.myDoka);
  }

  handleDokaConfirm = ($event) => {
    //console.error('data', $event.output.data);
    //console.error('file', $event.output.file);
    //Helper.fileToBase64($event.output.file).then(b64 => {
    //  console.error("base64", b64);
    //});
    this.saveUpdatedImage(this.editImage, $event.output.file);
  }

  handleDokaCancel = () => {
    //console.log('Doka cancel button clicked');
  }

  handleDokaClose = () => {
    //this.enabled = false;
    this.editImage = null;
  }


  saveUpdatedImage(asset: m5.AssetListViewModel, file: Blob) {

    let url: string = <string>this.assetService.buildFileUploadUrl(asset.AssetId, false);

    const form: FormData = new FormData();
    form.append("Data", JSON.stringify({ AssetId: asset.AssetId }));
    form.append("file", file, `${asset.FriendlyName}.${asset.FileType}`);

    ApiHelper.xhrPostForm(url, form).then(result => {
      this.appService.alertManager.addAlertMessage(AlertItemType.Success, "The image has been updated.", 2);
      //console.error("xhr post", result);
      // We need to refresh images to get the changes so call this method to rebuild our list of images
      this.editImageCounter++;
      this.load(true);
    }).catch(error => {
      this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "There was an error saving the updated image.");
      console.error(error);
    });

  }


}
