<ng-container [ngSwitch]="controlModel.ControlType">

  <!-- Rendering control id {{controlModel.FormControlId}} using control type {{controlModel.ControlType}} and option {{controlModel.ControlOption}} -->
  <span *ngIf="true"
        style="display:none;">
    Rendering control id {{controlModel.FormControlId}} using control type
    "{{controlModel.ControlType}}" and option "{{controlModel.ControlOption}}"
  </span>

  <div *ngIf="controlModel.ControlType.startsWith('Input') && controlModel.ControlType !== 'InputStatic' && !controlModel.ObjectName && !controlModel.PropertyName"
       [ngClass]="{ 'design-mode': designModeStyling }">
    <em>Control Id {{controlModel.FormControlId}}: Missing the required <span class="badge badge-danger">ObjectName</span> and <span class="badge badge-danger">PropertyName</span>
      settings.</em>
  </div>
  <div *ngIf="controlModel.ControlType.startsWith('Input') && controlModel.ControlType !== 'InputStatic' && !controlModel.ObjectName && controlModel.PropertyName"
       [ngClass]="{ 'design-mode': designModeStyling }">
    <em>Control Id {{controlModel.FormControlId}}: Missing the required <span class="badge badge-danger">ObjectName</span> setting.</em>
  </div>
  <div *ngIf="controlModel.ControlType.startsWith('Input') && controlModel.ControlType !== 'InputStatic' && !controlModel.PropertyName && controlModel.ObjectName"
       [ngClass]="{ 'design-mode': designModeStyling }">
    <em>Control Id {{controlModel.FormControlId}}: Missing the required <span class="badge badge-danger">PropertyName</span> setting.</em>
  </div>

  <ng-container *ngSwitchCase="'Text'"
                [ngSwitch]="controlModel.ControlOption">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <h1 *ngSwitchCase="'1'"
          class="{{controlModel.ContainerClasses}}"
          [ngClass]="{ 'design-mode': designModeStyling }"
          [style]="controlModel.ContainerStyles | safe : 'style'">
        {{getContents()}}
      </h1>
      <h2 *ngSwitchCase="'2'"
          class="{{controlModel.ContainerClasses}}"
          [ngClass]="{ 'design-mode': designModeStyling }"
          [style]="controlModel.ContainerStyles | safe : 'style'">
        {{getContents()}}
      </h2>
      <h3 *ngSwitchCase="'3'"
          class="{{controlModel.ContainerClasses}}"
          [ngClass]="{ 'design-mode': designModeStyling }"
          [style]="controlModel.ContainerStyles | safe : 'style'">
        {{getContents()}}
      </h3>
      <h4 *ngSwitchCase="'4'"
          class="{{controlModel.ContainerClasses}}"
          [ngClass]="{ 'design-mode': designModeStyling }"
          [style]="controlModel.ContainerStyles | safe : 'style'">
        {{getContents()}}
      </h4>
      <h5 *ngSwitchCase="'5'"
          class="{{controlModel.ContainerClasses}}"
          [ngClass]="{ 'design-mode': designModeStyling }"
          [style]="controlModel.ContainerStyles | safe : 'style'">
        {{getContents()}}
      </h5>
      <h6 *ngSwitchCase="'6'"
          class="{{controlModel.ContainerClasses}}"
          [ngClass]="{ 'design-mode': designModeStyling }"
          [style]="controlModel.ContainerStyles | safe : 'style'">
        {{getContents()}}
      </h6>
      <p *ngSwitchDefault
         class="{{controlModel.ContainerClasses}}"
         [ngClass]="{ 'design-mode': designModeStyling }"
         [style]="controlModel.ContainerStyles | safe : 'style'">
        {{getContents()}}
      </p>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'HTML'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <div [innerHtml]="getContents() | safe: 'html'"
           class="{{controlModel.ContainerClasses}}"
           [ngClass]="{ 'design-mode': designModeStyling }"></div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputText'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-text name="{{getInputControlName()}}"
                     label="{{controlModel.Label}}"
                     labelPrefix="{{controlModel.LabelPrefix}}"
                     labelSuffix="{{controlModel.LabelSuffix}}"
                     placeholder="{{controlModel.Watermark}}"
                     tooltip="{{controlModel.HelpText}}"
                     format="{{controlModel.Format}}"
                     width="{{controlModel.Width}}"
                     outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                     labelClass="{{controlModel.LabelClasses}}"
                     controlClass="{{controlModel.ControlClasses}}"
                     type="{{controlModel.PropertyDataType.toLowerCase()}}"
                     optionsPickListId="{{controlModel.OptionsPickListId}}"
                     optionsPickListFilter="{{optionsPickListFilter}}"
                     [optionsPickList]="controlModel.OptionsPickList"
                     characterCountTemplate="{{controlModel.CharacterCountTemplate}}"
                     [minlength]="controlModel.Minimum"
                     [maxlength]="controlModel.Maximum"
                     [vertical]="controlModel.LabelLayout === 'T'"
                     [bold]="controlModel.LabelBold"
                     [tight]="groupModel.ControlLayoutTight"
                     [required]="controlModel.Required"
                     [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                     [readonly]="controlModel.ReadOnly || formIsReadOnly"
                     [disabled]="controlModel.Disabled"
                     [autofocus]="controlModel.AutoFocus"
                     [standalone]="controlModel.Standalone"
                     errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                     errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                     errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                     errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                     errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                     (status)="onStatusChange($event)"
                     [ngModel]="getModel()"
                     (ngModelChange)="onModelChange($event)">
      </ib-input-text>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputTextArea'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-textarea name="{{getInputControlName()}}"
                         label="{{controlModel.Label}}"
                         labelPrefix="{{controlModel.LabelPrefix}}"
                         labelSuffix="{{controlModel.LabelSuffix}}"
                         placeholder="{{controlModel.Watermark}}"
                         tooltip="{{controlModel.HelpText}}"
                         format="{{controlModel.Format}}"
                         width="{{controlModel.Width}}"
                         outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                         labelClass="{{controlModel.LabelClasses}}"
                         controlClass="{{controlModel.ControlClasses}}"
                         characterCountTemplate="{{controlModel.CharacterCountTemplate}}"
                         [fullWidth]="controlModel.Width==='F'"
                         [rows]="controlModel.Height || 5"
                         [minlength]="controlModel.Minimum"
                         [maxlength]="controlModel.Maximum"
                         [vertical]="controlModel.LabelLayout === 'T'"
                         [bold]="controlModel.LabelBold"
                         [tight]="groupModel.ControlLayoutTight"
                         [required]="controlModel.Required"
                         [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                         [readonly]="controlModel.ReadOnly || formIsReadOnly"
                         [disabled]="controlModel.Disabled"
                         [autofocus]="controlModel.AutoFocus"
                         [standalone]="controlModel.Standalone"
                         errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                         errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                         errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                         errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                         errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                         (status)="onStatusChange($event)"
                         [ngModel]="getModel()"
                         (ngModelChange)="onModelChange($event)">
      </ib-input-textarea>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputRichText'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-rich-text name="{{getInputControlName()}}"
                          label="{{controlModel.Label}}"
                          labelPrefix="{{controlModel.LabelPrefix}}"
                          labelSuffix="{{controlModel.LabelSuffix}}"
                          placeholder="{{controlModel.Watermark}}"
                          tooltip="{{controlModel.HelpText}}"
                          format="{{controlModel.Format}}"
                          width="{{controlModel.Width}}"
                          outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                          labelClass="{{controlModel.LabelClasses}}"
                          controlClass="{{controlModel.ControlClasses}}"
                          characterCountTemplate="{{controlModel.CharacterCountTemplate}}"
                          [fullWidth]="controlModel.Width==='F'"
                          [rows]="controlModel.Height || 5"
                          [minlength]="controlModel.Minimum"
                          [maxlength]="controlModel.Maximum"
                          [vertical]="controlModel.LabelLayout === 'T'"
                          [bold]="controlModel.LabelBold"
                          [tight]="groupModel.ControlLayoutTight"
                          [required]="controlModel.Required"
                          [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                          [readonly]="controlModel.ReadOnly || formIsReadOnly"
                          [disabled]="controlModel.Disabled"
                          [autofocus]="controlModel.AutoFocus"
                          [standalone]="controlModel.Standalone"
                          errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                          errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                          errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                          errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                          errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                          [uploadImageAssets]="false"
                          (status)="onStatusChange($event)"
                          [ngModel]="getModel()"
                          (ngModelChange)="onModelChange($event)">
        <!-- [uploadImageAssets]="true"
             systemAssetGroup="Attachment"
             assetOwnerResourceType="HelpLink"
             [assetOwnerResourceId]="helpLink.HelpLinkId" -->
      </ib-input-rich-text>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputStringList'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-string-list name="{{getInputControlName()}}"
                            label="{{controlModel.Label}}"
                            labelPrefix="{{controlModel.LabelPrefix}}"
                            labelSuffix="{{controlModel.LabelSuffix}}"
                            placeholder="{{controlModel.Watermark}}"
                            tooltip="{{controlModel.HelpText}}"
                            format="{{controlModel.Format}}"
                            width="{{controlModel.Width}}"
                            outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                            labelClass="{{controlModel.LabelClasses}}"
                            controlClass="{{controlModel.ControlClasses}}"
                            characterCountTemplate="{{controlModel.CharacterCountTemplate}}"
                            headerText="{{controlModel.Label}}"
                            addButtonIcon="plus"
                            itemLabel="{{controlModel.Watermark}}"
                            [itemRows]="controlModel.Height || 1"
                            [canBulkAdd]="controlModel.Flags.includes('canBulkAdd')"
                            [canBulkEdit]="controlModel.Flags.includes('canBulkEdit')"
                            [minlength]="controlModel.Minimum"
                            [maxlength]="controlModel.Maximum"
                            [vertical]="controlModel.LabelLayout === 'T'"
                            [bold]="controlModel.LabelBold"
                            [tight]="groupModel.ControlLayoutTight"
                            [required]="controlModel.Required"
                            [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                            [readonly]="controlModel.ReadOnly || formIsReadOnly"
                            [disabled]="controlModel.Disabled"
                            [autofocus]="controlModel.AutoFocus"
                            [standalone]="controlModel.Standalone"
                            errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                            errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                            errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                            errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                            errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                            (status)="onStatusChange($event)"
                            [ngModel]="getModel()"
                            (ngModelChange)="onModelChange($event)">
      </ib-input-string-list>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputNumber'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-number name="{{getInputControlName()}}"
                       label="{{controlModel.Label}}"
                       labelPrefix="{{controlModel.LabelPrefix}}"
                       labelSuffix="{{controlModel.LabelSuffix}}"
                       placeholder="{{controlModel.Watermark}}"
                       tooltip="{{controlModel.HelpText}}"
                       format="{{controlModel.Format}}"
                       width="{{controlModel.Width}}"
                       outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                       labelClass="{{controlModel.LabelClasses}}"
                       controlClass="{{controlModel.ControlClasses}}"
                       type="{{controlModel.PropertyDataType.toLowerCase()}}"
                       [min]="controlModel.Minimum"
                       [max]="controlModel.Maximum"
                       [vertical]="controlModel.LabelLayout === 'T'"
                       [bold]="controlModel.LabelBold"
                       [tight]="groupModel.ControlLayoutTight"
                       [required]="controlModel.Required"
                       [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                       [readonly]="controlModel.ReadOnly || formIsReadOnly"
                       [disabled]="controlModel.Disabled"
                       [autofocus]="controlModel.AutoFocus"
                       [standalone]="controlModel.Standalone"
                       errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                       errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                       errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                       errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                       errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                       (status)="onStatusChange($event)"
                       [ngModel]="getModel()"
                       (ngModelChange)="onModelChange($event)">
      </ib-input-number>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputCurrency'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-currency name="{{getInputControlName()}}"
                         label="{{controlModel.Label}}"
                         labelPrefix="{{controlModel.LabelPrefix}}"
                         labelSuffix="{{controlModel.LabelSuffix}}"
                         placeholder="{{controlModel.Watermark}}"
                         tooltip="{{controlModel.HelpText}}"
                         format="{{controlModel.Format}}"
                         width="{{controlModel.Width}}"
                         outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                         labelClass="{{controlModel.LabelClasses}}"
                         controlClass="{{controlModel.ControlClasses}}"
                         [min]="controlModel.Minimum"
                         [max]="controlModel.Maximum"
                         [vertical]="controlModel.LabelLayout === 'T'"
                         [bold]="controlModel.LabelBold"
                         [tight]="groupModel.ControlLayoutTight"
                         [required]="controlModel.Required"
                         [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                         [readonly]="controlModel.ReadOnly || formIsReadOnly"
                         [disabled]="controlModel.Disabled"
                         [autofocus]="controlModel.AutoFocus"
                         [standalone]="controlModel.Standalone"
                         errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                         errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                         errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                         errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                         errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                         [isoCurrencyCode]="getDescriptionPropertyValue()"
                         (status)="onStatusChange($event)"
                         [ngModel]="getModel()"
                         (ngModelChange)="onModelChange($event)">
      </ib-input-currency>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputPercent'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-percent name="{{getInputControlName()}}"
                        label="{{controlModel.Label}}"
                        labelPrefix="{{controlModel.LabelPrefix}}"
                        labelSuffix="{{controlModel.LabelSuffix}}"
                        placeholder="{{controlModel.Watermark}}"
                        tooltip="{{controlModel.HelpText}}"
                        format="{{controlModel.Format}}"
                        width="{{controlModel.Width}}"
                        outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                        labelClass="{{controlModel.LabelClasses}}"
                        controlClass="{{controlModel.ControlClasses}}"
                        [min]="controlModel.Minimum"
                        [max]="controlModel.Maximum"
                        [vertical]="controlModel.LabelLayout === 'T'"
                        [bold]="controlModel.LabelBold"
                        [tight]="groupModel.ControlLayoutTight"
                        [required]="controlModel.Required"
                        [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                        [readonly]="controlModel.ReadOnly || formIsReadOnly"
                        [disabled]="controlModel.Disabled"
                        [autofocus]="controlModel.AutoFocus"
                        [standalone]="controlModel.Standalone"
                        errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                        errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                        errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                        errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                        errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                        (status)="onStatusChange($event)"
                        [ngModel]="getModel()"
                        (ngModelChange)="onModelChange($event)">
      </ib-input-percent>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputDateTime'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-date name="{{getInputControlName()}}"
                     label="{{controlModel.Label}}"
                     labelPrefix="{{controlModel.LabelPrefix}}"
                     labelSuffix="{{controlModel.LabelSuffix}}"
                     placeholder="{{controlModel.Watermark}}"
                     tooltip="{{controlModel.HelpText}}"
                     format="{{controlModel.Format}}"
                     width="{{controlModel.Width}}"
                     outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                     labelClass="{{controlModel.LabelClasses}}"
                     controlClass="{{controlModel.ControlClasses}}"
                     type="{{controlModel.PropertyDataType.toLowerCase()}}"
                     [vertical]="controlModel.LabelLayout === 'T'"
                     [bold]="controlModel.LabelBold"
                     [tight]="groupModel.ControlLayoutTight"
                     [required]="controlModel.Required"
                     [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                     [readonly]="controlModel.ReadOnly || formIsReadOnly"
                     [disabled]="controlModel.Disabled"
                     [autofocus]="controlModel.AutoFocus"
                     [standalone]="controlModel.Standalone"
                     errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                     errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                     errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                     errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                     errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                     (status)="onStatusChange($event)"
                     [ngModel]="getModel()"
                     (ngModelChange)="onModelChange($event)">
      </ib-input-date>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputSelect'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-select name="{{getInputControlName()}}"
                       label="{{controlModel.Label}}"
                       labelPrefix="{{controlModel.LabelPrefix}}"
                       labelSuffix="{{controlModel.LabelSuffix}}"
                       placeholder="{{controlModel.Watermark}}"
                       tooltip="{{controlModel.HelpText}}"
                       format="{{controlModel.Format}}"
                       width="{{controlModel.Width}}"
                       outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                       labelClass="{{controlModel.LabelClasses}}"
                       controlClass="{{controlModel.ControlClasses}}"
                       [optionsPickList]="controlModel.OptionsPickList"
                       optionsPickListId="{{controlModel.OptionsPickListId}}"
                       optionsPickListFilter="{{optionsPickListFilter}}"
                       [optionsIncludeNone]="controlModel.OptionsIncludeNone"
                       [optionsValueIsInteger]="controlModel.OptionsValueIsInteger"
                       optionsNoneLabel="{{controlModel.OptionsNoneLabel || '<None>'}}"
                       [vertical]="controlModel.LabelLayout === 'T'"
                       [bold]="controlModel.LabelBold"
                       [tight]="groupModel.ControlLayoutTight"
                       [required]="controlModel.Required"
                       [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                       [readonly]="controlModel.ReadOnly || formIsReadOnly"
                       [disabled]="controlModel.Disabled"
                       [autofocus]="controlModel.AutoFocus"
                       [standalone]="controlModel.Standalone"
                       errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                       errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                       errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                       errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                       errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                       (status)="onStatusChange($event)"
                       [ngModel]="getModel()"
                       (ngModelChange)="onModelChange($event)">
      </ib-input-select>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputMultiSelect'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-multiselect name="{{getInputControlName()}}"
                            label="{{controlModel.Label}}"
                            labelPrefix="{{controlModel.LabelPrefix}}"
                            labelSuffix="{{controlModel.LabelSuffix}}"
                            placeholder="{{controlModel.Watermark}}"
                            tooltip="{{controlModel.HelpText}}"
                            format="{{controlModel.Format}}"
                            width="{{controlModel.Width}}"
                            outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                            labelClass="{{controlModel.LabelClasses}}"
                            controlClass="{{controlModel.ControlClasses}}"
                            [optionsPickList]="controlModel.OptionsPickList"
                            optionsPickListId="{{controlModel.OptionsPickListId}}"
                            optionsPickListFilter="{{optionsPickListFilter}}"
                            [optionsIncludeNone]="controlModel.OptionsIncludeNone"
                            optionsNoneLabel="{{controlModel.OptionsNoneLabel || '<None>'}}"
                            [vertical]="controlModel.LabelLayout === 'T'"
                            [bold]="controlModel.LabelBold"
                            [tight]="groupModel.ControlLayoutTight"
                            [required]="controlModel.Required"
                            [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                            [readonly]="controlModel.ReadOnly || formIsReadOnly"
                            [disabled]="controlModel.Disabled"
                            [autofocus]="controlModel.AutoFocus"
                            [standalone]="controlModel.Standalone"
                            errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                            errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                            errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                            errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                            errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                            (status)="onStatusChange($event)"
                            [ngModel]="getModel()"
                            (ngModelChange)="onModelChange($event)">
      </ib-input-multiselect>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputListBox'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-list-box name="{{getInputControlName()}}"
                   label="{{controlModel.Label}}"
                   labelPrefix="{{controlModel.LabelPrefix}}"
                   labelSuffix="{{controlModel.LabelSuffix}}"
                   placeholder="{{controlModel.Watermark}}"
                   tooltip="{{controlModel.HelpText}}"
                   format="{{controlModel.Format}}"
                   width="{{controlModel.Width}}"
                   outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                   labelClass="{{controlModel.LabelClasses}}"
                   controlClass="{{controlModel.ControlClasses}}"
                   [optionsPickList]="controlModel.OptionsPickList"
                   optionsPickListId="{{controlModel.OptionsPickListId}}"
                   optionsPickListFilter="{{optionsPickListFilter}}"
                   [optionsIncludeNone]="controlModel.OptionsIncludeNone"
                   [optionsValueIsInteger]="controlModel.OptionsValueIsInteger"
                   optionsNoneLabel="{{controlModel.OptionsNoneLabel || '<None>'}}"
                   [vertical]="controlModel.LabelLayout === 'T'"
                   [bold]="controlModel.LabelBold"
                   [tight]="groupModel.ControlLayoutTight"
                   [required]="controlModel.Required"
                   [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                   [readonly]="controlModel.ReadOnly || formIsReadOnly"
                   [disabled]="controlModel.Disabled"
                   [autofocus]="controlModel.AutoFocus"
                   [standalone]="controlModel.Standalone"
                   errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                   errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                   errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                   errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                   errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                   [multiple]="false"
                   [checkbox]="false"
                   [showDescription]="true"
                   (status)="onStatusChange($event)"
                   [ngModel]="getModel()"
                   (ngModelChange)="onModelChange($event)">
        <!-- TODO get multiple, checkbox, and showDescription from controlModel extended properties instead of hardcoding -->
      </ib-list-box>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputCheckbox'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-checkbox name="{{getInputControlName()}}"
                         label="{{controlModel.Label}}"
                         labelPrefix="{{controlModel.LabelPrefix}}"
                         labelSuffix="{{controlModel.LabelSuffix}}"
                         placeholder="{{controlModel.Watermark}}"
                         tooltip="{{controlModel.HelpText}}"
                         format="{{controlModel.Format}}"
                         width="{{controlModel.Width}}"
                         outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                         labelClass="{{controlModel.LabelClasses}}"
                         controlClass="{{controlModel.ControlClasses}}"
                         [vertical]="controlModel.LabelLayout === 'T'"
                         [bold]="controlModel.LabelBold"
                         [tight]="groupModel.ControlLayoutTight"
                         [required]="controlModel.Required"
                         [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                         [readonly]="controlModel.ReadOnly || formIsReadOnly"
                         [disabled]="controlModel.Disabled"
                         [autofocus]="controlModel.AutoFocus"
                         [standalone]="controlModel.Standalone"
                         errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                         errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                         errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                         errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                         errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                         (status)="onStatusChange($event)"
                         [ngModel]="getModel()"
                         (ngModelChange)="onModelChange($event)">
      </ib-input-checkbox>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputRadio'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-radio name="{{getInputControlName()}}"
                      label="{{controlModel.Label}}"
                      labelPrefix="{{controlModel.LabelPrefix}}"
                      labelSuffix="{{controlModel.LabelSuffix}}"
                      placeholder="{{controlModel.Watermark}}"
                      tooltip="{{controlModel.HelpText}}"
                      format="{{controlModel.Format}}"
                      width="{{controlModel.Width}}"
                      outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                      labelClass="{{controlModel.LabelClasses}}"
                      controlClass="{{controlModel.ControlClasses}}"
                      [inline]="controlModel.ControlOption === 'H'"
                      [vertical]="controlModel.LabelLayout === 'T'"
                      [bold]="controlModel.LabelBold"
                      [tight]="groupModel.ControlLayoutTight"
                      [required]="controlModel.Required"
                      [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                      [readonly]="controlModel.ReadOnly || formIsReadOnly"
                      [disabled]="controlModel.Disabled"
                      [autofocus]="controlModel.AutoFocus"
                      [optionsPickList]="controlModel.OptionsPickList"
                      optionsPickListId="{{controlModel.OptionsPickListId}}"
                      optionsPickListFilter="{{optionsPickListFilter}}"
                      [optionsIncludeNone]="controlModel.OptionsIncludeNone"
                      [optionsValueIsInteger]="controlModel.OptionsValueIsInteger"
                      optionsNoneLabel="{{controlModel.OptionsNoneLabel || '<None>'}}"
                      [standalone]="controlModel.Standalone"
                      errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                      errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                      errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                      errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                      errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                      (status)="onStatusChange($event)"
                      [ngModel]="getModel()"
                      (ngModelChange)="onModelChange($event)">
      </ib-input-radio>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputTags'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-tags name="{{getInputControlName()}}"
                     label="{{controlModel.Label}}"
                     labelPrefix="{{controlModel.LabelPrefix}}"
                     labelSuffix="{{controlModel.LabelSuffix}}"
                     placeholder="{{controlModel.Watermark}}"
                     tooltip="{{controlModel.HelpText}}"
                     width="{{controlModel.Width}}"
                     outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                     labelClass="{{controlModel.LabelClasses}}"
                     controlClass="{{controlModel.ControlClasses}}"
                     optionsPickListId="___Tag_Tag"
                     optionsPickListFilter='OwnerResourceType == "{{controlModel.ObjectName}}"'
                     [vertical]="controlModel.LabelLayout === 'T'"
                     [bold]="controlModel.LabelBold"
                     [tight]="groupModel.ControlLayoutTight"
                     [required]="controlModel.Required"
                     [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                     [readonly]="controlModel.ReadOnly || formIsReadOnly"
                     [disabled]="controlModel.Disabled"
                     [autofocus]="controlModel.AutoFocus"
                     [standalone]="controlModel.Standalone"
                     errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                     errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                     errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                     errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                     errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                     (status)="onStatusChange($event)"
                     [ngModel]="getModel()"
                     (ngModelChange)="onModelChange($event)">
      </ib-input-tags>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputIcon'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-wrapper label="{{controlModel.Label}}"
                        labelPrefix="{{controlModel.LabelPrefix}}"
                        labelSuffix="{{controlModel.LabelSuffix}}">
        <ib-icon-picker [icon]="getModel()"
                        (change)="onModelChange($event)">
        </ib-icon-picker>
      </ib-input-wrapper>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputColor'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-wrapper label="{{controlModel.Label}}"
                        labelPrefix="{{controlModel.LabelPrefix}}"
                        labelSuffix="{{controlModel.LabelSuffix}}"
                        outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}">
        <ib-context-color-picker name="{{getInputControlName()}}"
                                 text="{{controlModel.Label}}"
                                 size="small"
                                 [color]="getModel()"
                                 [optionsIncludeNone]="true"
                                 optionsNoneLabel="<None>"
                                 (change)="onModelChange($event)">
        </ib-context-color-picker>
      </ib-input-wrapper>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputContact'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-contact name="{{getInputControlName()}}"
                        label="{{controlModel.Label}}"
                        labelPrefix="{{controlModel.LabelPrefix}}"
                        labelSuffix="{{controlModel.LabelSuffix}}"
                        placeholder="{{controlModel.Watermark}}"
                        tooltip="{{controlModel.HelpText}}"
                        width="{{controlModel.Width}}"
                        outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                        labelClass="{{controlModel.LabelClasses}}"
                        controlClass="{{controlModel.ControlClasses}}"
                        [vertical]="controlModel.LabelLayout === 'T'"
                        [bold]="controlModel.LabelBold"
                        [tight]="groupModel.ControlLayoutTight"
                        [required]="controlModel.Required"
                        [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                        [readonly]="controlModel.ReadOnly || formIsReadOnly"
                        [disabled]="controlModel.Disabled"
                        [autofocus]="controlModel.AutoFocus"
                        [standalone]="controlModel.Standalone"
                        errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                        errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                        errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                        errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                        errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                        (status)="onStatusChange($event)"
                        (change)="onContactSelected($event)"
                        [data]="getContactPickerData()"
                        [contactTypes]="getContactPickerContactTypes()">
      </ib-input-contact>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputAsset'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-asset name="{{getInputControlName()}}"
                      label="{{controlModel.Label}}"
                      labelPrefix="{{controlModel.LabelPrefix}}"
                      labelSuffix="{{controlModel.LabelSuffix}}"
                      placeholder="{{controlModel.Watermark}}"
                      tooltip="{{controlModel.HelpText}}"
                      width="{{controlModel.Width}}"
                      outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                      labelClass="{{controlModel.LabelClasses}}"
                      controlClass="{{controlModel.ControlClasses}}"
                      [vertical]="controlModel.LabelLayout === 'T'"
                      [bold]="controlModel.LabelBold"
                      [tight]="groupModel.ControlLayoutTight"
                      [required]="controlModel.Required"
                      [includeRequiredIcon]="controlModel.IncludeRequiredIcon"
                      [readonly]="controlModel.ReadOnly || formIsReadOnly"
                      [disabled]="controlModel.Disabled"
                      [autofocus]="controlModel.AutoFocus"
                      [standalone]="controlModel.Standalone"
                      errorRequiredMessage="{{controlModel.ErrorMessageRequired}}"
                      errorMinimumLengthMessage="{{controlModel.ErrorMessageMinimum}}"
                      errorMaximumLengthMessage="{{controlModel.ErrorMessageMaximum}}"
                      errorInvalidFormatMessage="{{controlModel.ErrorMessageInvalidFormat}}"
                      errorOtherMessage="{{controlModel.ErrorMessageOther}}"
                      [filter]="optionsPickListFilter"
                      (status)="onStatusChange($event)"
                      (change)="onAssetSelected($event)"
                      [data]="getAssetPickerData()">
      </ib-input-asset>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'InputStatic'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <ib-input-static name="{{getInputControlName()}}"
                       label="{{controlModel.Label}}"
                       labelPrefix="{{controlModel.LabelPrefix}}"
                       labelSuffix="{{controlModel.LabelSuffix}}"
                       placeholder="{{controlModel.Watermark}}"
                       tooltip="{{controlModel.HelpText}}"
                       format="{{controlModel.Format}}"
                       width="{{controlModel.Width}}"
                       outerClass="{{designModeStyling ? 'form-design-mode-control' : ''}}"
                       labelClass="{{controlModel.LabelClasses}}"
                       controlClass="{{controlModel.ControlClasses}}"
                       [bold]="controlModel.LabelBold"
                       [vertical]="controlModel.LabelLayout === 'T'"
                       [tight]="groupModel.ControlLayoutTight">
        <div [innerHtml]="getContents() | safe: 'html'"></div>
      </ib-input-static>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'FormButtons'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <div class="row"
           [ngClass]="{ 'design-mode': designModeStyling }">
        <div class="{{Constants.Layout.percent33Offset}} {{Constants.Layout.percent66}}">
          <ib-button *ngIf="mode === 'add' && (formModel.AddSaveButtonLabel || formModel.AddSaveButtonIcon)"
                     name="Save"
                     icon="{{formModel.AddSaveButtonIcon}}"
                     label="{{formModel.AddSaveButtonLabel}}"
                     tooltip="{{formModel.AddSaveButtonHelpText}}"
                     [color]="formModel.AddSaveButtonColor || 'primary'"
                     [outline]="formModel.AddSaveButtonOutline"
                     [displayErrorMessages]="false"
                     [disableWhenPristine]="formModel.AddSaveButtonDisableWhenPristine"
                     [disableWhenInvalid]="formModel.AddSaveButtonDisableWhenInvalid"
                     [disableWhenWorking]="formModel.AddSaveButtonDisableWhenWorking"
                     [workingLabel]="formModel.AddSaveButtonLabelWhenWorking"
                     [workingIcon]="formModel.AddSaveButtonIconWhenWorking"
                     [workingIconAnimation]="formModel.AddSaveButtonIconAnimationWhenWorking"
                     [working]="working"
                     (click)="onAddSave($event)">
          </ib-button>
          <ib-button *ngIf="mode !== 'add' && (formModel.EditSaveButtonLabel || formModel.EditSaveButtonIcon)"
                     name="Save"
                     icon="{{formModel.EditSaveButtonIcon}}"
                     label="{{formModel.EditSaveButtonLabel}}"
                     tooltip="{{formModel.EditSaveButtonHelpText}}"
                     [color]="formModel.EditSaveButtonColor || 'primary'"
                     [outline]="formModel.EditSaveButtonOutline"
                     [displayErrorMessages]="false"
                     [disableWhenPristine]="formModel.EditSaveButtonDisableWhenPristine"
                     [disableWhenInvalid]="formModel.EditSaveButtonDisableWhenInvalid"
                     [disableWhenWorking]="formModel.EditSaveButtonDisableWhenWorking"
                     [workingLabel]="formModel.EditSaveButtonLabelWhenWorking"
                     [workingIcon]="formModel.EditSaveButtonIconWhenWorking"
                     [workingIconAnimation]="formModel.EditSaveButtonIconAnimationWhenWorking"
                     [working]="working"
                     (click)="onEditSave($event)">
          </ib-button>
          &nbsp;&nbsp;
          <ib-button *ngIf="mode === 'add' && (formModel.AddCancelButtonLabel || formModel.AddCancelButtonIcon)"
                     name="Close"
                     icon="{{formModel.AddCancelButtonIcon}}"
                     label="{{formModel.AddCancelButtonLabel}}"
                     tooltip="{{formModel.AddCancelButtonHelpText}}"
                     [color]="formModel.AddCancelButtonColor || 'default'"
                     [outline]="formModel.AddCancelButtonOutline"
                     [disableWhenPristine]="false"
                     [disableWhenInvalid]="false"
                     [disableWhenWorking]="formModel.AddCancelButtonDisableWhenWorking"
                     [working]="working"
                     (click)="onAddClose($event)">
          </ib-button>
          <ib-button *ngIf="mode !== 'add' && (formModel.EditCancelButtonLabel || formModel.EditCancelButtonIcon)"
                     name="Close"
                     icon="{{formModel.EditCancelButtonIcon}}"
                     label="{{formModel.EditCancelButtonLabel}}"
                     tooltip="{{formModel.EditCancelButtonHelpText}}"
                     [color]="formModel.EditCancelButtonColor || 'default'"
                     [outline]="formModel.EditCancelButtonOutline"
                     [disableWhenPristine]="false"
                     [disableWhenInvalid]="false"
                     [disableWhenWorking]="formModel.EditCancelButtonDisableWhenWorking"
                     [working]="working"
                     (click)="onEditClose($event)">
          </ib-button>
        </div>
      </div>
      <div class="row"
           [ngClass]="{ 'design-mode': designModeStyling }"
           *ngIf="(mode === 'add' && (formModel.AddSaveButtonLabel || formModel.AddSaveButtonIcon)) || (formModel.EditSaveButtonLabel || formModel.EditSaveButtonIcon)">
        <div class="{{Constants.Layout.percent33Offset}} {{Constants.Layout.percent66}}">
          <ib-form-status-error-output [formStatus]="formStatus"
                                       [errorMessageRequiredSingle]="formModel.ErrorMessageRequiredSingle"
                                       [errorMessageRequiredMultiple]="formModel.ErrorMessageRequiredMultiple"
                                       [errorMessageMinimumLengthSingle]="formModel.ErrorMessageMinimumLengthSingle"
                                       [errorMessageMinimumLengthMultiple]="formModel.ErrorMessageMinimumLengthMultiple"
                                       [errorMessageMaximumLengthSingle]="formModel.ErrorMessageMaximumLengthSingle"
                                       [errorMessageMaximumLengthMultiple]="formModel.ErrorMessageMaximumLengthMultiple"
                                       [errorMessageInvalidFormatSingle]="formModel.ErrorMessageInvalidFormatSingle"
                                       [errorMessageInvalidFormatMultiple]="formModel.ErrorMessageInvalidFormatMultiple"
                                       [errorMessageOtherSingle]="formModel.ErrorMessageOtherSingle"
                                       [errorMessageOtherMultiple]="formModel.ErrorMessageOtherMultiple">
          </ib-form-status-error-output>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'Component'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <div class="row"
           [ngClass]="{ 'design-mode': designModeStyling }">
        <div class="{{Constants.Layout.fullWidth}}"
             #hostComponent>
          <!-- component markup will be injected here -->
        </div>
        <div class="{{Constants.Layout.fullWidth}}"
             *ngIf="!dynamicComponent">
          <span *ngIf="!designModeStyling"
                class="badge badge-info m-1 p-2">Loading {{controlModel.Contents}}...</span>
          <span *ngIf="designModeStyling"
                class="badge badge-info m-1 p-2">Component '{{controlModel.Contents}}' Placeholder</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'Editor'">
    <ng-container *ngIf="controlModel.MetaData?.Properties?.Visible">
      <div *ngIf="designModeStyling && !designModeControlActions"
           class="design-mode-icon"><i class="far fa-cog clickable"
           (click)="onDesignModeEditControlProperties($event)"></i></div>
      <div *ngIf="designModeStyling && designModeControlActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeControlActions"
                          [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
      </div>
      <div class="row"
           [ngClass]="{ 'design-mode': designModeStyling }">
        <div class="{{Constants.Layout.fullWidth}}">
          <ib-dynamic-editor [formId]="controlModel.EmbeddedFormId"
                             [mode]="mode"
                             [properties]="controlModel.Properties">
          </ib-dynamic-editor>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngSwitchDefault>
    <div *ngIf="designModeStyling && !designModeControlActions"
         class="design-mode-icon"><i class="far fa-cog clickable"
         (click)="onDesignModeEditControlProperties($event)"></i></div>
    <div *ngIf="designModeStyling && designModeControlActions"
         class="design-mode-icon">
      <ib-action-button [button]="designModeControlActions"
                        [data]="{ control: controlModel , group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
    </div>
    <em>Control Id {{controlModel.FormControlId || controlModel.PropertyName}}: No support for control type <span
            class="badge badge-danger">{{controlModel.ControlType}}</span>.</em>
  </div>

</ng-container>
