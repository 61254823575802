import { Injectable } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as moment from "moment";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { Router } from '@angular/router';
import { StaticPickList } from '../models/model-helpers';
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { ApiCall, ApiOperationType, ApiProperties, CacheLevel, IApiResponseWrapperTyped, Query } from '../api/ApiModels';
import { ApiModuleWeb } from '../api/Api.Module.Web';
import { ApiHelper } from '../api/ApiHelper';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends BaseService {

  private searchConfigSubject = new BehaviorSubject<m5web.SearchConfigurationViewModel[]>([]);
  getSearchConfig() { return this.searchConfigSubject.asObservable(); }
  private searchConfig: m5web.SearchConfigurationViewModel[] = [];
  private searchConfigLoaded: boolean = false;
  private searchConfigApiProperties: ApiProperties = null;
  private searchConfigApiCall: ApiCall = null;
  private searchConfigQuery: Query = null;

  public globalSearchConfigurations: m5web.SearchConfigurationViewModel[] = [];
  public get hasGlobalSearchConfigurations(): boolean {
    if (!this.globalSearchConfigurations) {
      this.globalSearchConfigurations = [];
    }
    return (this.globalSearchConfigurations.length > 0);
  }


  constructor(
    protected apiService: ApiService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router) {

    super();

    try {
      this.refreshSearchConfig();
    } catch (err) {
      Log.errorMessage("Exception refreshing search config from service constructor");
      Log.errorMessage(err);
    }


  }



  /**
   * Loads search config models.  The models are used internally but also provided
   * for subscribers to pick up on these updates.  This method can be called by service users
   * when they know new search configs have been added and the models need to be updated.
   */
  refreshSearchConfig(): Observable<m5web.SearchConfigurationViewModel[]> {

    const cacheName = "SearchConfig";
    const cacheKey = "SearchConfigObjectArray";

    // Get a quick response from our cache before we call the API.
    // We still call the API to refresh what we had in our cache.
    const promise: Promise<m5web.SearchConfigurationViewModel[]> = this.cache.storedCacheGetValue<m5web.SearchConfigurationViewModel[]>(cacheName, cacheKey);
    promise.then((answer) => {
      if (answer) {
        this.searchConfig = answer;
        this.searchConfigSubject.next(this.searchConfig);
        this.prepareGlobalSearchConfigurations();
      }
    }, (cancelled) => {
      // No action
    });

    // Config the query
    if (!this.searchConfigApiProperties || !this.searchConfigApiCall || !this.searchConfigQuery) {
      this.searchConfigApiProperties = ApiModuleWeb.SearchConfiguration();
      this.searchConfigApiCall = ApiHelper.createApiCall(this.searchConfigApiProperties, ApiOperationType.List);
      this.searchConfigApiCall.silent = true;
      this.searchConfigQuery = new Query("Description", Constants.RowsToReturn.All);
      // We only need search configs that are global or linked to an app table
      this.searchConfigQuery.Filter = `LinkSearchToAppGlobalSearch == 1 || LinkSearchToAppTable != ""`;
    }

    const subject = new AsyncSubject<m5web.SearchConfigurationViewModel[]>();

    // Execute
    this.apiService.execute(this.searchConfigApiCall, this.searchConfigQuery).subscribe((result: IApiResponseWrapperTyped<m5web.SearchConfigurationViewModel[]>) => {
      if (result.Data.Success) {
        this.searchConfigLoaded = true;
        this.searchConfig = result.Data.Data;
        this.searchConfigSubject.next(this.searchConfig);
        subject.next(this.searchConfig);
        subject.complete();
        this.prepareGlobalSearchConfigurations();
        // Now stick in cache storage with static lifetime.  It may change but this service wants to provide quick response
        // even if it might be stale and then after the api call it will refresh the cache storage.
        this.cache.storedCachePutValue(cacheName, cacheKey, this.searchConfig, CacheLevel.Static);
      } else {
        this.searchConfigLoaded = true; // Technically not loaded but we're not going to be retrying
        this.searchConfigSubject.next([]);
        subject.next([]);
        subject.complete();
        Log.errorMessage(result);
      }
    });

    return subject.asObservable();

  }


  prepareGlobalSearchConfigurations() {
    if (!this.searchConfig || this.searchConfig.length === 0) {
      this.globalSearchConfigurations = [];
      return;
    }
    this.globalSearchConfigurations = Helper.arraySort2(this.searchConfig.filter(x => x.LinkSearchToAppGlobalSearch), "LinkSearchToAppDisplayOrder");
  }


  findSearchConfig(id: string): Observable<m5web.SearchConfigurationViewModel> {

    let internalId: number = null;
    let externalId: string = null;

    if (Helper.isNumeric(id)) {
      internalId = parseInt(id, 10);
    } else {
      externalId = id;
    }

    const subject = new AsyncSubject<m5web.SearchConfigurationViewModel>();

    // If we haven't loaded search configs we need that to finish first
    if (!this.searchConfigLoaded) {
      this.refreshSearchConfig().pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(configs => {
          // Should be loaded now but enforce that so we don't get in a stack overflow recursive loop below
          if (!this.searchConfigLoaded) {
            this.searchConfigLoaded = true;
          }
          // Now loaded we can make a recursive call to get our value
          this.findSearchConfig(id).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(config => {
              subject.next(config);
              subject.complete();
            });
        });
      return subject.asObservable();
    }

    // If we have already loaded search configs so find the one we want and we're done.
    let matches: m5web.SearchConfigurationViewModel[] = [];
    if (internalId) {
      matches = this.searchConfig.filter(x => x.SearchConfigurationId === internalId);
    } else if (externalId) {
      matches = this.searchConfig.filter(x => Helper.equals(x.ExternalSearchConfigurationId, externalId, true));
    }
    if (matches && matches.length > 0) {
      subject.next(matches[0]);
      subject.complete();
    } else {
      Log.errorMessage(`Unable to find search configuration with id '${id}'.  Perhaps it was't configured for app table search or app global search?`);
      subject.next(null);
      subject.complete();
    }

    return subject.asObservable();

  }


  buildSearchFilter(searchConfig: m5web.SearchConfigurationViewModel, searchData: any): { filter?: string, errorMessage?: string, error?: any } {

    if (!searchConfig) {
      return { filter: "", errorMessage: "Unable to build search filter because search config object is null." };
    }
    if (!searchData) {
      return { filter: "", errorMessage: "Unable to build search filter because search data object is null." };
    }
    if (!searchConfig.SearchFilterBuilderScript?.Code || searchConfig.SearchFilterBuilderScript.Code.length === 0) {
      return { filter: "", errorMessage: "Unable to build search filter because search config object does not include filter builder script." };
    }
    if (searchConfig.ApprovalPending) {
      return { filter: "", errorMessage: "This search configuration has not been approved yet." };
    }
    if (!searchConfig.ApprovedDateTime) {
      return { filter: "", errorMessage: "This search configuration has not been approved yet." };
    }
    // Part of the search configuration is the script so we don't need separate script approval here
    // if (searchConfig.SearchFilterBuilderScript.ApprovalPending) {
    //   return { filter: "", errorMessage: "The filter builder code attached to this search configuration has not been approved yet." };
    // }
    // if (!searchConfig.SearchFilterBuilderScript.ApprovedDateTime) {
    //   return { filter: "", errorMessage: "The filter builder code attached to this search configuration has not been approved yet." };
    // }

    // Check for syntax errors
    try {
      //console.error("Ready to compile", searchConfig.SearchFilterBuilderScript.Code[0].SourceCode);
      const func: Function = new Function(searchConfig.SearchFilterBuilderScript.Code[0].SourceCode);
    } catch (err) {
      console.error(err);
      return { filter: "", errorMessage: err?.message ?? err, error: err };
    }

    let filter: string = "";
    try {
      //console.error("Ready to run filter builder with data", searchData);
      // Variable where we will store the function
      let filterBuilderFunction = null;
      // Eval assigning the code to our variable.  This means the entry point function must be
      // at the top of the code since that is what will be executed.
      eval(`filterBuilderFunction = ${searchConfig.SearchFilterBuilderScript.Code[0].SourceCode};`);
      // Now execute the code by calling the variable we stored the function at with our search data and save the filter it returns
      filter = filterBuilderFunction(searchData);
    } catch (err) {
      // Runtime error
      console.error(err);
      return { filter: "", errorMessage: err?.message ?? err, error: err };
    }

    return { filter: filter, errorMessage: "", error: null };

  }


}
