<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="null"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <form #f="ngForm">
    <fieldset [disabled]="( disabled || mode === 'view' ) ? 'disabled' : null">
      <ul ngbNav
          #nav='ngbNav'
          class="{{isMobile ? 'nav-pills' : 'nav-tabs'}}"
          [destroyOnHide]="false">
        <!--(navChange)="onNavChange($event)"-->

        <li ngbNavItem
            title="Info">
          <a ngbNavLink>Info</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Id"
                               label="Id"
                               [(ngModel)]="data.TableColumnId"></ib-input-text>
                <ib-input-text name="Property Name"
                               label="Property Name"
                               [(ngModel)]="data.PropertyName"></ib-input-text>
                <ib-input-select name="Data Type"
                                 label="Data Type"
                                 [(ngModel)]="data.DataType"
                                 [optionsPickList]="dataTypePickList"
                                 [optionsIncludeNone]="false"></ib-input-select>
                <ib-input-text name="Supporting Property Name"
                               label="Supporting Property Name"
                               [(ngModel)]="data.SupportingPropertyName"></ib-input-text>
                <ib-input-text name="Header Label"
                               label="Header Label"
                               [(ngModel)]="data.HeaderLabel"></ib-input-text>
                <ib-input-wrapper label="Header Icon">
                  <ib-icon-picker [icon]="data.HeaderIcon"
                                  (change)="data.HeaderIcon = $event.data;"></ib-icon-picker>
                </ib-input-wrapper>
                <ib-input-textarea name="Header Description"
                                   label="Header Description"
                                   [rows]="3"
                                   [(ngModel)]="data.HeaderDescription"></ib-input-textarea>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-checkbox name="Visible"
                                   label="Visible"
                                   [(ngModel)]="data.Visible"></ib-input-checkbox>
                <ib-input-checkbox name="Sortable"
                                   label="Sortable"
                                   [(ngModel)]="data.Sortable"></ib-input-checkbox>
                <ib-input-checkbox name="Resizable"
                                   label="Resizable"
                                   [(ngModel)]="data.Resizable"></ib-input-checkbox>
                <ib-input-checkbox name="Word Wrap"
                                   label="Word Wrap"
                                   [(ngModel)]="data.WordWrap"></ib-input-checkbox>
                <ib-input-checkbox name="Allow Line Breaks"
                                   label="Allow Line Breaks"
                                   [(ngModel)]="data.AllowLineBreaks"></ib-input-checkbox>
                <ib-input-checkbox name="Allow Html"
                                   label="Allow Html"
                                   [(ngModel)]="data.AllowHtml"></ib-input-checkbox>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Pick List">
          <a ngbNavLink>Pick List</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Options Pick List Id"
                               label="Options Pick List Id"
                               [(ngModel)]="data.OptionsPickListId"></ib-input-text>
                <ib-input-checkbox name="Options Include None"
                                   label="Options Include None"
                                   [(ngModel)]="data.OptionsIncludeNone"></ib-input-checkbox>
                <ib-input-text name="Options None Label"
                               label="Options None Label"
                               [(ngModel)]="data.OptionsNoneLabel"></ib-input-text>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <p class="mb-0">Options Pick List Values <em>(key is pick list value and value is pick list display text)</em></p>
                <div [hidden]="!optionsPickListJsonError"
                     class="alert alert-danger"
                     role="alert">
                  {{optionsPickListJsonError}}
                </div>
                <ib-code-editor name="Options Pick List"
                                height="300px"
                                fileType="json"
                                [ngModel]="getOptionsPickListAsJsonString()"
                                (change)="onOptionsPickListJsonStringChange($event)">
                </ib-code-editor>
              </div>
            </div>
          </ng-template>
        </li>


        <li ngbNavItem
            title="Filter">
          <a ngbNavLink>Filter</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-checkbox name="Include In Global Filter"
                                   label="Include In Global Filter"
                                   [(ngModel)]="data.IncludeInGlobalFilter"></ib-input-checkbox>
                <ib-input-select name="Filter Type"
                                 label="Filter Type"
                                 [(ngModel)]="data.FilterType"
                                 [optionsPickList]="filterTypePickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-select name="Filter Match Mode"
                                 label="Filter Match Mode"
                                 [(ngModel)]="data.FilterMatchMode"
                                 [optionsPickList]="filterMatchModePickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-text name="Filter Pick List Id"
                               label="Filter Pick List Id"
                               [(ngModel)]="data.FilterPickListId"></ib-input-text>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <p class="mb-0">Filter Pick List Values <em>(key is pick list value and value is pick list display text)</em></p>
                <div [hidden]="!filterPickListJsonError"
                     class="alert alert-danger"
                     role="alert">
                  {{filterPickListJsonError}}
                </div>
                <ib-code-editor name="Filter Pick List"
                                height="300px"
                                mode="json"
                                [ngModel]="getFilterPickListAsJsonString()"
                                (change)="onFilterPickListJsonStringChange($event)">
                </ib-code-editor>
              </div>
            </div>
          </ng-template>
        </li>



        <li ngbNavItem
            title="Other">
          <a ngbNavLink>Other</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-number name="Decimals"
                                 label="Decimals"
                                 type="integer"
                                 [(ngModel)]="data.Decimals"></ib-input-number>
                <ib-input-number name="Max Character Length"
                                 label="Max Character Length"
                                 type="integer"
                                 [(ngModel)]="data.MaxCharacterLength"></ib-input-number>
                <ib-input-checkbox name="Tooltip When Max Character Length Exceeded"
                                   label="Tooltip When Max Character Length Exceeded"
                                   [(ngModel)]="data.ToolTipWhenMaxCharacterLengthExceeded"></ib-input-checkbox>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-select name="Align"
                                 label="Align"
                                 [(ngModel)]="data.Align"
                                 [optionsPickList]="alignPickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-text name="Width"
                               label="Width"
                               [(ngModel)]="data.Width"></ib-input-text>
                <ib-input-textarea name="Footer Html"
                                   label="Footer Html"
                                   [rows]="5"
                                   [(ngModel)]="data.FooterHtml"></ib-input-textarea>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Tooltip">
          <a ngbNavLink>Tooltip</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-select name="Tooltip Type"
                                 label="Tooltip Type"
                                 [(ngModel)]="data.ToolTipType"
                                 [optionsPickList]="toolTipTypePickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-select name="Tooltip Placement"
                                 label="Tooltip Placement"
                                 [(ngModel)]="data.ToolTipPlacement"
                                 [optionsPickList]="toolTipPlacementPickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-checkbox name="Tooltip Append To Body"
                                   label="Tooltip Append To Body"
                                   [(ngModel)]="data.ToolTipAppendToBody"></ib-input-checkbox>
                <ib-input-text name="Tooltip Title"
                               label="Tooltip Title"
                               [(ngModel)]="data.ToolTipTitle"></ib-input-text>
                <ib-input-textarea name="Tooltip Text"
                                   label="Tooltip Text"
                                   [rows]="4"
                                   [(ngModel)]="data.ToolTipText"></ib-input-textarea>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <p class="mb-0">Tooltip Title Function</p>
                <ib-code-editor name="Tooltip Title Function"
                                height="200px"
                                fileType="js"
                                [actions]="tooltipTitleEditorActions"
                                [(ngModel)]="data.ToolTipTitleFunction"></ib-code-editor>
                <p class="mb-0">Tooltip Text Function</p>
                <ib-code-editor name="Tooltip Text Function"
                                height="200px"
                                fileType="js"
                                [actions]="tooltipTextEditorActions"
                                [(ngModel)]="data.ToolTipTextFunction"></ib-code-editor>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Click">
          <a ngbNavLink>Click</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Header Click URL"
                               label="Header Click URL"
                               [(ngModel)]="data.HeaderClickUrl"></ib-input-text>
                <ib-input-checkbox name="Header Click Open In New Window"
                                   label="Header Click Open In New Window"
                                   [(ngModel)]="data.HeaderClickUrlNewWindow"></ib-input-checkbox>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Row Click URL"
                               label="Row Click URL"
                               [(ngModel)]="data.RowClickUrl"></ib-input-text>
                <ib-input-checkbox name="Row Click Open In New Window"
                                   label="Row Click Open In New Window"
                                   [(ngModel)]="data.RowClickUrlNewWindow"></ib-input-checkbox>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Render">
          <a ngbNavLink>Render</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.fullWidth}}">
                <ib-input-checkbox name="Support Late Render"
                                   label="Support Late Render"
                                   format="pull-left"
                                   [(ngModel)]="data.SupportLateRender"></ib-input-checkbox>
                <p class="mb-0">Render Function</p>
                <ib-code-editor name="Render Function"
                                height="300px"
                                fileType="js"
                                [actions]="renderEditorActions"
                                [(ngModel)]="data.Render"></ib-code-editor>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Style">
          <a ngbNavLink>Style</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.fullWidth}}">
                <ib-input-textarea name="Style"
                                   label="Style"
                                   [vertical]="true"
                                   [rows]="4"
                                   [(ngModel)]="data.Style"></ib-input-textarea>
              </div>
            </div>
            <div class="row">
              <div class="{{Constants.Layout.fullWidth}}">
                <p class="mb-0">Style Function</p>
                <ib-code-editor name="Style Function"
                                height="300px"
                                fileType="js"
                                [actions]="getStyleEditorActions"
                                [(ngModel)]="data.GetStyle"></ib-code-editor>
              </div>
            </div>
          </ng-template>
        </li>



      </ul>
      <div [ngbNavOutlet]="nav"
           class="mt-2"></div>
    </fieldset>
  </form>
  <ib-form-status-error-output [formStatus]="formStatus"
                               classes="float-right mb-1"></ib-form-status-error-output>
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="data"
                         [cancelData]="null"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>
