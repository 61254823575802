import { Injectable } from '@angular/core';
import { CacheService, LifeSpan } from '@ngx-cache/core';
import { ApiCall, ApiOperationType, CacheLevel } from 'projects/core-lib/src/lib/api/ApiModels';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { BaseService } from './base.service';
//import { ReturnType } from '@ngx-cache/core/src/models/return-type';
import { NgForage, Driver, NgForageCache, NgForageConfig, CachedItem, InstanceFactory } from 'ngforage';
import { ApiHelper } from '../api/ApiHelper';

@Injectable({
  providedIn: 'root'
})
export class AppCacheService extends BaseService {

  //protected ngf: NgForage;

  constructor(
    public cache: CacheService,
    protected readonly ngf: NgForage,
    protected readonly ngcache: NgForageCache) {

    super();

    //console.error("NgForageFactory");
    //console.error("ngcache", this.ngcache);
    //// @ts-ignore
    //const instance = new InstanceFactory(this.ngcache);
    //console.error(instance);
    //// @ts-ignore
    //this.ngf = new NgForage({}, instance);
    //console.error("fact", (this.ngf as any).fact);

  }

  public cacheLevelToLifeSpanMilliseconds(cacheLevel: CacheLevel): number {
    switch (cacheLevel) {
      case CacheLevel.None:
        return (10 * 1000); // 10 seconds
      case CacheLevel.Volatile:
        return (5 * 60 * 1000); // 5 minutes
      case CacheLevel.ChangesOften:
        return (15 * 60 * 1000); // 15 minutes
      case CacheLevel.ChangesInfrequently:
        return (2 * 60 * 60 * 1000); // 2 hours
      case CacheLevel.PseudoStatic:
        return (6 * 60 * 60 * 1000); // 6 hours
      case CacheLevel.Static:
        //return Number.MAX_VALUE;
        return (30 * 24 * 60 * 60 * 1000); // 30 days
      default:
        Log.errorMessage(`Unsupported cache level: ${cacheLevel}`);
        return (10 * 1000); // 10 seconds
    }
  }

  public cacheLevelToLifeSpanObject(cacheLevel: CacheLevel): LifeSpan {
    /**
     * expiry time (timestamp)
     * TTL (seconds)
     */
    return { expiry: null, TTL: (this.cacheLevelToLifeSpanMilliseconds(cacheLevel) / 1000) };
  }


  public cachePutValue<T>(cacheName: string, key: string, value: T, cacheLevel: CacheLevel): boolean {
    if (cacheLevel === CacheLevel.None) {
      return true;
    }
    try {
      return this.cache.set(`${cacheName}~|~${key}`, value, null, this.cacheLevelToLifeSpanObject(cacheLevel));
    } catch (err) {
      Log.errorMessage(err);
      return false;
    }
  }


  public cacheGetKeys(): string[] {
    try {
      let keys: string[] = (<any>this.cache).cache.keys;
      if (Helper.isArray(keys)) {
        return keys;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }


  public cacheGetLength(): number {
    return this.cacheGetKeys().length;
  }


  public cacheKeyExists(cacheName: string, key: string): boolean {
    return this.cache.has(`${cacheName}~|~${key}`);
  }


  public cacheGetValue<T>(cacheName: string, key: string, defaultValue: T = null): T {
    if (!this.cacheKeyExists(cacheName, key)) {
      return defaultValue;
    }
    try {
      let value: T = this.cache.get(`${cacheName}~|~${key}`);
      if (value) {
        return value;
      } else {
        return defaultValue;
      }
    } catch (err) {
      Log.errorMessage(err);
      return defaultValue;
    }
  }


  /**
   * Removes all cached items that start with the passed in cache name.
   * @param cacheName
   */
  public cacheRemoveAllByCacheName(cacheName: string) {
    let cacheKeyArr = this.cacheGetKeys();
    let length = cacheKeyArr.length;

    for (let i = 0; i < length; i++) {
      if (Helper.startsWith(cacheKeyArr[i], cacheName, true)) {
        const nameAndKey = cacheKeyArr[i].split("~|~");
        const key = nameAndKey[1];

        this.cacheRemoveValue(cacheName, key);
      }
    }
  }


  public cacheRemoveValue(cacheName: string, key: string, startsWith: boolean = false): void {
    this.cache.remove(`${cacheName}~|~${key}`, startsWith);
    return;
  }


  public cacheRemoveValueBasedOnApiCall(api: ApiCall, inputData: any, currentUserPartitionId: number) {

    let cacheKey: string = "";
    if (api.type === ApiOperationType.List) {
      cacheKey = ApiHelper.buildApiAbsoluteUrl(api, inputData);
      if (currentUserPartitionId) {
        cacheKey = `P${currentUserPartitionId}-${cacheKey}`;
      }
    } else if (api.type === ApiOperationType.Get) {
      cacheKey = ApiHelper.buildCacheKey(api, inputData, currentUserPartitionId);
    } else if (api.type === ApiOperationType.Add ||
      api.type === ApiOperationType.Edit ||
      api.type === ApiOperationType.Patch ||
      api.type === ApiOperationType.Merge ||
      api.type === ApiOperationType.Copy ||
      api.type === ApiOperationType.Delete) {
      cacheKey = ApiHelper.buildCacheKey(api, inputData, currentUserPartitionId);
    }

    if (!cacheKey) {
      return;
    }

    if (api.cacheUseStorage) {
      this.storedCacheRemoveValue(api.cacheName, cacheKey);
    } else {
      this.cacheRemoveValue(api.cacheName, cacheKey);
    }

  }


  public cacheClear(): void {
    this.cache.clear();
  }




  //public getItem<T = any>(key: string): Promise<T> {
  //  return this.ngf.getItem<T>(key);
  //}

  public storedCachePutValue<T>(cacheName: string, key: string, value: T, cacheLevel: CacheLevel): Promise<T> {
    if (cacheLevel === CacheLevel.None) {
      return new Promise<T>((resolve, reject) => {
        resolve(value);
      });
    }
    try {
      let lifeSpan: number = this.cacheLevelToLifeSpanMilliseconds(cacheLevel);
      //console.error(`ready to cache for ${lifeSpan} ms: ${cacheName}~|~${key}`, value);
      return this.ngcache.setCached(`${cacheName}~|~${key}`, value, lifeSpan);
    } catch (err) {
      Log.errorMessage(err);
      return new Promise<T>((resolve, reject) => {
        resolve(value);
      });
    }
  }


  public storedCacheGetValue<T = any>(cacheName: string, key: string): Promise<T | null> {
    return this.ngcache.getCached<T>(`${cacheName}~|~${key}`)
      .then((r: CachedItem<T>) => {
        if (!r.hasData || r.expired) {
          return null;
        }
        return r.data;
      });
  }

  public storedCacheRemoveValue(cacheName: string, key: string, startsWith: boolean = false): void {
    if (startsWith) {
      this.ngcache.keys().then(keys => {
        keys.forEach(cachedKey => {
          if (Helper.startsWith(cachedKey, `${cacheName}~|~${key}`, true)) {
            this.ngcache.removeCached(cachedKey);
          }
        });
      });
    } else {
      this.ngcache.removeCached(`${cacheName}~|~${key}`);
    }
    return;
  }

  public storedCacheGetLength(): Promise<number> {
    return this.ngcache.length();
  }

  public storedCacheGetKeys(): Promise<string[]> {
    return this.ngcache.keys();
  }

  public storedCacheClear(): Promise<void> {
    return this.ngcache.clear();
  }

  /**
   * When the app loads it notes the version it is running via the
   * AppStatusService and when that version is first noted this method
   * is called so the cache engine can decide if certain cache
   * elements like long running pick lists need to be dumped.
   * @param version The app version currently running.
   */
  public versionCheck(version: string) {
    // Get the last known app version
    const lastVersion = Helper.localStorageGet("AppVersion", "");
    // If we don't have a last known app version or it doesn't match our current version
    // then we dump the stored cache since we stick some pretty static things there like
    // widgets, pick lists, etc. and a version change may mean those need to be refreshed.
    if (!lastVersion || lastVersion !== version) {
      Log.debug("cache", "Cache Dump", `New version "${version}" detected so dumping stored cache.  Last version was "${lastVersion}".`);
      this.storedCacheClear();
      Helper.localStorageSave("AppVersion", version);
    }
  }

}
