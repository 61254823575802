import { Component, OnInit, OnChanges, Input, SimpleChanges, OnDestroy, Output, EventEmitter, AfterViewInit, AfterContentInit, Directive, HostListener } from '@angular/core';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiProperties, ApiOperationType, IApiResponseWrapper, IApiResponseWrapperTyped, Query } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { ButtonItem, Action, EventModel, CommonDataEditorOptions, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import * as Enumerable from 'linq';
import { ActivatedRoute, Params, Data, Router, NavigationExtras } from '@angular/router';
import { Subscription, Subject, Observable, of, from, timer } from 'rxjs';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { takeUntil, map, catchError } from 'rxjs/operators';
import { CanDoWhat } from 'projects/core-lib/src/lib/models/security';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';
import { CanComponentDeactivate } from 'projects/core-lib/src/lib/services/can-deactivate-guard.service';
import { ModalService } from '../../modal/modal.service';
import { DataEditorExportModalComponent } from './data-editor-export-modal/data-editor-export-modal.component';
import { UxService } from '../../services/ux.service';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { AlertItemType } from '../../alert/alert-manager';

@Directive()
export abstract class DataEditorBaseClass<TList, TEdit> extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterContentInit, CanComponentDeactivate {

  @Input() public mode: "list" | "add" | "edit" | "view" | "download" | "upload" | string;
  /**
  Even if not configured to use modal for add/edit forms the editor could find itself
  embedded in a modal so exposing this host setting allows us to pass that host
  context on when the form gets rendered.  If set to component this editor is embedded
  within another editor which may impact how we want to render certain items like
  tables, document titles, etc.
  */
  @Input() public host: "page" | "modal" | "component" = "page";

  /**
  Can be defined in editor options, via input attribute, or route data depending on context of component setup.
  */
  @Input() public inputMethod: "inline" | "modal" | "route" = "inline";


  /**
  Data editors can have a scope object assigned to them which represents a data ownership scope for
  the component providing values for filtering of lists as well as default values when adding new
  objects.  For example, a list of contacts may have scope defining the contact type and who the
  contacts belong to: { ContactType: "T", ParentContactId: 12345 }.
  A list of case task lists may have scope defining the case they belong to: { CaseId: 12345 }.
  */
  @Input() public scope: any = {};

  /**
   * Pass-thru setting to table viewer if scope should be included in the filter.
   */
  @Input() public scopeInFilter: boolean = true;

  /**
   * True if scope properties should be used with api requests.  If this is set to false and the api
   * url requires properties that exist in scope but not in the data object then that part of the
   * api url will have an undefined value.
   */
  @Input() public scopeInApiRequests: boolean = true;


  /**
  Flag if changes should be saved to server.  The default is true which will result in API calls for
  add, edit, delete, etc.  If set to false then the array of data objects is the only data source
  that is interacted with.  This is typical for child data editor components where the collection of
  child objects is attached to the parent object.
  */
  @Input() public saveChangesToServer: boolean = true;

  /**
  We support overriding many input attributes with route data so config can be expressed in the
  route but sometimes we have self-embedded components (e.g. child contacts under a customer)
  and in those scenarios the route data is related to the parent not the child so we set this
  input attribute to true to ignore route data.
  */
  @Input() public ignoreRouteData: boolean = false;

  /**
  Provides option to set data editor option for show header via input attribute.
  */
  @Input() public showHeader: boolean = true;

  /**
  List of data objects for this editor.  Often not set but when using internal list (frequently
  with [saveChangesToServer]="false") this can be set to the list of data objects being edited.
  */
  @Input() public data: TList[] = [];

  /**
  An event emitter for notifying of any add, edit, delete operations.
  */
  @Output() public change: EventEmitter<EventModel> = new EventEmitter();


  /**
  A count of the number of table reloads requested.  We don't actually care about counting but
  it's a convenient way to signal a child component that renders the table that it needs to
  refresh as we can pass this as input to that child component and it can pick up a change on
  this via ngOnChanges to know when to refresh.  This is a public property so it can be
  referenced in html views as input to other components.
  */
  public tableReloadCount: number = 0;
  public tableCollapseRowsCount: number = 0;

  /**
  Options object for table view. This is a public property so it can be passed in html
  views as input to other components.
  */
  public tableOptions: TableOptions;

  /**
  Options object for the editor.  This is a public property so it can be passed in html
  views as input to other components.
  */
  public editorOptions: CommonDataEditorOptions;
  /**
  If editor component wants to use modal for input it must assign the modal component here.
  */
  protected modalComponent: any;
  /**
   * When the editor wants to use modal for input and instead of using a modalComponent we will be
   * using a dynamic form this is the modal options for the dynamic form.
   */
  protected modalOptions: ModalCommonOptions;
  /**
   * When the editor wants to use modal for input and instead of using a modalComponent we will be
   * using a dynamic form this is the form for the dynamic form.  The form should use object name
   * "Data" for accessing properties.
   */
  protected modalForm: m5web.FormEditViewModel;
  /**
  If modal component needs any custom values injected they are noted here.
  */
  protected modalComponentInjection: any;
  /**
  If modal component needs any custom values injected they are noted here.
  */
  protected modalComponentInjectionAddMode: any;
  /**
  If modal component needs any custom values injected they are noted here.
  */
  protected modalComponentInjectionEditViewMode: any;

  /**
   * Key-value pairs of events to subscribe to on the modal component instances
   * and what function to call when they fire. This mimics the effect of an event
   * binding inside of a template.
   */
  protected modalComponentEventBindings: { [eventName: string]: (eventData: any) => void };

  /**
  Indicator if we are loading data which will show loading overlay.
  */
  public loading: boolean = false;

  /**
  Indicator if we are working - typically saving data - which will alter how save button is handled.
  */
  public working: boolean = false;

  /**
  When true our deactivate guard will warn us about any unsaved changes.
  */
  protected warnAboutUnsavedChanges: boolean = true;

  /**
  Typically when not saving to server we can easily populate multi-select option for column filters
  and if this is true we do that.  If we don't want that our subclass needs to set this to false
  in the constructor before ngOnInit can do it.
  */
  protected allColumnsFilterMultiSelectWhenNotSavingToServer: boolean = true;

  addData: TEdit = null;
  addFormStatus: FormStatusModel = new FormStatusModel();

  editData: TEdit = null;
  editFormStatus: FormStatusModel = new FormStatusModel();
  protected editDataOriginal: TEdit = null;
  protected editDataIndex: number = -1;
  /**
   * Data can be saved via PUT, PATCH, or MERGE.  A merge is pushing up a difference object of
   * just the properties that have been modified which can be much smaller payload than a PUT
   * of the whole object resulting in better performance.
   */
  protected editSaveUsingMerge: boolean = false;
  /**
   * When a MERGE is being performed and this setting is true and the diff is empty the save
   * will be skipped.  In some scenarios complex forms have multiple items to save via
   * onEditSaveSuccess(), etc. so we may in those cases get a diff that is empty (except for
   * PK and MetaData).  If this is true and that diff is empty then we skip the merge.
   */
  protected editSaveUsingMergeSkipIfEmptyDiff: boolean = false;
  /**
   * A dirty form enables the save button and when editOnlySaveWhenModified is false pressing the
   * save button will submit the edit request.  When editOnlySaveWhenModified is true the edit
   * request is only submitted if comparing editData and editDataOriginal indicate there has been
   * changes.  All other parts of the save operation (save start event, save success event, etc.)
   * fire as if the edit request is submitted and was successful.  This enables other data that
   * needs to be saved via those events to be saved (which is what may have marked the form dirty).
   */
  protected editSaveOnlyWhenModified: boolean = false;
  protected editModeContext: "" | "new" = "";
  formResetCount: number = 0;
  formDirtyCount: number = 0;
  protected autoSave: boolean = false;
  protected autoSaveSubscription: Subscription;
  protected autoSaveInterval: number = 10000;


  // We get routes from router data instead of editor options so we can use in different routes if needed
  // but more importantly so we can keep router settings in the router where it's easier to keep in sync.
  protected baseRoute: string = "";
  protected listRoute: string = "";
  public addRoute: string = "";
  public editRouteBase: string = "";
  protected viewRouteBase: string = "";

  protected routeParamId: number = null;
  protected routeParamName: string = null;
  protected routeParamFriendlyName: string = "";

  protected user: m5sec.AuthenticatedUserViewModel = null;
  protected permissions: CanDoWhat = null;

  protected lastKnownTableQuery: Query = null;
  canDownload: boolean = false;
  canUpload: boolean = false;

  /*
   * Gets toggled when we select to upload if upload support is enabled.
   */
  showFileUploadArea: boolean = false;


  ///**
  //We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
  //only need to unsubscribe from this one shared subject in ngOnDestroy as it
  //is passed as parameter to takeUntil.
  //*/
  //protected ngUnsubscribe = new Subject();

  ///**
  //Make constants available in html view.
  //*/
  //public Constants = Constants;



  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected ngbModalService: NgbModal,
    public apiProperties: ApiProperties) {

    super();

    // Clear any existing context sensitive help links.  Subclass will set the help appropriate for that component.
    this.appService.helpLink = null;

    // ngAfterContentInit will call validateUser which will set this but let's start with a default
    this.user = this.appService.userOrDefault;

  }

  ngOnInit() {

    super.ngOnInit();

    this.editorOptions = this.getDefaultEditorOptions();
    this.tableOptions = this.getDefaultTableOptions();

    // We may dictate our mode via route data so the mode matches the route intended for that mode
    if (!this.ignoreRouteData) {
      const mode = this.route.snapshot.data["mode"];
      // If we have mode in our route data then that's the mode we want here
      if (mode) {
        this.mode = mode;
      }
    }

    // If add mode this came in via route and we need an object
    if (Helper.equals(this.mode, "add", true)) {
      this.addData = this.getNewDataObject();
      this.addFormStatus = new FormStatusModel();
    } else if (Helper.equals(this.mode, "edit", true) || Helper.equals(this.mode, "view", true)) {
      // This is a temp object placeholder while we do async load
      this.editDataOriginal = this.getNewDataObject();
      this.editData = this.getNewDataObject();
      if (this.route.snapshot.queryParams["context"]) {
        this.editModeContext = this.route.snapshot.queryParams["context"];
      }
      // async load will fire in AfterViewInit
    }

    // Routes are exclusively declared in route data in order to more easily keep route changes in sync
    if (!this.ignoreRouteData) {
      this.baseRoute = this.route.snapshot.data["baseRoute"];
      this.listRoute = this.route.snapshot.data["listRoute"];
      this.addRoute = this.route.snapshot.data["addRoute"];
      this.editRouteBase = this.route.snapshot.data["editRouteBase"];
      this.viewRouteBase = this.route.snapshot.data["viewRouteBase"];
      // Instead of defining individual routes we may have chose to follow a route naming convention and just define the base route
      if (!this.listRoute && this.baseRoute) {
        // List route is the base route
        this.listRoute = this.baseRoute;
      }
      if (!this.addRoute && this.baseRoute) {
        // List route is the base route + "/add"
        this.addRoute = this.baseRoute + "/add";
      }
      if (!this.editRouteBase && this.baseRoute) {
        // List route is the base route + "/edit" + id and name parameters which will get added upon navigation.
        this.editRouteBase = this.baseRoute + "/edit";
      }
      if (!this.viewRouteBase && this.baseRoute) {
        // List route is the base route + "/view" + id and name parameters which will get added upon navigation.
        this.viewRouteBase = this.baseRoute + "/view";
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.validateParameters();
    if (changes.saveChangesToServer && this.tableOptions) {
      this.tableOptions.loadDataFromServer = this.saveChangesToServer;
    }
  }

  //ngOnDestroy() {
  //  super.ngOnDestroy();
  //  ///**
  //  //We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
  //  //only need to unsubscribe from this one shared subject in ngOnDestroy as it
  //  //is passed as parameter to takeUntil.
  //  //*/
  //  //this.ngUnsubscribe.next();
  //  //this.ngUnsubscribe.complete();
  //}

  ngAfterViewInit() {
    // Some of our editors have download/upload support and may have a hash on the url indicating that's what were
    // previously looking at when the user hit the back button so accommodate that here.
    try {
      let hash: string = window.location.hash;
      if (hash && Helper.contains(hash, "Download", true)) {
        this.mode = "download";
      } if (hash && Helper.contains(hash, "Upload", true)) {
        this.mode = "upload";
      }
    } catch (err) {
      console.error(err);
    }
  }

  ngAfterContentInit() {

    // Debug help by giving access to this component in the console
    if (this.appService.config.debug) {
      //if (!(<any>window).debug) {
      //  (<any>window).debug = {};
      //}
      //let id = `${this.apiProperties.id}DataEditor`;
      //(<any>window).debug[id] = this;
      //Log.debugMessage(`Get debug access to component from console by typing "debug.${id}"`);
      //Log.debugMessage("You can access a component instance by selecting it in the elements tab and then executing this from the console: > ng.probe($0).componentInstance");
      Log.debugMessage("You can access a component instance by selecting it in the elements tab and then executing this from the console: > ng.getComponent($0)");
    }

    // Hold any data loading until after view init in case our subclass made api changes in OnInit()
    // which happens in shared component scenarios where the api endpoint may change like contacts
    // of various contact types that have 90% component in common with each other.
    if (Helper.equals(this.mode, "edit", true) || Helper.equals(this.mode, "view", true)) {
      if (!this.ignoreRouteData) {
        //let id = this.route.snapshot.params["id"];
        //let name = this.route.snapshot.params["name"];
        ////this.debug("id", id, "name", name);
        //this.editLoadData(id, -1);
        // Route params can change and won't auto reload if we're on the same component
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
          const id = params["id"];
          const name = params["name"];
          //console.error("route params change", "id", id, "name", name);
          this.editLoadData(id, -1);
          try {
            this.routeParamId = parseInt(id.toString(), 10);
            this.routeParamName = name || "";
            this.routeParamFriendlyName = Helper.toTitleCase(Helper.replaceAll(name, "-", " "));
          } catch (err) {
            Log.errorMessage(err);
          }
        });
      }
    }

    if (this.mode === "list" && this.host === "page" && this.editorOptions) {
      const id = `${this.editorOptions.objectFriendlyName}-List`;
      const description = `${this.editorOptions.objectFriendlyName} List`;
      this.appService.pageHistory.add(id, description, this.router.url);
    }

    // Validate our user meets data editor requirements
    this.validateUser();

  }

  /**
   * Validate input parameters to make sure they are supported values.
   */
  protected validateParameters() {

    if (!this.mode) {
      this.mode = "list";
      Log.errorMessage(`Data editor mode was not configured.  Accepted values are "list", "add", "edit", "view", "download", and "upload".  Defaulting to "list".`);
    }
    this.mode = <any>this.mode.toLowerCase();
    if (this.mode !== "list" && this.mode !== "add" && this.mode !== "edit" && this.mode !== "view" && this.mode !== "download" && this.mode !== "upload") {
      Log.errorMessage(`Data editor mode ${this.mode} is not recognized.  Accepted values are "list", "add", "edit", "view", "download", and "upload".  Defaulting to "list".`);
      this.mode = "list";
    }

  }

  /**
   * Make sure our user meets requirements set forth by the data editor options.
   */
  protected validateUser() {

    if (!this.editorOptions) {
      return;
    }
    if (!this.editorOptions.requiresValidatedUser && !this.editorOptions.requiresContactType && !this.editorOptions.requiresPartitionZero) {
      return;
    }

    // Let's have the API read permission rejection handle this instead of this editor doing it.  Most of the time our
    // menu should handle permission checks and not even show the item when they only have read permission.
    // if (this.editorOptions.accessArea) {
    //   if (!this.appService.hasPermission(this.editorOptions.accessArea, "R")) {
    //     this.appService.alertManager.addAlertMessage(AlertItemType.Danger, `Access denied.  You do not have read permission for ${Helper.formatIdentifierWithSpaces(this.editorOptions.accessArea)}.`);
    //     if (!this.browserBack()) {
    //       this.appService.redirectToHome();
    //     }
    //     return;
    //   }
    // }

    // This will redirect to login if needed
    this.appService.tryGetUser(true).pipe(takeUntil(this.ngUnsubscribe)).subscribe((user: m5sec.AuthenticatedUserViewModel) => {
      if (user) {
        this.user = user;
        if (this.editorOptions.requiresContactType && this.user.ParentContactType) {
          if (!Helper.equals(this.user.ParentContactType, this.editorOptions.requiresContactType, true)) {
            // This user isn't valid for this view so redirect them home
            Log.errorMessage(`The authenticated user is contact type '${this.user.ParentContactType}' not contact type '${this.editorOptions.requiresContactType}' as requested by '${Helper.getFirstDefinedString(this.editorOptions.objectFriendlyName)}' so redirecting to home.`);
            this.appService.redirectToHome();
          }
        }
        if (this.editorOptions.requiresPartitionZero && this.user.PartitionId !== 0) {
          // This user isn't valid for this view so redirect them home
          Log.errorMessage(`The authenticated user is not a partition zero user as requested by '${Helper.getFirstDefinedString(this.editorOptions.objectFriendlyName)}' so redirecting to home.`);
          this.appService.redirectToHome();
        }
      }
    });

  }


  /**
   * Event that gets fired when a row gets selected when in list mode.  This is a public method
   * so it can be passed in html views as input to other components.
   * @param $event
   */
  public onRowSelected($event: EventModel) {
    //console.error($event);
    if (!$event || !$event.data) {
      Log.errorMessage("Row selected was called with null data object.");
      return;
    }
    if (this.editorOptions.rowSelectedAction === "view" || !this.canEdit()) {
      this.view($event.data, Helper.tryGetValue($event, x => x.cargo.index, -1));
    } else if (this.editorOptions.rowSelectedAction === "edit" && this.canEdit()) {
      this.edit($event.data, Helper.tryGetValue($event, x => x.cargo.index, -1), "");
    }
  }


  protected add() {
    // If not saving changes to server modal is preferred and route is not allowed
    if (this.editorOptions.addMethod === "modal" || !this.saveChangesToServer) {
      this.addMethodModal();
    } else if (this.editorOptions.addMethod === "route" && this.saveChangesToServer) {
      this.addMethodRoute();
    } else {
      this.addMethodInline();
    }
  }

  protected addMethodInline() {
    this.addData = this.getNewDataObject();
    this.addFormStatus = new FormStatusModel();
    this.mode = "add";
  }

  protected addMethodModal() {
    // If we don't have a modal component then use inline
    if (!this.modalComponent) {
      if (this.modalForm) {
        this.addMethodModalDynamicForm();
      } else {
        this.addMethodInline();
      }
      return;
    }
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.title = `New ${this.editorOptions.objectFriendlyName}`;
    // Open the modal
    const modalRef = this.ngbModalService.open(this.modalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.data = this.getNewDataObject();
    modalRef.componentInstance.disabled = false;
    modalRef.componentInstance.mode = "add";
    this.setupCommonModalOptions(modalRef);
    if (this.modalComponentInjectionAddMode) {
      for (const property in this.modalComponentInjectionAddMode) {
        modalRef.componentInstance[property] = this.modalComponentInjectionAddMode[property];
      }
    }
    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModel) => {
      // User hit ok so value.data is the data object.  If in add mode then add to data store.  If in edit mode then update data store.
      this.addSave(value.data);
    }, (reason) => {
      // User hit cancel so nothing to save
    });
  }

  protected addMethodModalDynamicForm() {

    // If we don't have a form then use inline
    if (!this.modalForm) {
      this.addMethodInline();
      return;
    }
    let options: ModalCommonOptions = this.modalOptions;
    if (!options) {
      options = ModalCommonOptions.defaultDataEntryModalOptions();
      options.title = `New ${this.editorOptions.objectFriendlyName}`;
    }

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Data: TEdit } = { Data: this.getNewDataObject() };

    const promise = this.uxService.modal.showDynamicFormModal(options, this.modalForm, payload);
    // Set actions when modal promise is resolved with either ok or cancel
    promise.then((event: EventModelTyped<{ Data: TEdit }>) => {
      // User hit ok so event.data.Data is the data object.  If in add mode then add to data store.  If in edit mode then update data store.
      this.addSave(event.data.Data);
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }

  protected addMethodRoute() {
    if (this.addRoute) {
      this.router.navigate([this.addRoute]);
    }
    // Missing route?  Then go inline.
    this.addMethodInline();
  }

  protected addSave(data: TEdit) {

    if (!this.saveChangesToServer) {
      // Not saving to server so done
      this.onAddSaveStart();
      this.addDone(data);
      return;
    }

    if (this.editorOptions.objectEnvelopePropertyName && data[this.editorOptions.objectEnvelopePropertyName]) {
      data = data[this.editorOptions.objectEnvelopePropertyName];
    }

    if (this.scopeInApiRequests) {
      for (const property in this.scope) {
        data[property] = this.scope[property];
      }
    }

    // Save to server
    const apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Add);
    this.working = true;
    this.onAddSaveStart();
    this.apiService.execute(apiCall, data).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        this.working = false;
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        this.addDone(result.Data.Data);
      } else {
        this.working = false;
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        this.onAddSaveError();
      }
    });
  }

  protected addDone(data: TEdit) {

    // Update our add data object in case needed in onAddSaveSuccess() method
    this.addData = data;
    // Add new object to our data array
    this.data.push(<any>data);
    // For performance reasons we need to tell the table there's new data to display which we do via attribute binding on tableReloadCount
    this.tableReloadCount++;

    // Emit change event for anyone listening
    this.change.emit(new EventModel("change", "add", data));

    // Fire add save success method that subclasses may have defined with action
    this.onAddSaveSuccess();

    // If we are going to navigate away from the add form to an edit form we will trigger a
    // warning about unsaved changes unless we set the form to pristine.
    this.formResetCount++;
    this.addFormStatus.isPristine = true;

    // Upon save from modal we switch to list mode and refresh the list
    // Upon save from inline or route we switch to edit mode
    if (this.editorOptions.addMethod === "modal") {
      this.mode = "list";
    } else {
      this.edit(data, this.data.length - 1, "new");
    }

  }

  protected onAddSaveStart() {
  }
  protected onAddSaveSuccess() {
  }
  protected onAddSaveError() {
  }

  public onAddFormStatusChange($event) {
    if ($event && $event.data) {
      this.addFormStatus = $event.data;
    }
  }

  public onAddFormSave($event) {
    this.addSave(this.addData);
  }

  public onAddFormClose($event) {

    if (!this.addFormStatus || this.addFormStatus.isPristine) {
      // Add form wasn't touched so nothing to do
      if (this.editorOptions.formCloseButtonPerformsBrowserBackOperation && this.browserHasHistory()) {
        if (this.browserBack()) {
          return;
        }
      } else if (this.editorOptions.addMethod === "route" && this.listRoute) {
        this.router.navigate([this.listRoute]);
      }
      this.mode = "list";
      return;
    }

    // Form was touched so let's ask the user if they want to lose changes
    const promise = this.uxService.modal.confirmUnsavedChanges();
    promise.then((value) => {
      // user chose ok so willing to lose changes
      // Prevent possible double discard warning
      this.warnAboutUnsavedChanges = false;
      if (this.editorOptions.formCloseButtonPerformsBrowserBackOperation && this.browserHasHistory()) {
        if (this.browserBack()) {
          return;
        }
      } else if (this.editorOptions.addMethod === "route" && this.listRoute) {
        this.router.navigate([this.listRoute]);
      }
      this.mode = "list";
    }, (reason) => {
      // user chose cancel so do nothing and let them finish the form later
    });

  }


  protected view(data: any, rowIndex: number) {
    if (!data) {
      Log.errorMessage("View was called with null data object.  Often due to no row data selected when menu event was fired.");
      return;
    }
    // If not saving changes to server modal is preferred and route is not allowed
    if (this.editorOptions.viewMethod === "modal" || !this.saveChangesToServer) {
      this.viewMethodModal(data, rowIndex);
    } else if (this.editorOptions.viewMethod === "route" && this.saveChangesToServer) {
      this.viewMethodRoute(data, rowIndex);
    } else {
      this.viewMethodInline(data, rowIndex);
    }
  }

  protected viewMethodInline(data: any, rowIndex: number) {
    if (this.editorOptions.listModelMatchesFullModel) {
      this.editDataOriginal = data;
      this.editData = Helper.deepCopy(data);
      this.editDataIndex = rowIndex;
      //this.editFormStatus = null;
      this.mode = "view";
    } else {
      // Some lists use different view models than used for edit so let's get the object we can edit as it wasn't what got passed in
      this.editLoadData(data[this.editorOptions.objectKeyPropertyName], rowIndex);
      this.editDataIndex = rowIndex;
      this.mode = "view";
    }
  }

  protected viewMethodModal(data: any, rowIndex: number) {
    // If we don't have a modal component then use inline
    if (!this.modalComponent) {
      this.viewMethodInline(data, rowIndex);
      return;
    }
    this.viewOrEditMethodModal("view", data, rowIndex);
  }

  protected viewMethodRoute(data: any, rowIndex: number) {
    if (this.viewRouteBase) {
      this.router.navigate([this.viewRouteBase, data[this.editorOptions.objectKeyPropertyName], Helper.encodeURISlug(this.getObjectDescription(data))]);
      return;
    }
    // Missing route?  Then go inline.
    this.viewMethodInline(data, rowIndex);
  }


  protected edit(data: any, rowIndex: number, context: "" | "new") {
    if (!data) {
      Log.errorMessage("Edit was called with null data object.  Often due to no row data selected when menu event was fired.");
      return;
    }
    // If not saving changes to server modal is preferred and route is not allowed
    if (this.editorOptions.editMethod === "modal" || !this.saveChangesToServer) {
      this.editMethodModal(data, rowIndex);
    } else if (this.editorOptions.editMethod === "route" && this.saveChangesToServer) {
      this.editMethodRoute(data, rowIndex, context);
    } else {
      this.editMethodInline(data, rowIndex);
    }
  }

  protected editMethodInline(data: any, rowIndex: number) {
    if (this.editorOptions.listModelMatchesFullModel) {
      this.editDataOriginal = data;
      this.editData = Helper.deepCopy(data);
      this.editDataIndex = rowIndex;
      //this.editFormStatus = null;
      this.mode = "edit";
    } else {
      // Some lists use different view models than used for edit so let's get the object we can edit as it wasn't what got passed in
      this.editLoadData(data[this.editorOptions.objectKeyPropertyName], rowIndex);
      this.editDataIndex = rowIndex;
      this.mode = "edit";
    }
  }

  protected editLoadData(id: any, rowIndex: number, ignoreCache: boolean = false) {

    // Not saving to server so read from our list
    if (!this.saveChangesToServer) {
      this.onEditLoadStart(id, rowIndex);
      let index = rowIndex;
      if (index === -1) {
        index = this.getIndexFromId(id);
      }
      if (index > -1) {
        this.editDataOriginal = <any>this.data[index];
        this.editData = Helper.deepCopy(<any>this.data[index]);
      } else {
        this.editDataOriginal = this.getNewDataObject();
        this.editData = this.getNewDataObject();
        Log.errorMessage(`Unable to find index for data list entry with id ${id}`);
      }
      this.onEditLoadSuccess();
      return;
    }

    // Load from server
    this.loading = true;
    const apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Get);
    apiCall.cacheIgnoreOnRead = (ignoreCache || this.editorOptions.cacheIgnore);
    this.onEditLoadStart(id, rowIndex);
    //console.error("load", id, apiCall);
    let query: any = {};
    if (this.editorOptions.objectLoadProperties) {
      query = this.editorOptions.objectLoadProperties;
    }
    if (this.scopeInApiRequests) {
      for (const property in this.scope) {
        query[property] = this.scope[property];
      }
    }
    query[this.editorOptions.objectKeyPropertyName] = id;
    this.apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapperTyped<TEdit>) => {
      if (result.Data.Success) {
        if (this.editorOptions.objectEnvelopePropertyName) {
          const orig = Helper.deepCopy(this.editorOptions.objectTemplate);
          const copy = Helper.deepCopy(this.editorOptions.objectTemplate);
          orig[this.editorOptions.objectEnvelopePropertyName] = result.Data.Data;
          copy[this.editorOptions.objectEnvelopePropertyName] = Helper.deepCopy(result.Data.Data);
          this.editDataOriginal = orig;
          this.editData = copy;
        } else {
          this.editDataOriginal = result.Data.Data;
          this.editData = Helper.deepCopy(result.Data.Data);
        }
        this.formResetCount++;
        //this.editFormStatus = null;
        this.loading = false;
        this.onEditLoadSuccess();
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        this.loading = false;
        this.onEditLoadError();
        // Stay in list mode
        this.mode = "list";
      }
    });
  }

  protected onEditLoadStart(id: any, rowIndex: number) {
  }
  protected onEditLoadSuccess() {
    // Push into our history list
    if (this.mode === "edit" && this.editorOptions.editMethod === "route" && this.editData && this.editorOptions) {
      const id = `${this.editorOptions.objectFriendlyName}-${this.editData[this.editorOptions.objectKeyPropertyName]}`;
      const description = `${Helper.left(this.getObjectDescription(this.editData), 40, true)} (${this.editorOptions.objectFriendlyName})`;
      this.appService.pageHistory.add(id, description, this.router.url);
    }
  }
  protected onEditLoadError() {
  }

  protected editMethodModal(data: any, rowIndex: number) {
    // If we don't have a modal component then use inline
    if (!this.modalComponent && !this.modalForm) {
      this.editMethodInline(data, rowIndex);
      return;
    }
    this.viewOrEditMethodModal("edit", data, rowIndex);
  }

  protected viewOrEditMethodModal(mode: "add" | "edit" | "view", data: any, rowIndex: number) {

    let showModal: () => void;
    // showModal will different depending on if we have a modalComponent or modalForm we are working with
    if (this.modalComponent) {
      showModal = () => {
        const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
        options.mode = mode;
        if (this.editorOptions.headerTextEditModeIncludeObjectDescription) {
          options.title = this.editorOptions.headerTextEditModePrefix + " " + this.getObjectDescription(this.editData);
        } else {
          options.title = this.editorOptions.headerTextEditModePrefix;
        }
        // Open the modal
        const modalRef = this.ngbModalService.open(this.modalComponent, ModalCommonOptions.toNgbModalOptions(options));
        // Set @Input() properties for our component being used as the modal content
        modalRef.componentInstance.options = options;
        modalRef.componentInstance.data = this.editData;
        modalRef.componentInstance.disabled = false;
        modalRef.componentInstance.mode = mode;
        this.setupCommonModalOptions(modalRef);
        if (this.modalComponentInjectionEditViewMode) {
          for (const property in this.modalComponentInjectionEditViewMode) {
            modalRef.componentInstance[property] = this.modalComponentInjectionEditViewMode[property];
          }
        }
        // Set actions when modal promise is resolved with either ok or cancel
        modalRef.result.then((value: EventModel) => {
          // User hit ok so value.data is the data object.  If in add mode then add to data store.  If in edit mode then update data store.
          // No action needed in view mode
          if (mode === "edit") {
            this.editData = value.data;
            this.editSave(value.data)
          }
        }, (reason) => {
          // User hit cancel so nothing to save
        });
      };
    } else {
      showModal = () => {
        let options: ModalCommonOptions = this.modalOptions;
        if (!options) {
          options = ModalCommonOptions.defaultDataEntryModalOptions();
          options.mode = mode;
          options.title = this.editorOptions.objectFriendlyName;
          if (mode === "edit") {
            options.title = `Edit ${this.editorOptions.objectFriendlyName}`;
          }
        }
        // Note that since forms allow interacting with different data object types, data is always a container of objects
        // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
        // like data.Customer.Name, data.Invoice.Date, etc.
        const payload: { Data: TEdit } = { Data: this.editData };
        const promise = this.uxService.modal.showDynamicFormModal(options, this.modalForm, payload);
        // Set actions when modal promise is resolved with either ok or cancel
        promise.then((event: EventModelTyped<{ Data: TEdit }>) => {
          // User hit ok so event.data.Data is the data object.  If in add mode then add to data store.  If in edit mode then update data store.
          // No action needed in view mode
          if (mode === "edit") {
            this.editData = event.data.Data;
            this.editSave(event.data.Data)
          }
        }, (reason) => {
          // User hit cancel so nothing to save
        });
      };
    }

    if (this.editorOptions.listModelMatchesFullModel || !this.saveChangesToServer) {
      this.editData = Helper.deepCopy(data);
      this.editDataIndex = rowIndex;
      this.editFormStatus = new FormStatusModel();
      showModal();
    } else {
      // Some lists use different view models than used for edit so let's get the object we can edit as it wasn't what got passed in
      const apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Get);
      apiCall.cacheIgnoreOnRead = this.editorOptions.cacheIgnore;
      // This was previously passed in as data to execute method: data[this.editorOptions.objectKeyPropertyName]
      // which is fine for top tier objects but for child objects that need to resolve route data we need the object
      if (this.scopeInApiRequests) {
        for (const property in this.scope) {
          data[property] = this.scope[property];
        }
      }
      this.apiService.execute(apiCall, data).subscribe((result: IApiResponseWrapperTyped<TEdit>) => {
        if (result.Data.Success) {
          this.editData = Helper.deepCopy(result.Data.Data);
          this.editFormStatus = new FormStatusModel();
          showModal();
        } else {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          // Stay in list mode
        }
      });
    }

  }

  protected editMethodRoute(data: any, rowIndex: number, context: "" | "new") {
    if (this.editRouteBase) {
      if (context) {
        // If we have a context for going into edit mode pass that along so we can make any
        // context specific decisions we might want to make.
        this.router.navigate([this.editRouteBase, data[this.editorOptions.objectKeyPropertyName], Helper.encodeURISlug(this.getObjectDescription(data))], { queryParams: { context: context } });
      } else {
        this.router.navigate([this.editRouteBase, data[this.editorOptions.objectKeyPropertyName], Helper.encodeURISlug(this.getObjectDescription(data))]);
      }
      return;
    }
    // Missing route?  Then go inline.
    this.editMethodInline(data, rowIndex);
  }

  private setupCommonModalOptions(modalRef: NgbModalRef) {
    // Inject any custom properties our modal needs
    if (this.modalComponentInjection) {
      for (const property in this.modalComponentInjection) {
        modalRef.componentInstance[property] = this.modalComponentInjection[property];
      }
    }
    // Inject any custom event bindings our modal needs
    if (this.modalComponentEventBindings) {
      for (const eventName in this.modalComponentEventBindings) {
        modalRef.componentInstance[eventName]?.subscribe((eventData: any) => {
          this.modalComponentEventBindings[eventName]?.(eventData)
        });
      }
    }
  }


  protected autoSaveSetup(enabled: boolean) {

    // If auto save is already in the desired state then we have no action to take
    if (this.autoSave && enabled) {
      return;
    } else if (!this.autoSave && !enabled) {
      return;
    }

    this.autoSave = enabled;

    if (this.autoSaveSubscription) {
      this.autoSaveSubscription.unsubscribe();
    }

    if (!this.autoSave) {
      // No auto save so no need to setup the timer
      return;
    }

    this.autoSaveSubscription = timer(0, this.autoSaveInterval)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {

        // If form is pristine then no action to take
        if (this.editFormStatus.isPristine) {
          //console.error("pristine so no auto save", new Date());
          return;
        }

        // If form is not valid then no action to take
        if (!this.editFormStatus.isValid) {
          //console.error("not valid so no auto save", new Date());
          return;
        }

        // If we're already saving then we don't want to fire off save event again
        if (this.working) {
          //console.error("already mid-save so no auto save", new Date());
          return;
        }

        // Auto save
        //console.error("auto save", new Date());
        this.editSave(this.editData, {}, false, true, true);

      });

  }


  /**
   * Determine if the data object has deleted data objects in its meta data.
   * This is used to determine if we can do a merge or must do a full put on
   * edit since merge doesn't handle deleted data objects very well.
   * @param data
   * @returns
   */
  protected hasDeletedDataObjects(data: TEdit): boolean {
    try {
      if ((data as any)?.MetaData?.DeletedDataObjects && (data as any).MetaData.DeletedDataObjects.length > 0) {
        return true;
      }
    } catch (err) {
      Log.errorMessage(err);
    }
    return false;
  }



  /**
   * Compares original with current modified edit object and returns a difference object that can be
   * used for submitting an edit via custom MERGE http verb.  Can be overridden by subclasses that
   * may have custom needs for how this diff object is built.
   */
  protected getEditObjectDiff(data: TEdit): any {

    let diff: any = {};

    // Get the diff of the original object and current object to capture what properties changed.
    // We always need the PK in the payload and MetaData.CurrentRowVersion and MetaData.DeletedDataObjects are also critical properties.
    if (this.editorOptions.objectEnvelopePropertyName) {
      diff = Helper.objectDiff(this.editDataOriginal[this.editorOptions.objectEnvelopePropertyName], data[this.editorOptions.objectEnvelopePropertyName], this.editorOptions.objectKeyPropertyName, "MetaData");
    } else {
      diff = Helper.objectDiff(this.editDataOriginal, data, this.editorOptions.objectKeyPropertyName, "MetaData");
    }

    // Trim down to just the MetaData we need for successful merge
    if (diff && diff.MetaData) {
      diff.MetaData = { CurrentRowVersion: diff.MetaData.CurrentRowVersion, DeletedDataObjects: diff.MetaData.DeletedDataObjects };
    }

    return diff;

  }

  protected skipDiffMerge(diff: any) {

    if (!this.editSaveUsingMergeSkipIfEmptyDiff) {
      return false;
    }

    const properties: string[] = Helper.objectGetPropertyNameList(diff);
    if (properties && properties.length === 2 && properties.includes(this.editorOptions.objectKeyPropertyName) && properties.includes("MetaData")) {
      // If we only have 2 properties and they are the PK and MetaData then our diff is empty in terms of data to merge
      return true;
    }

    return false;

  }



  /**
   * This saves edit data.
   * @param data
   * @param cargo - optional object to carry any needed data about the save context.  This cargo passed to onSaveStart|Success|Error in case needed.
   * One practical example is things like close case where ui is different if closed or not and server may have rejected close attempt and then
   * onEditSaveError needs to know if it was a close request that failed so it can update the ui appropriately.
   * @param overwriteChanges - when true header is passed to api indicating we win any version conflicts.  This should typically only be true
   * when there is a callback with user verification that is what they want done.
   * @param silent - when true the api call is submitted in silent mode w/o eye candy.
   * @param autoSave - when true the method was called by auto-save timer.  This is used to inform how we handle the response recognizing that
   * the user could still be editing the form when auto-save was executed and, therefore, the current state of the form may be different than
   * what was saved and, therefore, different than the save response payload.
   */
  protected editSave(data: TEdit, cargo: any = {}, overwriteChanges: boolean = false, silent: boolean = false, autoSave: boolean = false) {

    //console.error("form status", this.editFormStatus);

    this.onEditSaveStart(cargo);

    if (!this.saveChangesToServer) {
      // Not saving to server so done
      this.editDone(data, cargo);
      return;
    }

    // Save to server
    let save: boolean = true;
    if (this.editSaveOnlyWhenModified) {
      // We sometimes push temp helper data in MetaData.Properties and, in any case, meta data isn't editable data so ignore when comparing equality
      save = !Helper.objectEquals(data, this.editDataOriginal, ["MetaData"]);
    }
    if (save) {
      let apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Edit);
      let dataToSave: any = data;
      if (this.editorOptions.objectEnvelopePropertyName) {
        dataToSave = data[this.editorOptions.objectEnvelopePropertyName];
      }
      if (this.editSaveUsingMerge && !this.hasDeletedDataObjects(data)) {
        const diff: any = this.getEditObjectDiff(data);
        if (this.editSaveUsingMergeSkipIfEmptyDiff && this.skipDiffMerge(diff)) {
          //console.error("skip diff merge");
          this.formResetCount++;
          this.onEditSaveSuccess(cargo);
          return;
        }
        apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Merge);
        dataToSave = diff;
      }
      apiCall.overwriteChanges = overwriteChanges;
      apiCall.silent = silent;
      if (this.scopeInApiRequests) {
        for (const property in this.scope) {
          dataToSave[property] = this.scope[property];
        }
      }
      this.working = true;
      this.apiService.execute(apiCall, dataToSave).subscribe((result: IApiResponseWrapper) => {
        if (result.Data.Success) {
          this.working = false;
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          this.editDone(result.Data.Data, cargo, autoSave);
        } else {
          this.working = false;
          let buttons: ButtonItem[] = [];
          // Get action buttons needed for version conflicts (Result Code 1611)
          if (result.Data.ResultCode === 1611) {
            buttons = this.appService.alertManager.getButtonsForVersionConflict(
              apiCall,
              this.getObjectDescription(data),
              () => {
                this.editSave(data, cargo, true);
              },
              () => {
                this.editLoadData(data[this.editorOptions.objectKeyPropertyName], -1, true);
              });
          }
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall, buttons);
          this.onEditSaveError(cargo);
        }
      });
    } else {
      // Object wasn't modified and we only want to save when it is modified so fire event that we're done
      this.editDone(this.editDataOriginal, cargo);
    }

  }

  protected editDone(data: TEdit, cargo: any = {}, autoSave: boolean = false) {

    // Update our data array with this updated object
    this.updateDataArray(data, this.editDataIndex, autoSave);

    // Emit change event for anyone listening
    this.change.emit(new EventModel("change", "edit", data));

    // Upon save from modal we switch to list mode and refresh the list
    // Upon save from inline or route we reset form to pristine and continue in edit mode
    if (this.editorOptions.editMethod === "modal") {
      this.mode = "list";
      this.tableReloadCount++;
    } else {
      if (this.editorOptions.objectEnvelopePropertyName) {
        // With auto-save we just update CurrentRowVersion in case the object was further modified during auto-save action
        if (autoSave && (data as any).MetaData?.CurrentRowVersion) {
          this.editDataOriginal[this.editorOptions.objectEnvelopePropertyName].MetaData.CurrentRowVersion = (data as any).MetaData.CurrentRowVersion;
          this.editData[this.editorOptions.objectEnvelopePropertyName].MetaData.CurrentRowVersion = (data as any).MetaData.CurrentRowVersion;
        } else {
          const orig = Helper.deepCopy(this.editorOptions.objectTemplate);
          const copy = Helper.deepCopy(this.editorOptions.objectTemplate);
          orig[this.editorOptions.objectEnvelopePropertyName] = data;
          copy[this.editorOptions.objectEnvelopePropertyName] = Helper.deepCopy(data);
          this.editDataOriginal = orig;
          this.editData = copy;
        }
      } else { // No object envelope
        // With auto-save we just update CurrentRowVersion in case the object was further modified during auto-save action
        if (autoSave && (data as any).MetaData?.CurrentRowVersion) {
          (this.editDataOriginal as any).MetaData.CurrentRowVersion = (data as any).MetaData.CurrentRowVersion;
          (this.editData as any).MetaData.CurrentRowVersion = (data as any).MetaData.CurrentRowVersion;
        } else {
          this.editDataOriginal = data;
          this.editData = Helper.deepCopy(data);
        }
      }
      this.formResetCount++;
    }

    // Fire edit save success method that subclasses may have defined with action
    this.onEditSaveSuccess(cargo);

  }


  protected onEditSaveStart(cargo: any = {}) {
  }
  protected onEditSaveSuccess(cargo: any = {}) {
  }
  protected onEditSaveError(cargo: any = {}) {
  }


  public onEditFormStatusChange($event) {
    //console.error("status change", $event.data);
    if ($event && $event.data) {
      this.editFormStatus = $event.data;
    }
  }

  public onEditFormSave($event) {
    this.editSave(this.editData);
  }

  public onEditFormClose($event) {

    if (this.mode === "view" || !this.editFormStatus || this.editFormStatus.isPristine) {
      // In view mode or edit form wasn't touched so nothing to do
      if (this.editorOptions.formCloseButtonPerformsBrowserBackOperation && this.browserHasHistory()) {
        if (this.browserBack()) {
          return;
        }
      } else if (this.mode === "view" && this.editorOptions.viewMethod === "route" && this.listRoute) {
        this.router.navigate([this.listRoute]);
      } else if (this.mode === "edit" && this.editorOptions.editMethod === "route" && this.listRoute) {
        this.router.navigate([this.listRoute]);
      }
      this.mode = "list";
      return;
    }

    // Form was touched so let's ask the user if they want to lose changes
    const promise = this.uxService.modal.confirmUnsavedChanges();
    promise.then((value) => {
      // user chose ok so willing to lose changes
      // Prevent possible double discard warning
      this.warnAboutUnsavedChanges = false;
      if (this.editorOptions.formCloseButtonPerformsBrowserBackOperation && this.browserHasHistory()) {
        if (this.browserBack()) {
          return;
        }
      } else if (this.editorOptions.editMethod === "route" && this.listRoute) {
        this.router.navigate([this.listRoute]);
      }
      this.mode = "list";
    }, (reason) => {
      // user chose cancel so do nothing and let them finish the form later
    });

  }


  /**
   * Event that gets fired when table builds a query object so that same query can be utilized when doing data export.
   * This is a public method so it can be referenced in html views.
   * @param $event
   */
  public onQueryPrepared($event: EventModelTyped<Query>) {
    this.lastKnownTableQuery = $event.data;
  }


  //protected export() {
  //  let apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Export);
  //  this.working = true;
  //  this.apiService.execute(apiCall, this.lastKnownTableQuery).subscribe((result: IApiResponseWrapper) => {
  //    if (result.Data.Success) {
  //      this.working = false;
  //      this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
  //    } else {
  //      this.working = false;
  //      this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
  //    }
  //    this.mode = "download";
  //  });
  //}

  //protected download() {
  //  this.mode = "download";
  //}

  protected upload() {
    this.mode = "upload";
  }

  public download($event) {

    // If we don't have any filter in place we can just set the mode to download to show that view
    // If we do have a filter then we'll show a modal where the user can request an export using the filter, no filter, or download previous exports.
    if (!this.lastKnownTableQuery || (!this.lastKnownTableQuery.Q && !this.lastKnownTableQuery.Filter && !this.lastKnownTableQuery.FilterId)) {
      this.mode = "download";
      return;
    }

    //console.error("contact picker button", $event);
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    options.title = "Download"
    // Open the modal
    const modalRef = this.ngbModalService.open(DataEditorExportModalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.apiProperties = this.apiProperties;
    modalRef.componentInstance.currentFilter = this.lastKnownTableQuery;
    modalRef.componentInstance.allowExportWithoutFilter = this.editorOptions.downloadAllowExportWithoutFilter;
    modalRef.componentInstance.showThatFilterWillBeApplied = this.editorOptions.downloadShowThatFilterWillBeApplied;

    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModel) => {
      // User hit ok so switch to download mode on the data editor view
      this.mode = "download";
    }, (reason) => {
      // User hit cancel so nothing to do
    });

  }


  protected copy(data: any, rowIndex: number) {

    if (!data) {
      Log.errorMessage("Copy was called with null data object.  Often due to no row data selected when menu event was fired.");
      return;
    }

    const copyAlias = Helper.getFirstDefinedString(this.editorOptions?.copyAlias, "Copy");
    const copyAliasLower = copyAlias.toLowerCase();

    if (!this.canAdd()) {
      this.uxService.modal.alertWarning(`Can't ${copyAlias}`, `You do not have permissions to ${copyAliasLower} this.`);
      return;
    }

    const promise = this.uxService.modal.confirmInfoYesNo(`${copyAlias} ${this.editorOptions.objectFriendlyName}? `, `${copyAlias} ${this.getObjectDescription(data)}?`, null);
    promise.then((answer) => {

      if (!this.saveChangesToServer) {
        // Not saving to server so make local copy
        const copy = Helper.deepCopy(data);
        // Null out PK and push into our data array
        copy[this.editorOptions.objectKeyPropertyName] = null;
        this.data.push(<any>copy);
        // For performance reasons we need to tell the table there's new data to display which we do via attribute binding on tableReloadCount
        this.tableReloadCount++;
        this.change.emit(new EventModel("change", "add", copy));
        // this.addData is null here.... this.onAddSaveSuccess();
        return;
      }

      // Copy on server
      const apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Copy);
      // This was previously passed in as data to execute method: data[this.editorOptions.objectKeyPropertyName]
      // which is fine for top tier objects but for child objects that need to resolve route data we need the object
      if (this.scopeInApiRequests) {
        for (const property in this.scope) {
          data[property] = this.scope[property];
        }
      }
      this.apiService.execute(apiCall, data).subscribe((result: IApiResponseWrapper) => {
        if (result.Data.Success) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          this.change.emit(new EventModel("change", "add", result.Data.Data));
          // this.addData is null here.... this.onAddSaveSuccess();
          this.data.push(result.Data.Data);
          this.mode = "list";
          this.tableReloadCount++;
        } else {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          this.onAddSaveError();
        }

      });
    }, (cancelled) => {
      // No action
    });
  }



  protected delete(data, rowIndex: number) {

    if (!data) {
      Log.errorMessage("Delete was called with null data object.  Often due to no row data selected when menu event was fired.");
      return;
    }
    if (!this.canDelete()) {
      this.uxService.modal.alertWarning("Can't Delete", "You do not have permissions to delete this.");
      return;
    }

    // Get the message we are going to ask user before deleting the object
    let message = "";
    if (this.editorOptions.getDeleteObjectMessage) {
      try {
        message = this.editorOptions.getDeleteObjectMessage(data);
      } catch (err) {
        console.error(err);
      }
    }
    if (!message) {
      message = `Delete ${this.getObjectDescription(data)}?`;
    }

    // Ask and delete if told yes
    const promise = this.uxService.modal.confirmDelete(message, null);
    promise.then((answer) => {

      if (!this.saveChangesToServer) {
        let index = rowIndex;
        if (index === -1) {
          index = this.getIndexFromObject(data);
        }
        if (index > -1) {
          this.data.splice(index, 1);
          // For performance reasons we need to tell the table there's new data to display which we do via attribute binding on tableReloadCount
          this.tableReloadCount++;
          // For delete event the $event.data property is IDeletedDataObject
          this.change.emit(new EventModel("change", "delete", { ObjectType: this.apiProperties.documentation.objectName, ObjectId: data[this.editorOptions.objectKeyPropertyName] }));
          this.onDeleteSuccess();
        }
        return;
      }

      if (this.editorOptions.objectEnvelopePropertyName && data[this.editorOptions.objectEnvelopePropertyName]) {
        data = data[this.editorOptions.objectEnvelopePropertyName];
      }

      if (this.scopeInApiRequests) {
        for (const property in this.scope) {
          data[property] = this.scope[property];
        }
      }

      // Delete on server
      const apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Delete);
      // This was previously passed in as data to execute method: data[this.editorOptions.objectKeyPropertyName]
      // which is fine for top tier objects but for child objects that need to resolve route data we need the object
      this.apiService.execute(apiCall, data).subscribe((result: IApiResponseWrapper) => {
        if (result.Data.Success) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          if (this.data && this.data.length > 0) {
            const index = this.getIndexFromObject(data);
            if (index > -1) {
              this.data.splice(index, 1);
              // For performance reasons we need to tell the table there's new data to display which we do via attribute binding on tableReloadCount
              this.tableReloadCount++;
            }
          }
          this.change.emit(new EventModel("change", "delete", { ObjectType: this.apiProperties.documentation.objectName, ObjectId: data[this.editorOptions.objectKeyPropertyName] }));
          this.onDeleteSuccess();
          if (this.mode !== "list") {
            this.mode = "list";
            if (this.editorOptions.formCloseButtonPerformsBrowserBackOperation && this.browserHasHistory()) {
              if (this.browserBack()) {
                return;
              }
            } else if (this.listRoute) {
              this.router.navigate([this.listRoute]);
            }
          }
          this.tableReloadCount++;
        } else {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          this.onDeleteError();
        }
      });
    }, (cancelled) => {
      // No action
    });

  }


  protected onDeleteSuccess() {
  }
  protected onDeleteError() {
  }


  public onCloseExportImport() {
    this.mode = "list";
    // Drop any hash from url
    history.pushState("", document.title, window.location.pathname + window.location.search);
  }


  protected updateDataArray(data: TEdit, index: number, autoSave: boolean = false): void {

    // If we're in edit mode with routing we don't have a data array to update as that array gets
    // populated in list mode which only applies when we're on the list route.
    if (this.mode === "edit" && this.editorOptions.editMethod === "route") {
      return;
    }
    // No data = no action
    if (!data) {
      return;
    }

    // Unwrap data from envelope if needed
    if (this.editorOptions.objectEnvelopePropertyName && data[this.editorOptions.objectEnvelopePropertyName]) {
      data = data[this.editorOptions.objectEnvelopePropertyName];
    }

    if (!this.data || this.data.length === 0) {
      this.data = [];
      this.data.push(<any>data);
      // For performance reasons we need to tell the table there's new data to display which we do via attribute binding on tableReloadCount
      this.tableReloadCount++;
      Log.errorMessage(`Data array was empty so pushing object with id ${data[this.editorOptions.objectKeyPropertyName]}`);
      return;
    }

    // If we were not given an index then try to figure it out
    if (index === -1) {
      index = this.getIndexFromObject(data);
    }

    if (index > -1) {
      // With auto-save we just update CurrentRowVersion in case the object was further modified during auto-save action
      if (autoSave && (data as any).MetaData?.CurrentRowVersion && (this.data[index] as any).MetaData) {
        (this.data[index] as any).MetaData.CurrentRowVersion = (data as any).MetaData.CurrentRowVersion;
      } else {
        this.data[index] = <any>data;
      }
    } else {
      this.data.push(<any>data);
    }
    // For performance reasons we need to tell the table there's new data to display which we do via attribute binding on tableReloadCount
    this.tableReloadCount++;

    return;

  }

  protected getIndexFromId(id: any): number {
    return Enumerable.from(this.data).indexOf(x => x[this.editorOptions.objectKeyPropertyName] === id);
  }

  protected getIndexFromObject(obj: any): number {
    const id: any = obj[this.editorOptions.objectKeyPropertyName];
    if (id) {
      const index = this.getIndexFromId(id);
      if (index === -1) {
        Log.errorMessage(`Unable to find index for data list entry with id ${id}`);
      }
      return index;
    } else {
      // We don't have an id so compare the objects for a match.  This won't happen when we're saving to the server
      // but if we're working with an embedded child object list we may have added objects that do not have a PK
      // assigned yet and then we try to edit that object before saving.
      const index = Enumerable.from(this.data).indexOf(x => Helper.equals(JSON.stringify(x), JSON.stringify(obj), true));
      // Do we have that many index misses that we resort to this?  For embedded lists should we have some surrogate
      // key in metadata we temp match on until data gets saved and a PK created?
      Log.errorMessage("Resorted to JSON object matching for finding the object index!");
      // TODO
      if (index === -1) {
        Log.errorMessage(`Unable to find index for data list entry with object ${JSON.stringify(obj)}`);
      }
      return index;
    }
  }

  /**
   * Get new data object ready to collect data for add operation.
   */
  protected getNewDataObject(): TEdit {
    const data = Helper.deepCopy(this.editorOptions.objectTemplate);
    if (this.scope) {
      for (const property in this.scope) {
        data[property] = this.scope[property];
      }
    }
    return data;
  }


  /**
  Gets a description of the object from the api properties description property names or as fallback
  uses the object description from the api properties.  This can be used when asking about operations
  like delete, copy, etc?
  For example, depending on the api properties configuration and the contents of the object submitted
  this method might return any of the following for a customer object: "Jon Doe", "394854", "this Customer"
  */
  public getObjectDescription = (obj: any, objectTypeFriendlyName: string = "", defaultDescription: string = "this item"): string => {
    if (!obj) {
      return defaultDescription || "this item";
    }
    let description = "";
    if (this.editorOptions.getObjectDescription) {
      try {
        if (this.editorOptions.objectEnvelopePropertyName && obj[this.editorOptions.objectEnvelopePropertyName]) {
          description = this.editorOptions.getObjectDescription(obj[this.editorOptions.objectEnvelopePropertyName]);
        } else {
          description = this.editorOptions.getObjectDescription(obj);
        }
        if (description) {
          return description;
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (!this.apiProperties) {
      return defaultDescription || "this item";
    }
    if (this.editorOptions.objectEnvelopePropertyName && obj[this.editorOptions.objectEnvelopePropertyName]) {
      description = Helper.getFirstDefinedStringFromPropertyNameList(obj[this.editorOptions.objectEnvelopePropertyName], this.apiProperties?.documentation?.objectDescriptionPropertyNames);
    } else {
      description = Helper.getFirstDefinedStringFromPropertyNameList(obj, this.apiProperties?.documentation?.objectDescriptionPropertyNames);
    }
    if (!description) {
      if (this.routeParamFriendlyName) {
        description = this.routeParamFriendlyName;
      } else if (this.routeParamName) {
        description = this.routeParamName;
      } else if (objectTypeFriendlyName) {
        description = `This ${objectTypeFriendlyName}`;
      } else if (this.editorOptions.objectFriendlyName) {
        description = `This ${this.editorOptions.objectFriendlyName}`;
      } else {
        description = `This ${this.apiProperties?.documentation?.objectDescription}`;
      }
    }
    return description;
  }



  protected getDefaultEditorOptions = (): CommonDataEditorOptions => {

    const options = new CommonDataEditorOptions();

    //// Helpful to have call back data initialized to hold any non-TData related data.
    //if (!options.callback.data) {
    //  options.callback.data = {};
    //}

    // Get some object related values we can suggest from our api properties object
    if (this.apiProperties) {

      options.objectTemplate = Helper.deepCopy(this.apiProperties.documentation.requestDataModelObject);

      options.objectFriendlyName = this.apiProperties.documentation.objectDescription;
      if (Helper.equals(options.objectFriendlyName, "Case", true)) {
        options.objectFriendlyName = Helper.getFirstDefinedString(this.appService.getLabel("Case"), "Case");
      }

      if (Helper.isArray(this.apiProperties.documentation.objectPrimaryKey)) {
        options.objectKeyPropertyName = this.apiProperties.documentation.objectPrimaryKey[0];
      } else {
        options.objectKeyPropertyName = <string>this.apiProperties.documentation.objectPrimaryKey;
      }

      options.objectDescriptionPropertyNames = this.apiProperties.documentation.objectDescriptionPropertyNames;
      options.headerTextListMode = this.apiProperties.documentation.objectDescriptionPlural;
      options.headerTextAddMode = `New ${options.objectFriendlyName}`;
      options.headerTextEditModePrefix = `${options.objectFriendlyName} - `;
      options.headerTextDownloadUploadMode = `${options.objectFriendlyName} Data Export / Import`;

      // Default security access area is the one defined in the api documentation
      options.accessArea = this.apiProperties.documentation.securityAccessArea;

    }

    // Pull flag to show header from input attributes.
    options.headerShow = this.showHeader;
    // If we're not hosted by a page then we don't want to be altering our document title for this component
    options.documentTitleMirrorHeaderText = (this.host === "page");

    // Set default input method from input attribute but can override in route data or subclass as needed.
    options.addMethod = this.inputMethod;
    options.editMethod = this.inputMethod;
    options.viewMethod = this.inputMethod;

    // If our editor is embedded as a component as opposed to on it's own page then closing add/edit/view forms
    // (if not already modal) should then go back in browser history to the page that hosted us not just go into
    // list mode.
    //options.formCloseButtonPerformsBrowserBackOperation = (this.host === "component");
    // Our new default behavior is to always do browser back operation on back/close button press
    options.formCloseButtonPerformsBrowserBackOperation = true;

    // Default row selected action is edit
    options.rowSelectedAction = "edit";

    // We default to the model objects being different but if we're not saving data to the server
    // then we can assume we're working with the full model object in the list as it's embedded.
    options.listModelMatchesFullModel = !this.saveChangesToServer;

    // Default no action button in add mode and delete button in edit & view modes
    options.actionButtonAddMode = null;
    options.actionButtonEditMode = new ButtonItem("Delete", "times", "danger");
    options.actionButtonEditMode.action = (event: EventModel) => this.delete(this.editData, -1);
    options.actionButtonEditMode.visible = (data: TEdit) => {
      // eslint-disable-next-line
      if (data && (data as any).MetaData && (data as any).MetaData.ReadOnly) {
        return false;
      }
      return this.canDelete();
    }
    options.actionButtonViewMode = options.actionButtonEditMode;

    // See if route data has any editor options defined in it
    this.updateEditorOptionsFromRouteData(options);

    return options;

  }


  updateEditorOptionsFromRouteData(options: CommonDataEditorOptions): void {
    if (this.ignoreRouteData) {
      return;
    }
    // We allow specifying addMethod, editMethod, viewMethod or a universal inputMethod via route data
    const inputMethod = this.route.snapshot.data["inputMethod"];
    const addMethod = this.route.snapshot.data["addMethod"];
    if (addMethod && ["inline", "modal", "route"].includes(addMethod)) {
      options.addMethod = addMethod;
    } else if (inputMethod && ["inline", "modal", "route"].includes(inputMethod)) {
      options.addMethod = inputMethod;
    }
    const editMethod = this.route.snapshot.data["editMethod"];
    if (editMethod && ["inline", "modal", "route"].includes(editMethod)) {
      options.editMethod = editMethod;
    } else if (inputMethod && ["inline", "modal", "route"].includes(inputMethod)) {
      options.editMethod = inputMethod;
    }
    const viewMethod = this.route.snapshot.data["viewMethod"];
    if (viewMethod && ["inline", "modal", "route"].includes(viewMethod)) {
      options.viewMethod = viewMethod;
    } else if (inputMethod && ["inline", "modal", "route"].includes(inputMethod)) {
      options.viewMethod = inputMethod;
    }
  }


  updateEditorOptionsForExportImport(): void {

    // If we have not opted into object export import support then we're done
    if (!this.appService?.optInFeatures?.ObjectExportImportSupport) {
      return;
    }

    const typeDescription: string = Helper.getFirstDefinedString(this.editorOptions.uploadObjectDescription, this.editorOptions.objectFriendlyName, "Object");

    const exportAction: Action = new Action("export", "Export", "download", "default");
    exportAction.action = ($event: EventModel) => {
      if (!this.editData) {
        console.error(`No ${typeDescription} object found for download.`);
        return;
      }
      const description = this.getObjectDescription(this.editData);
      const fileName = `${typeDescription} ${description} as-of ${Helper.formatDateTime(new Date(), "YYYY-MM-DD-HH-mm-ss")}.json`;
      Helper.saveJSON(this.editData, fileName);
    };
    const importAction: Action = new Action("import", "Import", "upload", "default");
    importAction.action = ($event: EventModel) => {
      this.showFileUploadArea = !this.showFileUploadArea;
    };
    this.editorOptions.actionButtonEditMode.menuPlacement = "bottom-right";

    // In some situations we already switched from delete button to action button in the subclass so before we
    // add the delete option see if it's already in a list of options put in place before this code got called.
    if (!this.editorOptions.actionButtonEditMode.options.some(x => Helper.equals(x.actionId, "delete", true))) {
      // Move delete action from default action to menu item under action button
      const deleteAction: Action = new Action("delete", "Delete", "times", "danger", this.editorOptions.actionButtonEditMode.action);
      this.editorOptions.actionButtonEditMode.options.push(deleteAction);
    }
    this.editorOptions.actionButtonEditMode.options.push(new Action()); // Separator
    this.editorOptions.actionButtonEditMode.options.push(exportAction);
    this.editorOptions.actionButtonEditMode.options.push(importAction);
    this.editorOptions.actionButtonEditMode.label = "Actions";
    this.editorOptions.actionButtonEditMode.contextColor = "warning";
    this.editorOptions.actionButtonEditMode.icon = "cog";
    this.editorOptions.actionButtonEditMode.action = null; // Remove the default delete action since that is now on menu action

    // In add mode we want to support import so we can import into a new object
    if (this.editorOptions.actionButtonAddMode?.options && this.editorOptions.actionButtonAddMode.options.length > 0) {
      // We have existing add mode options so add import to the existing options
      this.editorOptions.actionButtonAddMode.options.push(new Action()); // Separator
      this.editorOptions.actionButtonAddMode.options.push(importAction);
    } else {
      // We don't have any add mode options so import is our only add mode button action
      this.editorOptions.actionButtonAddMode = new ButtonItem();
      this.editorOptions.actionButtonAddMode.label = "Import";
      this.editorOptions.actionButtonAddMode.contextColor = "secondary";
      this.editorOptions.actionButtonAddMode.icon = "upload";
      this.editorOptions.actionButtonAddMode.action = (event) => { this.showFileUploadArea = !this.showFileUploadArea; };
    }

  }


  getDefaultTableOptions(actions: string = "edit,divider,copy,divider,delete", propertyNames: string = ""): TableOptions {

    const options: TableOptions = TableHelper.buildDefaultTableOptions(this.apiProperties, propertyNames);

    // If configured to save changes to the server then 99% we're doing lazy loading of data in the table as well
    options.loadDataFromServer = this.saveChangesToServer;

    // Table actions
    options.actionButtonRight1 = new ButtonItem(`Add ${this.apiProperties.documentation.objectDescription}`, "plus", "primary", (event) => this.add());
    options.actionButtonRight1.size = "sm";
    // See if we have sub-menu for the table action button
    const exportEndpoint = ApiHelper.getApiEndpoint(this.apiProperties, ApiOperationType.Export, "", false);
    const importEndpoint = ApiHelper.getApiEndpoint(this.apiProperties, ApiOperationType.Import, "", false);
    if (exportEndpoint) {
      this.canDownload = this.canOutput();
    }
    if (importEndpoint) {
      this.canUpload = this.canAdd();
    }
    if (this.canDownload || this.canUpload) {
      options.actionButtonRight1.options = [];
      if (this.canAdd()) {
        options.actionButtonRight1.options.push(new Action("add", "Add", "plus", "", (event) => this.add()));
      }
      if (this.canAdd() && (this.canDownload || this.canUpload)) {
        options.actionButtonRight1.options.push(new Action()); // separator
      }
      if (this.canDownload) {
        //options.actionButtonRight1.options.push(new Action("export", "Export", "file-export", "", (event) => this.export()));
        options.actionButtonRight1.options.push(new Action("download", "Download", "cloud-download", "", (event) => this.download(event)));
      }
      if (this.canDownload && this.canUpload) {
        options.actionButtonRight1.options.push(new Action()); // separator
      }
      if (this.canUpload) {
        options.actionButtonRight1.options.push(new Action("upload", "Upload", "cloud-upload", "", (event) => this.upload()));
      }
    } else if (!this.canAdd()) {
      // If we are not supporting upload or download and we can't add then no point in having the add button
      options.actionButtonRight1 = null;
    }

    // Row actions
    options.rowActionButton = this.getDefaultTableRowActions(actions);

    return options;

  }


  getDefaultTableRowActions(actions: string = "edit,divider,copy,divider,delete", button: ButtonItem = null): ButtonItem {

    if (!actions) {
      // No actions specified then we get the defaults
      actions = "edit,divider,copy,divider,delete";
    }

    // Row actions get appended to the button provided but if none provided we will prepare one
    if (!button) {
      button = new ButtonItem("", "bars", "default");
      button.size = "xs";
    }

    // Parse the actions CSV into a list and step through that list of button actions
    const list: string[] = Helper.parseCsvString(actions.toLowerCase());
    list.forEach(actionName => {
      if (Helper.equals(actionName, "divider", true) && button.options.length > 0) {
        button.options.push(new Action("divider"));
      } else if (Helper.equals(actionName, "view", true)) {
        button.options.push(new Action("view", "View", "eye", "", (event) => {
          //console.error("context menu event inside action button handler");
          //console.error(event);
          this.view(event.data, Helper.tryGetValue(event, x => x.cargo.index, -1));
        }));
      } else if (Helper.equals(actionName, "edit", true) && this.canEdit()) {
        button.options.push(new Action("edit", "Edit", "edit", "", (event) => {
          //console.error("context menu event inside action button handler");
          //console.error(event);
          this.edit(event.data, Helper.tryGetValue(event, x => x.cargo.index, -1), "");
        }));
      } else if (Helper.equals(actionName, "copy", true) && this.canAdd()) {
        button.options.push(new Action("copy", "Copy", "copy", "", (event) => {
          //console.error("context menu event inside action button handler");
          //console.error(event);
          this.copy(event.data, Helper.tryGetValue(event, x => x.cargo.index, -1));
        }));
      } else if (Helper.equals(actionName, "delete", true) && this.canDelete()) {
        button.options.push(new Action("delete", "Delete", "times", "", (event) => {
          //console.error("context menu event inside action button handler");
          //console.error(event);
          this.delete(event.data, Helper.tryGetValue(event, x => x.cargo.index, -1));
        }));
      }
    });

    return button;

  }



  onUpload($event) {

    //console.error("upload", $event);
    const description: string = Helper.getFirstDefinedString(this.editorOptions.uploadObjectDescription, this.editorOptions.objectFriendlyName, "Object");
    const descriptionLowerCase: string = description.toLowerCase();

    // Validate and parse file contents
    if (!$event?.data?.contents) {
      this.uxService.modal.alertDanger("Error", `Unable to read ${descriptionLowerCase} file contents.`);
      return;
    }
    let obj: TEdit = null;
    try {
      obj = JSON.parse($event.data.contents);
    } catch (ex) {
      this.uxService.modal.alertDanger("Error", `Unable to parse ${descriptionLowerCase} file contents.<br/><br/>${ex}`);
      return;
    }

    // Fire off upload action if defined
    if (this.editorOptions.uploadAction) {
      const actionSuccess: boolean = this.editorOptions.uploadAction($event, $event.data.contents, obj);
      if (!actionSuccess) {
        // uploadAction said to not continue and should have alerted the user to this
        return;
      }
    }

    // Fire off change detection which for some reason seems needed at this point otherwise we don't seem to
    // progress without a mouse click or other event which I assume is triggering change detection.
    this.uxService.detectChanges();

    // Now check for properties that are required to know this is valid JSON
    if (this.editorOptions.uploadRequiredPropertiesAll && this.editorOptions.uploadRequiredPropertiesAll.length > 0) {
      let hasRequiredProperty: boolean = true;
      this.editorOptions.uploadRequiredPropertiesAll.forEach(property => {
        if (!obj[property]) {
          hasRequiredProperty = false;
          Log.debugMessage(`Uploaded JSON file is missing required property ${property}.`);
        }
      });
      if (!hasRequiredProperty) {
        this.uxService.modal.alertDanger("Error", `This file does not appear to be a properly formatted ${descriptionLowerCase} object.`);
        return;
      }
    }
    if (this.editorOptions.uploadRequiredPropertiesAtLeastOne && this.editorOptions.uploadRequiredPropertiesAtLeastOne.length > 0) {
      let hasRequiredProperty: boolean = false;
      this.editorOptions.uploadRequiredPropertiesAtLeastOne.forEach(property => {
        if (obj[property]) {
          hasRequiredProperty = true;
        }
      });
      if (!hasRequiredProperty) {
        Log.debugMessage(`Uploaded JSON must have at least one of these required properties ${Helper.buildCsvString(this.editorOptions.uploadRequiredPropertiesAtLeastOne)}.`);
        this.uxService.modal.alertDanger("Error", `This file does not appear to be a properly formatted ${descriptionLowerCase} object.`);
        return;
      }
    }
    //console.error("Parsed and ready to go");

    // We keep the upload area visible until now so it's clear we're working on the upload
    this.showFileUploadArea = false;


    // Warn user and if they confirm then take action to have this be our new object
    const message = `<strong class="text-danger">Warning: Your current ${descriptionLowerCase} configuration will be replaced.</strong>  ` +
      `If you have not already done so you should back it up by downloading it first.<br/><br/>` +
      `You should only import files that are from a trusted source.  Are you sure you want to replace your current ${descriptionLowerCase} configuration with the uploaded configuration?`;
    const options = new ModalCommonOptions();
    options.titleContextColor = "danger";
    options.titleIcon = "exclamation-triangle";
    options.title = `Replace ${description} Configuration`;
    options.message = message;
    options.okButtonContextColor = "danger";
    options.okButtonText = "Yes";
    options.cancelButtonContextColor = "default";
    options.cancelButtonText = "No";
    options.size = "larger";
    const promise = this.uxService.modal.showSimpleModal(options);
    promise.then((answer) => {
      //console.error('modal result', answer);
      if (this.mode === "add") {
        // Wipe out PK, FK, and meta data from JSON object so in add mode we get a newly added object
        if (this.editorOptions.objectKeyPropertyName) {
          obj[this.editorOptions.objectKeyPropertyName] = null;
        }
        if ((obj as any).MetaData) {
          (obj as any).MetaData = new m5.MetaDataModel();
        }
        // See if we have any custom transform
        if (this.editorOptions.uploadTransformObjectForAdd) {
          obj = this.editorOptions.uploadTransformObjectForAdd(obj);
        }
        this.addData = obj;
        //console.error(this.addData);
      } else if (this.mode === "edit" || this.mode === "view") {
        // Reset PK and FK data from JSON object so in edit mode we edit the object we are working on
        if (this.editorOptions.objectKeyPropertyName) {
          obj[this.editorOptions.objectKeyPropertyName] = this.editData[this.editorOptions.objectKeyPropertyName];
        }
        // Reset any meta data that was with the imported object as we may be different partitions, databases, etc.
        if ((this.editData as any).MetaData) {
          (obj as any).MetaData = (this.editData as any).MetaData;
        }
        // See if we have any custom transform
        if (this.editorOptions.uploadTransformObjectForEdit) {
          obj = this.editorOptions.uploadTransformObjectForEdit(obj, this.editData);
        }
        this.editData = obj;
        //console.error(this.editData);
      }
      this.formDirtyCount++;
      // Fire off change detection
      this.uxService.detectChanges();
      //const payload: EventModel = new EventModel("change", event, this.data);
      //this.change.emit(payload);
    }, (cancelled) => {
      // No action
    });

  }




  public canRead(): boolean {
    if (!this.permissions) {
      this.permissions = this.appService.security.parsePermissions(this.user, this.editorOptions.accessArea);
    }
    if (!this.permissions) {
      // No user object or no access area could mean anonymous access is allowed so default to yes
      return true;
      //// We don't know if we can do this so we have to assume no
      //return false;
    }
    return this.permissions.read;
  }

  public canAdd(): boolean {
    if (!this.permissions) {
      this.permissions = this.appService.security.parsePermissions(this.user, this.editorOptions.accessArea);
    }
    if (!this.permissions) {
      // No user object or no access area could mean anonymous access is allowed so default to yes
      return true;
      //// We don't know if we can do this so we have to assume no
      //return false;
    }
    return this.permissions.add;
  }

  public canEdit(switchToViewModeIfCannotEdit: boolean = true): boolean {
    if (!this.permissions) {
      this.permissions = this.appService.security.parsePermissions(this.user, this.editorOptions.accessArea);
    }
    if (!this.permissions) {
      // No user object or no access area could mean anonymous access is allowed so default to yes
      return true;
      //// We don't know if we can do this so we have to assume no
      //if (switchToViewModeIfCannotEdit) {
      //  this.mode = "view";
      //}
      //console.warn(`No permissions object for access area "${this.editorOptions.accessArea}" mode set to "${this.mode}".`);
      //return false;
    }
    if (!this.permissions.edit && switchToViewModeIfCannotEdit) {
      console.warn(`No edit permission for access area "${this.editorOptions.accessArea}" mode set to "${this.mode}".`);
      this.mode = "view";
    }
    return this.permissions.edit;
  }

  public canDelete(): boolean {
    if (!this.permissions) {
      this.permissions = this.appService.security.parsePermissions(this.user, this.editorOptions.accessArea);
    }
    if (!this.permissions) {
      // No user object or no access area could mean anonymous access is allowed so default to yes
      return true;
      //// We don't know if we can do this so we have to assume no
      //return false;
    }
    return this.permissions.delete;
  }

  public canOutput(): boolean {
    if (!this.permissions) {
      this.permissions = this.appService.security.parsePermissions(this.user, this.editorOptions.accessArea);
    }
    if (!this.permissions) {
      // No user object or no access area could mean anonymous access is allowed so default to yes
      return true;
      //// We don't know if we can do this so we have to assume no
      //return false;
    }
    return this.permissions.output;
  }

  public canExecute(): boolean {
    if (!this.permissions) {
      this.permissions = this.appService.security.parsePermissions(this.user, this.editorOptions.accessArea);
    }
    if (!this.permissions) {
      // No user object or no access area could mean anonymous access is allowed so default to yes
      return true;
      //// We don't know if we can do this so we have to assume no
      //return false;
    }
    return this.permissions.execute;
  }

  public canFull(): boolean {
    if (!this.permissions) {
      this.permissions = this.appService.security.parsePermissions(this.user, this.editorOptions.accessArea);
    }
    if (!this.permissions) {
      // No user object or no access area could mean anonymous access is allowed so default to yes
      return true;
      //// We don't know if we can do this so we have to assume no
      //return false;
    }
    return this.permissions.full;
  }



  public debug(...items: any[]) {
    if (!items) {
      return;
    }
    items.forEach((item) => {
      console.error(item);
    });
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key == "s") {
      event.preventDefault();

      if (this.mode == "add" && this.addFormStatus && this.addFormStatus.isValid && !this.addFormStatus.isPristine) {
        this.addSave(this.addData);
      }

      else if (this.mode == "edit" && this.editFormStatus && this.editFormStatus.isValid && !this.editFormStatus.isPristine) {
        this.editSave(this.editData);
      }
    }
  }



  /**
   * If user navigates away and the form is dirty and we want to warn about it then present
   * a prompt asking the user to confirm they intend to discard changes.
   */
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (this.mode === "list" || this.mode === "view") {
      return true;
    }

    let status: FormStatusModel = this.editFormStatus;
    if (this.mode === "add") {
      status = this.addFormStatus;
    }
    //console.error("data editor pristine", status.isPristine, "warn", this.warnAboutUnsavedChanges);

    // Allow synchronous navigation ('true') if the form we are watching is not dirty or if we don't care
    if (!status || status.isPristine || !this.warnAboutUnsavedChanges) {
      return true;
    }
    //console.error("ready to ask", "pristine", status.isPristine, "warn", this.warnAboutUnsavedChanges);

    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    let promise = this.uxService.modal.confirmUnsavedChanges();
    //console.error("data editor promise", promise);

    // Convert from promise to observable so we can map to boolean
    let observable = from(promise);

    // Map our observable from EventModel to boolean where true means we can deactivate and false means we cannot.
    return observable.pipe(
      map((response) => {
        let model: EventModel = ModalService.fromSimpleModalResultToEventModel(response);
        //console.error("data editor", model);
        if (model.data) {
          // Prevent possible double discard warning
          this.warnAboutUnsavedChanges = false;
          //console.error("data editor warn about unsaved changes", this.warnAboutUnsavedChanges);
        }
        return model.data;
      }),
      catchError((err) => {
        let model: EventModel = ModalService.fromSimpleModalResultToEventModel(err);
        //console.error("data editor discard error", model);
        return of(model.data);
      }));

  }


}
