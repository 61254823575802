import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ib-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(protected router: Router) { }

  ngOnInit() {
    // For now redirect to dashboard
    this.router.navigate(["/","dashboard"]);
  }

}
