import * as m from "./ngCoreModels";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper } from "projects/core-lib/src/lib/helpers/helper";
import { SelectItem } from "primeng/api";
import { IconHelper } from "projects/common-lib/src/lib/image/icon/icon-helper";
import { Dictionary } from "./dictionary";


export interface OptInFeatures {
  ObjectExportImportSupport?: boolean;
  ShowComputedPermissions?: boolean;
}


export interface IPickListValueProperties {
  value: string;
  displayText: string;
  icon: string;
  iconColor: string;
}



export class AssetSelectionRuleOptions {
  showMatchAllOption: boolean = false;
  property01: string = "";
  property02: string = "";
  property03: string = "";
  property04: string = "";
  property05: string = "";
  property06: string = "";
  property07: string = "";
  property08: string = "";
  property09: string = "";
  property10: string = "";
  value01UsePickList: boolean;
  value02UsePickList: boolean;
  value03UsePickList: boolean;
  value04UsePickList: boolean;
  value05UsePickList: boolean;
  value06UsePickList: boolean;
  value07UsePickList: boolean;
  value08UsePickList: boolean;
  value09UsePickList: boolean;
  value10UsePickList: boolean;
  value01UsePickListGroups: boolean;
  value02UsePickListGroups: boolean;
  value03UsePickListGroups: boolean;
  value04UsePickListGroups: boolean;
  value05UsePickListGroups: boolean;
  value06UsePickListGroups: boolean;
  value07UsePickListGroups: boolean;
  value08UsePickListGroups: boolean;
  value09UsePickListGroups: boolean;
  value10UsePickListGroups: boolean;
  value01PickList: m5core.PickListSelectionViewModel[];
  value02PickList: m5core.PickListSelectionViewModel[];
  value03PickList: m5core.PickListSelectionViewModel[];
  value04PickList: m5core.PickListSelectionViewModel[];
  value05PickList: m5core.PickListSelectionViewModel[];
  value06PickList: m5core.PickListSelectionViewModel[];
  value07PickList: m5core.PickListSelectionViewModel[];
  value08PickList: m5core.PickListSelectionViewModel[];
  value09PickList: m5core.PickListSelectionViewModel[];
  value10PickList: m5core.PickListSelectionViewModel[];
  value01PickListCascadeFrom: number;
  value02PickListCascadeFrom: number;
  value03PickListCascadeFrom: number;
  value04PickListCascadeFrom: number;
  value05PickListCascadeFrom: number;
  value06PickListCascadeFrom: number;
  value07PickListCascadeFrom: number;
  value08PickListCascadeFrom: number;
  value09PickListCascadeFrom: number;
  value10PickListCascadeFrom: number;
  value01PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value02PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value03PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value04PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value05PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value06PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value07PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value08PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value09PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value10PickListWhenCascadeFromHasEmptyValue: m5core.PickListSelectionViewModel[];
  value01PickListNoneLabel: string;
  value02PickListNoneLabel: string;
  value03PickListNoneLabel: string;
  value04PickListNoneLabel: string;
  value05PickListNoneLabel: string;
  value06PickListNoneLabel: string;
  value07PickListNoneLabel: string;
  value08PickListNoneLabel: string;
  value09PickListNoneLabel: string;
  value10PickListNoneLabel: string;
}


// We usually pull PickListSelectionViewModel[] from the documentation/data-options api but some are static to our app and defined here
export class StaticPickList {


  public static stringArrayToPickList(items: string[], includeNone: boolean = false): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    if (includeNone) {
      list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    }
    if (!items || items.length === 0) {
      return list;
    }
    let order: number = 1;
    items.forEach((item) => {
      const option = new m5core.PickListSelectionViewModel();
      option.DisplayOrder = order;
      option.Value = item;
      option.DisplayText = item;
      list.push(option);
      order++;
    });
    return list;
  }

  public static stringItemsToPickList(...items: string[]): m5core.PickListSelectionViewModel[] {
    // Exclude empty strings
    items = items.filter(x => x);
    // Map to pick list selection objects
    return StaticPickList.stringArrayToPickList(items, false);
  }


  public static dictionaryToPickList(items: { [index: string]: string }, includeNone: boolean = false): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    if (includeNone) {
      list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    }
    const keys: string[] = [];
    for (const properties in items) {
      if (items.hasOwnProperty(properties)) {
        keys.push(properties);
      }
    }
    if (!keys || keys.length === 0) {
      return list;
    }
    let order: number = 1;
    keys.forEach((key) => {
      const option = new m5core.PickListSelectionViewModel();
      option.DisplayOrder = order;
      option.Value = key;
      option.DisplayText = items[key];
      list.push(option);
      order++;
    });
    return list;
  }


  public static constantsArrayToPickList(constants: m.ConstantInformation[], includeNone: boolean = false): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    if (includeNone) {
      list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    }
    if (!constants || constants.length === 0) {
      return list;
    }
    constants.forEach((item) => {
      let option = new m5core.PickListSelectionViewModel();
      option.Value = item.Value;
      option.DisplayText = item.Description;
      list.push(option);
    });
    return list;
  }

  public static pickListToPrimeSelectItems(pickList: m5core.PickListSelectionViewModel[],
    optionsIncludeNone: boolean = true,
    optionsNoneLabel: string = "<None>",
    optionsValueIsInteger: boolean = false,
    optionsIncludeGroups: boolean = false): SelectItem[] {

    const items: SelectItem[] = [];

    if (optionsIncludeNone && !optionsValueIsInteger && pickList.filter(x => !x.Value).length === 0) {
      items.push({ label: optionsNoneLabel, value: "" });
    } else if (optionsIncludeNone && optionsValueIsInteger && pickList.filter(x => x.Value === "0").length === 0) {
      items.push({ label: optionsNoneLabel, value: "0" });
    }

    let lastGroup: string = "";
    pickList.forEach(pick => {
      if (optionsIncludeGroups) {
        if (pick.GroupText && !Helper.equals(lastGroup, pick.GroupText, true)) {
          let group: SelectItem = { label: pick.GroupText, value: pick.GroupText, disabled: true, styleClass: "primeng-select-item-group" };
          (<any>group).PickListValue = pick;
          (<any>group).GroupHeader = true;
          items.push(group);
          lastGroup = pick.GroupText;
        }
      }
      const native: SelectItem = { label: pick.DisplayText, value: pick.Value };
      if (pick.Icon) {
        native.icon = IconHelper.iconDataFromIconDescription(pick.Icon).calculatedClasses;
      }
      (<any>native).PickListValue = pick;
      items.push(native);
    });

    /*
    export interface SelectItem {
      label?: string;
      value: any;
      styleClass?: string;
      icon?: string;
      title?: string;
      disabled?: boolean;
    }
    */

    return items;

  }


  public static SingleDataOptionOfNone(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static SingleDataOptionOfCurrentValue(value: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: value, DisplayText: value, Description: value, IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static SingleDataOptionOfNoneOrCurrent(value: string, language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    list.push({ Value: value, DisplayText: value, Description: value, IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    return list;
  }

  // collection of DataDictionaryColumn objects
  public static DataModelColumns(columns: any[], sortByName: boolean = true, excludeRarelyUsedMetaColumns: boolean = true): m5core.PickListSelectionViewModel[] {

    let list: m5core.PickListSelectionViewModel[] = [];
    if (!columns || columns.length === 0) {
      return list;
    }

    columns.forEach((col: any, index: number, cols: any[]) => {

      if (excludeRarelyUsedMetaColumns &&
        (Helper.equals(col.Name, "MarkedForDeletionDateTime", true) ||
          Helper.equals(col.Name, "MarkedForDeletionByContactId", true) ||
          Helper.equals(col.Name, "CurrentRowVersion", true) ||
          Helper.equals(col.Name, "PartitionId", true))) {
        // These columns are excluded from the list of columns that can be selected
      } else {
        const picker: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
        picker.PickListId = ``;
        picker.DisplayOrder = index;
        picker.DisplayText = col.Description;
        picker.Value = col.Name;
        picker.Description = col.Notes;
        list.push(picker);
      }

    });

    if (sortByName) {
      list = Helper.arraySort(list, "DisplayText");
    }

    return list;

  }

  // DataDictionaryColumn.Options
  public static DataModelColumnOptions(options: any[]): m5core.PickListSelectionViewModel[] {

    const list: m5core.PickListSelectionViewModel[] = [];
    if (!options || options.length === 0) {
      return list;
    }

    options.forEach((option: any, index: number, opts: any[]) => {
      const picker: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
      picker.PickListId = ``;
      picker.DisplayOrder = index;
      picker.DisplayText = option.Label;
      picker.Value = option.Value;
      picker.Description = option.Description;
      picker.IsDefaultValue = option.Default;
      list.push(picker);
    });

    return list;

  }


  public static FilterBuilderComparisonOperatorString(): m5core.PickListSelectionViewModel[] {
    // Strings start with the same comparison operators as non-strings and then add a few.
    const list: m5core.PickListSelectionViewModel[] = StaticPickList.FilterBuilderComparisonOperatorNonString();
    list.push({ Value: "StartsWith", DisplayText: "Starts With", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "EndsWith", DisplayText: "Ends With", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "Contains", DisplayText: "Contains", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotStartsWith", DisplayText: "Doesn't Start With", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotEndsWith", DisplayText: "Doesn't End With", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotContains", DisplayText: "Doesn't Contain", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FilterBuilderComparisonOperatorNonString(): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "Equals", DisplayText: "Equal To", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotEquals", DisplayText: "Not Equal To", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "LessThan", DisplayText: "Less Than", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "LessThanOrEqualTo", DisplayText: "Less Than or Equal To", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "GreaterThan", DisplayText: "Greater Than", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "GreaterThanOrEqualTo", DisplayText: "Greater Than or Equal To", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "Between", DisplayText: "Between", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotBetween", DisplayText: "Not Between", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "In", DisplayText: "In", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotIn", DisplayText: "Not In", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FilterBooleanOperator(): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "And", DisplayText: "All", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "Or", DisplayText: "Any", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FilterContactIdOptions(): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "Me", DisplayText: "Me", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "NotMe", DisplayText: "Not Me", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "MyGroups", DisplayText: "My Groups", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "MeAndMyGroups", DisplayText: "Me and My Groups", IsDefaultValue: true } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    list.push({ Value: "Other", DisplayText: "Other", IsDefaultValue: false } as m5core.PickListSelectionViewModel as m5core.PickListSelectionViewModel);
    return list;
  }

  public static DayOfWeek(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "S", DisplayText: "Sunday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M", DisplayText: "Monday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "T", DisplayText: "Tuesday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "W", DisplayText: "Wednesday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "R", DisplayText: "Thursday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "F", DisplayText: "Friday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "U", DisplayText: "Saturday" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static AttributeSetAttributeSetTarget(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "Customer", DisplayText: "Customers", Description: "Customers", IsDefaultValue: true } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Directory", DisplayText: "Users", Description: "Users", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Vendor", DisplayText: "Vendors", Description: "Vendors", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Warehouse", DisplayText: "Warehouses", Description: "Warehouses", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Group", DisplayText: "Groups", Description: "Groups", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    //list.push({ DisplayText: "Agents", value: "Agent" });
    //list.push({ DisplayText: "Service Providers", value: "ServiceProvider" });
    list.push({ Value: "Cases", DisplayText: "Case", Description: "Case", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Asset", DisplayText: "Asset", Description: "Asset", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AssetSelection", DisplayText: "Asset Selection", Description: "Asset Selection", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BillingAccount", DisplayText: "Billing Account", Description: "Billing Account", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BillingEvent", DisplayText: "Billing Event", Description: "Billing Event", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PackageOccurrence", DisplayText: "Package Occurrence", Description: "Package Occurrence", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Association", DisplayText: "Association", Description: "Association", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TaxMatrix", DisplayText: "Tax Matrix", Description: "Tax Matrix", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Voucher", DisplayText: "Voucher", Description: "Voucher", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PaymentTransaction", DisplayText: "Payment Transaction", Description: "Payment Transaction", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "InventoryType", DisplayText: "Inventory Type", Description: "Inventory Type", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Inventory", DisplayText: "Inventory", Description: "Inventory", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "InventoryEvent", DisplayText: "Inventory Event", Description: "Inventory Event", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static AttributeSetAttributeStaticProperties(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "AttributeSetTarget", DisplayText: "AttributeSetTarget", Description: "AttributeSetTarget", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttributeSetTargetExternalId", DisplayText: "AttributeSetTargetExternalId", Description: "AttributeSetTargetExternalId", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttributeSetTargetDescription", DisplayText: "AttributeSetTargetDescription", Description: "AttributeSetTargetDescription", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttributeSetTargetName", DisplayText: "AttributeSetTargetName", Description: "AttributeSetTargetName", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AddedDateTime", DisplayText: "AddedDateTime", Description: "AddedDateTime", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AddedByContactName", DisplayText: "AddedByContactName", Description: "AddedByContactName", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LastEditedDateTime", DisplayText: "LastEditedDateTime", Description: "LastEditedDateTime", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LastEditedByContactName", DisplayText: "LastEditedByContactName", Description: "LastEditedByContactName", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FormControlOptionMultipleChoice(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "1", DisplayText: "1 column of radio buttons" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "2", DisplayText: "2 columns of radio buttons" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3", DisplayText: "3 columns of radio buttons" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "H", DisplayText: "Horizontal list of radio buttons" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "D", DisplayText: "Drop down list of options", Description: "", IsDefaultValue: true } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FormControlOptionText(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "1", DisplayText: "Heading 1" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "2", DisplayText: "Heading 2" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3", DisplayText: "Heading 3" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "4", DisplayText: "Heading 4" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "5", DisplayText: "Heading 5" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "P", DisplayText: "Paragraph", Description: "", IsDefaultValue: true } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FormControlOptionButton(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "P", DisplayText: "Primary Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "I", DisplayText: "Info Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "S", DisplayText: "Success Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "W", DisplayText: "Warning Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "D", DisplayText: "Danger Color" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "N", DisplayText: "Normal Color", IsDefaultValue: true } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static FormControlOptionAttachment(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "U", DisplayText: "Upload" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M", DisplayText: "Manage" } as m5core.PickListSelectionViewModel);
    return list;
  }

  // Get objects this control may point to
  public static FormControlObjectName(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Customer", DisplayText: "Customer" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Directory", DisplayText: "User" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Vendor", DisplayText: "Vendor" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Warehouse", DisplayText: "Warehouse" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Cases", DisplayText: "Case" } as m5core.PickListSelectionViewModel);
    return list;
  }

  // Get objects this control may point to
  public static AssetOwnerResourceType(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "", DisplayText: "<None>" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Me", DisplayText: "Me" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Customers", DisplayText: "Customers" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Directory Users", DisplayText: "Directory Users" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Vendors", DisplayText: "Vendors" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Warehouses", DisplayText: "Warehouses" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static NumericPriority(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "-1", DisplayText: "Low" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "0", DisplayText: "Normal" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "1", DisplayText: "High" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static DateRangeOptions(language?: string): m5core.PickListSelectionViewModel[] {

    const list: m5core.PickListSelectionViewModel[] = [];

    list.push({ Value: "THIS WEEK", DisplayText: "This Week" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "THIS MONTH", DisplayText: "This Month" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "YTD", DisplayText: "Year to Date" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "QTD", DisplayText: "Quarter to Date" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "MTD", DisplayText: "Month to Date" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "WTD", DisplayText: "Week to Date" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "TODAY", DisplayText: "Today" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "YESTERDAY", DisplayText: "Yesterday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "2 DAYS AGO", DisplayText: "2 Days Ago" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3 DAYS AGO", DisplayText: "3 Days Ago" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "LAST WEEK", DisplayText: "Last Week" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST MONTH", DisplayText: "Last Month" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST QUARTER", DisplayText: "Last Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST YEAR", DisplayText: "Last Year" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "PAST WEEK", DisplayText: "Past Week" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PAST MONTH", DisplayText: "Past Month" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PAST 3 MONTHS", DisplayText: "Past 3 Months" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PAST YEAR", DisplayText: "Past Year" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "M01", DisplayText: "January" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M02", DisplayText: "February" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M03", DisplayText: "March" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M04", DisplayText: "April" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M05", DisplayText: "May" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M06", DisplayText: "June" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M07", DisplayText: "July" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M08", DisplayText: "August" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M09", DisplayText: "September" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M10", DisplayText: "October" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M11", DisplayText: "November" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "M12", DisplayText: "December" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Q1", DisplayText: "1st Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Q2", DisplayText: "2nd Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Q3", DisplayText: "3rd Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Q4", DisplayText: "4th Quarter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "WINTER", DisplayText: "Winter" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SPRING", DisplayText: "Spring" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SUMMER", DisplayText: "Summer" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "FALL", DisplayText: "Fall" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "LAST MON", DisplayText: "Last Monday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST TUE", DisplayText: "Last Tuesday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST WED", DisplayText: "Last Wednesday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST THU", DisplayText: "Last Thursday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST FRI", DisplayText: "Last Friday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST SAT", DisplayText: "Last Saturday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST SUN", DisplayText: "Last Sunday" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST 7 DAYS", DisplayText: "Last 7 Days" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LAST 3 DAYS", DisplayText: "Last 3 Days" } as m5core.PickListSelectionViewModel);

    list.push({ Value: "CUSTOM", DisplayText: "Custom" } as m5core.PickListSelectionViewModel);

    return list;

  }

  public static QueryRawDataExportFormats(language?: string): m5core.PickListSelectionViewModel[] {
    const list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "xlsx", DisplayText: "xlsx" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "pdf", DisplayText: "pdf" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "csv", DisplayText: "csv" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "tsv", DisplayText: "tsv" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "psv", DisplayText: "psv" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "xml", DisplayText: "xml" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "json", DisplayText: "json" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static PaymentProviderName(language?: string): m5core.PickListSelectionViewModel[] {
    let list: m5core.PickListSelectionViewModel[] = [];
    // Static
    list.push({ Value: "Simulator", DisplayText: "Simulator", Description: "Payment Provider Simulator", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayPal", DisplayText: "PayPal", Description: "PayPal", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BraintreePayPal", DisplayText: "Braintree PayPal", Description: "Braintree PayPal", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BraintreeVenmo", DisplayText: "Braintree Venmo", Description: "Braintree Venmo", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Amazon", DisplayText: "Amazon - Pay with Amazon", Description: "Amazon - Pay with Amazon", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ApplePayChaseOrbitalCommonWallet", DisplayText: "Apple Pay with Chase Orbital Wallet", Description: "Apple Pay with Chase Orbital Wallet", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ApplePayChaseOrbitalCommonWalletV2", DisplayText: "Apple Pay with Chase Orbital Wallet v2", Description: "Apple Pay with Chase Orbital Wallet v2", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Adyen", DisplayText: "Adyen", Description: "Adyen", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Cielo", DisplayText: "Cielo eCommerce", Description: "Cielo eCommerce", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Nestpay", DisplayText: "Nestpay / Isbank / Payten / Asseco", Description: "Nestpay / Isbank / Payten / Asseco", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "NubillWallet", DisplayText: "Nubill Wallet", Description: "Nubill Wallet", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Custom", DisplayText: "Custom", Description: "Custom Provider", IsDefaultValue: false } as m5core.PickListSelectionViewModel);
    // Part of E-Payment Integrator
    list.push({ Value: "Authorize.Net AIM (AuthorizeNet)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "eProcessing Transparent Database Engine (Eprocessing)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Intellipay ExpertLink (Intellipay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "iTransact RediCharge HTML (ITransact)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "NetBilling DirectMode (NetBilling)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Verisign PayFlow Pro (PayFlowPro)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "USA ePay CGI Transaction Gateway (USAePay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Plug 'n Pay (PlugNPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Planet Payment iPay (PlanetPayment)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "MPCS (MPCS)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "RTWare (RTWare)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ECX (ECX)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Bank of America (Global Gateway e4) (BankOfAmerica)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Innovative Gateway (PHP) (Innovative)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Merchant Anywhere (Transaction Central Classic) (MerchantAnywhere)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SkipJack (Skipjack)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Intuit Payment Solutions (IntuitPaymentSolutions)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3 Delta Systems (3DSI) EC-Linx (3DSI)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TrustCommerce API (TrustCommerce)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PSIGate HTML (PSIGate)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayFuse XML (ClearCommerce Engine) (PayFuse)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayFlow Link (PayFlowLink)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Chase Paymentech Orbital Gateway (Orbital)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "LinkPoint (LinkPoint)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Moneris eSelect Plus Canada (Moneris)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "uSight Gateway Post-Auth (USight)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Fast Transact VeloCT (Direct Mode) (FastTransact)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "NetworkMerchants Direct-Post API (NetworkMerchants)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Ogone DirectLink (Ogone)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TransFirst Transaction Central Classic (formerly PRIGate) (PRIGate)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Merchant Partners (Transaction Engine) (MerchantPartners)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "CyberCash (CyberCash)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "First Data Global Gateway (Linkpoint) (FirstData)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "YourPay (Linkpoint) (YourPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ACH Payments AGI (ACHPayments)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payments Gateway AGI (PaymentsGateway)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Cyber Source SOAP API (CyberSource)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "eWay XML API (Australia) (Eway)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "goEmerchant XML (GoEMerchant)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TransFirst eLink (TransFirst)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Chase Merchant Services (Linkpoint) (Chase)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Thompson Merchant Services NexCommerce (iTransact mode) (NexCommerce)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "WorldPay Select Junior Invisible (WorldPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TransFirst Transaction Central Classic (TransactionCentral)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Sterling SPOT XML API (HTTPS POST) (Sterling)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayJunction Trinity Gateway (PayJunction)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SECPay (United Kingdom) API Solution (SECPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payment Express PXPost (PaymentExpress)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Elavon/NOVA/My Virtual Merchant (MyVirtualMerchant)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Sage Payment Solutions (Bankcard HTTPS Post protocol) (SagePayments)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SecurePay (Script API/COM Object Interface) (SecurePay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Moneris eSelect Plus USA (MonerisUSA)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Beanstream Process Transaction API (Beanstream)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Verifi Direct-Post API (Verifi)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "SagePay Direct (Previously Protx) (SagePay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Merchant E-Solutions Payment Gateway (Trident API) (MerchantESolutions)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayLeap Web Services API (PayLeap)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayPoint.net (Previously SECPay) API Solution (PayPoint)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Worldpay XML (Direct/Invisible) (WorldPayXML)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ProPay Merchant Services API (ProPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Intuit QuickBooks Merchant Services (QBMS) (QBMS)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Heartland POS Gateway (Heartland)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Litle Online Gateway (Litle)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BrainTree DirectPost (Server-to-Server Orange) Gateway (BrainTree)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "JetPay Gateway (JetPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "HSBC XML API (ClearCommerce Engine) (HSBC)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "BluePay 2.0 Post (BluePay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Adyen API Payments (Adyen)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Barclay ePDQ (DirectLink) (Barclay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PayTrace Payment Gateway (PayTrace)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "YKC Gateway (YKC)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "GoToBilling Gateway (GoToBilling)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "TransNational Bankcard (TransNationalBankcard)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Netbanx (Netbanx)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "MIT (MIT)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "DataCash (DataCash)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ACH Federal (ACHFederal)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Global Iris (HSBC) (GlobalIris)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "First Data Global Gateway E4 (FirstDataE4)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "First Atlantic Commerce (FirstAtlantic)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Bluefin (Bluefin)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payscape (Payscape)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Pay Direct (Link2Gov) (PayDirect)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Authorize.NET CIM (AuthorizeNetCIM)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "5th Dimension Logistics (5thDimension)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "WorldPay US Link Gateway (WorldPayLink)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "3DSI Payment WorkSuite (PaymentWorkSuite)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PSIGate XML (PSIGateXML)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "First Data PayPoint (FirstDataPayPoint)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ExPay Gateway (ExPay)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payvision Gateway (Payvision)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Converge (formerly MyVirtualMerchant) (Converge)" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Payeezy Gateway (formerly First Data E4) (Payeezy)" } as m5core.PickListSelectionViewModel);
    // Fill in missing DisplayTexts and descriptions
    list.forEach((provider, index, providers) => {
      if (!provider.DisplayText || provider.DisplayText === "") {
        provider.DisplayText = provider.Value.replace(/\([^)]*\)/g, '').trim();
      }
      if (!provider.Description || provider.Description === "") {
        provider.Description = provider.DisplayText;
      }
    });
    // Sort
    list = Helper.arraySort(list, "Label");
    return list;
  }


  public static FontAwesomeIconNames(): string[] {

    // Font Awesome Pro v5.15.3
    const standard: string[] = [
      "abacus", "acorn", "ad", "address-book", "address-card", "adjust",
      "air-conditioner", "air-freshener", "alarm-clock", "alarm-exclamation", "alarm-plus", "alarm-snooze",
      "album", "album-collection", "alicorn", "alien", "alien-monster", "align-center",
      "align-justify", "align-left", "align-right", "align-slash", "allergies", "ambulance",
      "american-sign-language-interpreting", "amp-guitar", "analytics", "anchor", "angel", "angle-double-down",
      "angle-double-left", "angle-double-right", "angle-double-up", "angle-down", "angle-left", "angle-right",
      "angle-up", "angry", "ankh", "apple-alt", "apple-crate", "archive",
      "archway", "arrow-alt-circle-down", "arrow-alt-circle-left", "arrow-alt-circle-right", "arrow-alt-circle-up", "arrow-alt-down",
      "arrow-alt-from-bottom", "arrow-alt-from-left", "arrow-alt-from-right", "arrow-alt-from-top", "arrow-alt-left", "arrow-alt-right",
      "arrow-alt-square-down", "arrow-alt-square-left", "arrow-alt-square-right", "arrow-alt-square-up", "arrow-alt-to-bottom", "arrow-alt-to-left",
      "arrow-alt-to-right", "arrow-alt-to-top", "arrow-alt-up", "arrow-circle-down", "arrow-circle-left", "arrow-circle-right",
      "arrow-circle-up", "arrow-down", "arrow-from-bottom", "arrow-from-left", "arrow-from-right", "arrow-from-top",
      "arrow-left", "arrow-right", "arrows", "arrows-alt", "arrows-alt-h", "arrows-alt-v",
      "arrows-h", "arrow-square-down", "arrow-square-left", "arrow-square-right", "arrow-square-up", "arrows-v",
      "arrow-to-bottom", "arrow-to-left", "arrow-to-right", "arrow-to-top", "arrow-up", "assistive-listening-systems",
      "asterisk", "at", "atlas", "atom", "atom-alt", "audio-description",
      "award", "axe", "axe-battle", "baby", "baby-carriage", "backpack",
      "backspace", "backward", "bacon", "bacteria", "bacterium", "badge",
      "badge-check", "badge-dollar", "badge-percent", "badger-honey", "badge-sheriff", "bags-shopping",
      "bahai", "balance-scale", "balance-scale-left", "balance-scale-right", "ballot", "ballot-check",
      "ball-pile", "ban", "band-aid", "banjo", "barcode", "barcode-alt",
      "barcode-read", "barcode-scan", "bars", "baseball", "baseball-ball", "basketball-ball",
      "basketball-hoop", "bat", "bath", "battery-bolt", "battery-empty", "battery-full",
      "battery-half", "battery-quarter", "battery-slash", "battery-three-quarters", "bed", "bed-alt",
      "bed-bunk", "bed-empty", "beer", "bell", "bell-exclamation", "bell-on",
      "bell-plus", "bells", "bell-school", "bell-school-slash", "bell-slash", "betamax",
      "bezier-curve", "bible", "bicycle", "biking", "biking-mountain", "binoculars",
      "biohazard", "birthday-cake", "blanket", "blender", "blender-phone", "blind",
      "blinds", "blinds-open", "blinds-raised", "blog", "bold", "bolt",
      "bomb", "bone", "bone-break", "bong", "book", "book-alt",
      "book-dead", "book-heart", "bookmark", "book-medical", "book-open", "book-reader",
      "books", "books-medical", "book-spells", "book-user", "boombox", "boot",
      "booth-curtain", "border-all", "border-bottom", "border-center-h", "border-center-v", "border-inner",
      "border-left", "border-none", "border-outer", "border-right", "border-style", "border-style-alt",
      "border-top", "bow-arrow", "bowling-ball", "bowling-pins", "box", "box-alt",
      "box-ballot", "box-check", "boxes", "boxes-alt", "box-fragile", "box-full",
      "box-heart", "boxing-glove", "box-open", "box-tissue", "box-up", "box-usd",
      "brackets", "brackets-curly", "braille", "brain", "bread-loaf", "bread-slice",
      "briefcase", "briefcase-medical", "bring-forward", "bring-front", "broadcast-tower", "broom",
      "browser", "brush", "bug", "building", "bullhorn", "bullseye",
      "bullseye-arrow", "bullseye-pointer", "burger-soda", "burn", "burrito", "bus",
      "bus-alt", "business-time", "bus-school", "cabinet-filing", "cactus", "calculator",
      "calculator-alt", "calendar", "calendar-alt", "calendar-check", "calendar-day", "calendar-edit",
      "calendar-exclamation", "calendar-minus", "calendar-plus", "calendar-star", "calendar-times", "calendar-week",
      "camcorder", "camera", "camera-alt", "camera-home", "camera-movie", "camera-polaroid",
      "camera-retro", "campfire", "campground", "candle-holder", "candy-cane", "candy-corn",
      "cannabis", "capsules", "car", "car-alt", "caravan", "caravan-alt",
      "car-battery", "car-building", "car-bump", "car-bus", "car-crash", "caret-circle-down",
      "caret-circle-left", "caret-circle-right", "caret-circle-up", "caret-down", "caret-left", "caret-right",
      "caret-square-down", "caret-square-left", "caret-square-right", "caret-square-up", "caret-up", "car-garage",
      "car-mechanic", "carrot", "cars", "car-side", "cart-arrow-down", "car-tilt",
      "cart-plus", "car-wash", "cash-register", "cassette-tape", "cat", "cat-space",
      "cauldron", "cctv", "certificate", "chair", "chair-office", "chalkboard",
      "chalkboard-teacher", "charging-station", "chart-area", "chart-bar", "chart-line", "chart-line-down",
      "chart-network", "chart-pie", "chart-pie-alt", "chart-scatter", "check", "check-circle",
      "check-double", "check-square", "cheese", "cheeseburger", "cheese-swiss", "chess",
      "chess-bishop", "chess-bishop-alt", "chess-board", "chess-clock", "chess-clock-alt", "chess-king",
      "chess-king-alt", "chess-knight", "chess-knight-alt", "chess-pawn", "chess-pawn-alt", "chess-queen",
      "chess-queen-alt", "chess-rook", "chess-rook-alt", "chevron-circle-down", "chevron-circle-left", "chevron-circle-right",
      "chevron-circle-up", "chevron-double-down", "chevron-double-left", "chevron-double-right", "chevron-double-up", "chevron-down",
      "chevron-left", "chevron-right", "chevron-square-down", "chevron-square-left", "chevron-square-right", "chevron-square-up",
      "chevron-up", "child", "chimney", "church", "circle", "circle-notch",
      "city", "clarinet", "claw-marks", "clinic-medical", "clipboard", "clipboard-check",
      "clipboard-list", "clipboard-list-check", "clipboard-prescription", "clipboard-user", "clock", "clone",
      "closed-captioning", "cloud", "cloud-download", "cloud-download-alt", "cloud-drizzle", "cloud-hail",
      "cloud-hail-mixed", "cloud-meatball", "cloud-moon", "cloud-moon-rain", "cloud-music", "cloud-rain",
      "cloud-rainbow", "clouds", "cloud-showers", "cloud-showers-heavy", "cloud-sleet", "clouds-moon",
      "cloud-snow", "clouds-sun", "cloud-sun", "cloud-sun-rain", "cloud-upload", "cloud-upload-alt",
      "club", "cocktail", "code", "code-branch", "code-commit", "code-merge",
      "coffee", "coffee-pot", "coffee-togo", "coffin", "coffin-cross", "cog",
      "cogs", "coin", "coins", "columns", "comet", "comment",
      "comment-alt", "comment-alt-check", "comment-alt-dollar", "comment-alt-dots", "comment-alt-edit", "comment-alt-exclamation",
      "comment-alt-lines", "comment-alt-medical", "comment-alt-minus", "comment-alt-music", "comment-alt-plus", "comment-alt-slash",
      "comment-alt-smile", "comment-alt-times", "comment-check", "comment-dollar", "comment-dots", "comment-edit",
      "comment-exclamation", "comment-lines", "comment-medical", "comment-minus", "comment-music", "comment-plus",
      "comments", "comments-alt", "comments-alt-dollar", "comments-dollar", "comment-slash", "comment-smile",
      "comment-times", "compact-disc", "compass", "compass-slash", "compress", "compress-alt",
      "compress-arrows-alt", "compress-wide", "computer-classic", "computer-speaker", "concierge-bell", "construction",
      "container-storage", "conveyor-belt", "conveyor-belt-alt", "cookie", "cookie-bite", "copy",
      "copyright", "corn", "couch", "cow", "cowbell", "cowbell-more",
      "credit-card", "credit-card-blank", "credit-card-front", "cricket", "croissant", "crop",
      "crop-alt", "cross", "crosshairs", "crow", "crown", "crutch",
      "crutches", "cube", "cubes", "curling", "cut", "dagger",
      "database", "deaf", "debug", "deer", "deer-rudolph", "democrat",
      "desktop", "desktop-alt", "dewpoint", "dharmachakra", "diagnoses", "diamond",
      "dice", "dice-d10", "dice-d12", "dice-d20", "dice-d4", "dice-d6",
      "dice-d8", "dice-five", "dice-four", "dice-one", "dice-six", "dice-three",
      "dice-two", "digging", "digital-tachograph", "diploma", "directions", "disc-drive",
      "disease", "divide", "dizzy", "dna", "dog", "dog-leashed",
      "dollar-sign", "dolly", "dolly-empty", "dolly-flatbed", "dolly-flatbed-alt", "dolly-flatbed-empty",
      "donate", "do-not-enter", "door-closed", "door-open", "dot-circle", "dove",
      "download", "drafting-compass", "dragon", "draw-circle", "draw-polygon", "draw-square",
      "dreidel", "drone", "drone-alt", "drum", "drum-steelpan", "drumstick",
      "drumstick-bite", "dryer", "dryer-alt", "duck", "dumbbell", "dumpster",
      "dumpster-fire", "dungeon", "ear", "ear-muffs", "eclipse", "eclipse-alt",
      "edit", "egg", "egg-fried", "eject", "elephant", "ellipsis-h",
      "ellipsis-h-alt", "ellipsis-v", "ellipsis-v-alt", "empty-set", "engine-warning", "envelope",
      "envelope-open", "envelope-open-dollar", "envelope-open-text", "envelope-square", "equals", "eraser",
      "ethernet", "euro-sign", "exchange", "exchange-alt", "exclamation", "exclamation-circle",
      "exclamation-square", "exclamation-triangle", "expand", "expand-alt", "expand-arrows", "expand-arrows-alt",
      "expand-wide", "external-link", "external-link-alt", "external-link-square", "external-link-square-alt", "eye",
      "eye-dropper", "eye-evil", "eye-slash", "fan", "fan-table", "farm",
      "fast-backward", "fast-forward", "faucet", "faucet-drip", "fax", "feather",
      "feather-alt", "female", "field-hockey", "fighter-jet", "file", "file-alt",
      "file-archive", "file-audio", "file-certificate", "file-chart-line", "file-chart-pie", "file-check",
      "file-code", "file-contract", "file-csv", "file-download", "file-edit", "file-excel",
      "file-exclamation", "file-export", "file-image", "file-import", "file-invoice", "file-invoice-dollar",
      "file-medical", "file-medical-alt", "file-minus", "file-music", "file-pdf", "file-plus",
      "file-powerpoint", "file-prescription", "file-search", "file-signature", "files-medical", "file-spreadsheet",
      "file-times", "file-upload", "file-user", "file-video", "file-word", "fill",
      "fill-drip", "film", "film-alt", "film-canister", "filter", "fingerprint",
      "fire", "fire-alt", "fire-extinguisher", "fireplace", "fire-smoke", "first-aid",
      "fish", "fish-cooked", "fist-raised", "flag", "flag-alt", "flag-checkered",
      "flag-usa", "flame", "flashlight", "flask", "flask-poison", "flask-potion",
      "flower", "flower-daffodil", "flower-tulip", "flushed", "flute", "flux-capacitor",
      "fog", "folder", "folder-download", "folder-minus", "folder-open", "folder-plus",
      "folders", "folder-times", "folder-tree", "folder-upload", "font", "font-awesome-logo-full",
      "font-case", "football-ball", "football-helmet", "forklift", "forward", "fragile",
      "french-fries", "frog", "frosty-head", "frown", "frown-open", "function",
      "funnel-dollar", "futbol", "galaxy", "game-board", "game-board-alt", "game-console-handheld",
      "gamepad", "gamepad-alt", "garage", "garage-car", "garage-open", "gas-pump",
      "gas-pump-slash", "gavel", "gem", "genderless", "ghost", "gift",
      "gift-card", "gifts", "gingerbread-man", "glass", "glass-champagne", "glass-cheers",
      "glass-citrus", "glasses", "glasses-alt", "glass-martini", "glass-martini-alt", "glass-whiskey",
      "glass-whiskey-rocks", "globe", "globe-africa", "globe-americas", "globe-asia", "globe-europe",
      "globe-snow", "globe-stand", "golf-ball", "golf-club", "gopuram", "graduation-cap",
      "gramophone", "greater-than", "greater-than-equal", "grimace", "grin", "grin-alt",
      "grin-beam", "grin-beam-sweat", "grin-hearts", "grin-squint", "grin-squint-tears", "grin-stars",
      "grin-tears", "grin-tongue", "grin-tongue-squint", "grin-tongue-wink", "grin-wink", "grip-horizontal",
      "grip-lines", "grip-lines-vertical", "grip-vertical", "guitar", "guitar-electric", "guitars",
      "h1", "h2", "h3", "h4", "hamburger", "hammer",
      "hammer-war", "hamsa", "hand-heart", "hand-holding", "hand-holding-box", "hand-holding-heart",
      "hand-holding-magic", "hand-holding-medical", "hand-holding-seedling", "hand-holding-usd", "hand-holding-water", "hand-lizard",
      "hand-middle-finger", "hand-paper", "hand-peace", "hand-point-down", "hand-pointer", "hand-point-left",
      "hand-point-right", "hand-point-up", "hand-receiving", "hand-rock", "hands", "hand-scissors",
      "handshake", "handshake-alt", "handshake-alt-slash", "handshake-slash", "hands-heart", "hands-helping",
      "hand-sparkles", "hand-spock", "hands-usd", "hands-wash", "hanukiah", "hard-hat",
      "hashtag", "hat-chef", "hat-cowboy", "hat-cowboy-side", "hat-santa", "hat-winter",
      "hat-witch", "hat-wizard", "hdd", "heading", "headphones", "headphones-alt",
      "headset", "head-side", "head-side-brain", "head-side-cough", "head-side-cough-slash", "head-side-headphones",
      "head-side-mask", "head-side-medical", "head-side-virus", "head-vr", "heart", "heartbeat",
      "heart-broken", "heart-circle", "heart-rate", "heart-square", "heat", "helicopter",
      "helmet-battle", "hexagon", "highlighter", "hiking", "hippo", "history",
      "hockey-mask", "hockey-puck", "hockey-sticks", "holly-berry", "home", "home-alt",
      "home-heart", "home-lg", "home-lg-alt", "hood-cloak", "horizontal-rule", "horse",
      "horse-head", "horse-saddle", "hospital", "hospital-alt", "hospitals", "hospital-symbol",
      "hospital-user", "hotdog", "hotel", "hot-tub", "hourglass", "hourglass-end",
      "hourglass-half", "hourglass-start", "house", "house-damage", "house-day", "house-flood",
      "house-leave", "house-night", "house-return", "house-signal", "house-user", "hryvnia",
      "h-square", "humidity", "hurricane", "ice-cream", "ice-skate", "icicles",
      "icons", "icons-alt", "i-cursor", "id-badge", "id-card", "id-card-alt",
      "igloo", "image", "image-polaroid", "images", "inbox", "inbox-in",
      "inbox-out", "indent", "industry", "industry-alt", "infinity", "info",
      "info-circle", "info-square", "inhaler", "integral", "intersection", "inventory",
      "island-tropical", "italic", "jack-o-lantern", "jedi", "joint", "journal-whills",
      "joystick", "jug", "kaaba", "kazoo", "kerning", "key",
      "keyboard", "keynote", "key-skeleton", "khanda", "kidneys", "kiss",
      "kiss-beam", "kiss-wink-heart", "kite", "kiwi-bird", "knife-kitchen", "lambda",
      "lamp", "lamp-desk", "lamp-floor", "landmark", "landmark-alt", "language",
      "laptop", "laptop-code", "laptop-house", "laptop-medical", "lasso", "laugh",
      "laugh-beam", "laugh-squint", "laugh-wink", "layer-group", "layer-minus", "layer-plus",
      "leaf", "leaf-heart", "leaf-maple", "leaf-oak", "lemon", "less-than",
      "less-than-equal", "level-down", "level-down-alt", "level-up", "level-up-alt", "life-ring",
      "lightbulb", "lightbulb-dollar", "lightbulb-exclamation", "lightbulb-on", "lightbulb-slash", "light-ceiling",
      "lights-holiday", "light-switch", "light-switch-off", "light-switch-on", "line-columns", "line-height",
      "link", "lips", "lira-sign", "list", "list-alt", "list-music",
      "list-ol", "list-ul", "location", "location-arrow", "location-circle", "location-slash",
      "lock", "lock-alt", "lock-open", "lock-open-alt", "long-arrow-alt-down", "long-arrow-alt-left",
      "long-arrow-alt-right", "long-arrow-alt-up", "long-arrow-down", "long-arrow-left", "long-arrow-right", "long-arrow-up",
      "loveseat", "low-vision", "luchador", "luggage-cart", "lungs", "lungs-virus",
      "mace", "magic", "magnet", "mailbox", "mail-bulk", "male",
      "mandolin", "map", "map-marked", "map-marked-alt", "map-marker", "map-marker-alt",
      "map-marker-alt-slash", "map-marker-check", "map-marker-edit", "map-marker-exclamation", "map-marker-minus", "map-marker-plus",
      "map-marker-question", "map-marker-slash", "map-marker-smile", "map-marker-times", "map-pin", "map-signs",
      "marker", "mars", "mars-double", "mars-stroke", "mars-stroke-h", "mars-stroke-v",
      "mask", "meat", "medal", "medkit", "megaphone", "meh",
      "meh-blank", "meh-rolling-eyes", "memory", "menorah", "mercury", "meteor",
      "microchip", "microphone", "microphone-alt", "microphone-alt-slash", "microphone-slash", "microphone-stand",
      "microscope", "microwave", "mind-share", "minus", "minus-circle", "minus-hexagon",
      "minus-octagon", "minus-square", "mistletoe", "mitten", "mobile", "mobile-alt",
      "mobile-android", "mobile-android-alt", "money-bill", "money-bill-alt", "money-bill-wave", "money-bill-wave-alt",
      "money-check", "money-check-alt", "money-check-edit", "money-check-edit-alt", "monitor-heart-rate", "monkey",
      "monument", "moon", "moon-cloud", "moon-stars", "mortar-pestle", "mosque",
      "motorcycle", "mountain", "mountains", "mouse", "mouse-alt", "mouse-pointer",
      "mp3-player", "mug", "mug-hot", "mug-marshmallows", "mug-tea", "music",
      "music-alt", "music-alt-slash", "music-slash", "narwhal", "network-wired", "neuter",
      "newspaper", "not-equal", "notes-medical", "object-group", "object-ungroup", "octagon",
      "oil-can", "oil-temp", "om", "omega", "ornament", "otter",
      "outdent", "outlet", "oven", "overline", "page-break", "pager",
      "paint-brush", "paint-brush-alt", "paint-roller", "palette", "pallet", "pallet-alt",
      "paperclip", "paper-plane", "parachute-box", "paragraph", "paragraph-rtl", "parking",
      "parking-circle", "parking-circle-slash", "parking-slash", "passport", "pastafarianism", "paste",
      "pause", "pause-circle", "paw", "paw-alt", "paw-claws", "peace",
      "pegasus", "pen", "pen-alt", "pencil", "pencil-alt", "pencil-paintbrush",
      "pencil-ruler", "pen-fancy", "pennant", "pen-nib", "pen-square", "people-arrows",
      "people-carry", "pepper-hot", "percent", "percentage", "person-booth", "person-carry",
      "person-dolly", "person-dolly-empty", "person-sign", "phone", "phone-alt", "phone-laptop",
      "phone-office", "phone-plus", "phone-rotary", "phone-slash", "phone-square", "phone-square-alt",
      "phone-volume", "photo-video", "pi", "piano", "piano-keyboard", "pie",
      "pig", "piggy-bank", "pills", "pizza", "pizza-slice", "place-of-worship",
      "plane", "plane-alt", "plane-arrival", "plane-departure", "plane-slash", "planet-moon",
      "planet-ringed", "play", "play-circle", "plug", "plus", "plus-circle",
      "plus-hexagon", "plus-octagon", "plus-square", "podcast", "podium", "podium-star",
      "police-box", "poll", "poll-h", "poll-people", "poo", "poop",
      "poo-storm", "popcorn", "portal-enter", "portal-exit", "portrait", "pound-sign",
      "power-off", "pray", "praying-hands", "prescription", "prescription-bottle", "prescription-bottle-alt",
      "presentation", "print", "print-search", "print-slash", "procedures", "project-diagram",
      "projector", "pumpkin", "pump-medical", "pump-soap", "puzzle-piece", "qrcode",
      "question", "question-circle", "question-square", "quidditch", "quote-left", "quote-right",
      "quran", "rabbit", "rabbit-fast", "racquet", "radar", "radiation",
      "radiation-alt", "radio", "radio-alt", "rainbow", "raindrops", "ram",
      "ramp-loading", "random", "raygun", "receipt", "record-vinyl", "rectangle-landscape",
      "rectangle-portrait", "rectangle-wide", "recycle", "redo", "redo-alt", "refrigerator",
      "registered", "remove-format", "repeat", "repeat-1", "repeat-1-alt", "repeat-alt",
      "reply", "reply-all", "republican", "restroom", "retweet", "retweet-alt",
      "ribbon", "ring", "rings-wedding", "road", "robot", "rocket",
      "rocket-launch", "route", "route-highway", "route-interstate", "router", "rss",
      "rss-square", "ruble-sign", "ruler", "ruler-combined", "ruler-horizontal", "ruler-triangle",
      "ruler-vertical", "running", "rupee-sign", "rv", "sack", "sack-dollar",
      "sad-cry", "sad-tear", "salad", "sandwich", "satellite", "satellite-dish",
      "sausage", "save", "sax-hot", "saxophone", "scalpel", "scalpel-path",
      "scanner", "scanner-image", "scanner-keyboard", "scanner-touchscreen", "scarecrow", "scarf",
      "school", "screwdriver", "scroll", "scroll-old", "scrubber", "scythe",
      "sd-card", "search", "search-dollar", "search-location", "search-minus", "search-plus",
      "seedling", "send-back", "send-backward", "sensor", "sensor-alert", "sensor-fire",
      "sensor-on", "sensor-smoke", "server", "shapes", "share", "share-all",
      "share-alt", "share-alt-square", "share-square", "sheep", "shekel-sign", "shield",
      "shield-alt", "shield-check", "shield-cross", "shield-virus", "ship", "shipping-fast",
      "shipping-timed", "shish-kebab", "shoe-prints", "shopping-bag", "shopping-basket", "shopping-cart",
      "shovel", "shovel-snow", "shower", "shredder", "shuttlecock", "shuttle-van",
      "sickle", "sigma", "sign", "signal", "signal-1", "signal-2",
      "signal-3", "signal-4", "signal-alt", "signal-alt-1", "signal-alt-2", "signal-alt-3",
      "signal-alt-slash", "signal-slash", "signal-stream", "signature", "sign-in", "sign-in-alt",
      "sign-language", "sign-out", "sign-out-alt", "sim-card", "sink", "siren",
      "siren-on", "sitemap", "skating", "skeleton", "skiing", "skiing-nordic",
      "ski-jump", "ski-lift", "skull", "skull-cow", "skull-crossbones", "slash",
      "sledding", "sleigh", "sliders-h", "sliders-h-square", "sliders-v", "sliders-v-square",
      "smile", "smile-beam", "smile-plus", "smile-wink", "smog", "smoke",
      "smoking", "smoking-ban", "sms", "snake", "snooze", "snow-blowing",
      "snowboarding", "snowflake", "snowflakes", "snowman", "snowmobile", "snowplow",
      "soap", "socks", "solar-panel", "solar-system", "sort", "sort-alpha-down",
      "sort-alpha-down-alt", "sort-alpha-up", "sort-alpha-up-alt", "sort-alt", "sort-amount-down", "sort-amount-down-alt",
      "sort-amount-up", "sort-amount-up-alt", "sort-circle", "sort-circle-down", "sort-circle-up", "sort-down",
      "sort-numeric-down", "sort-numeric-down-alt", "sort-numeric-up", "sort-numeric-up-alt", "sort-shapes-down", "sort-shapes-down-alt",
      "sort-shapes-up", "sort-shapes-up-alt", "sort-size-down", "sort-size-down-alt", "sort-size-up", "sort-size-up-alt",
      "sort-up", "soup", "spa", "space-shuttle", "space-station-moon", "space-station-moon-alt",
      "spade", "sparkles", "speaker", "speakers", "spell-check", "spider",
      "spider-black-widow", "spider-web", "spinner", "spinner-third", "splotch", "spray-can",
      "sprinkler", "square", "square-full", "square-root", "square-root-alt", "squirrel",
      "staff", "stamp", "star", "star-and-crescent", "star-christmas", "star-exclamation",
      "starfighter", "starfighter-alt", "star-half", "star-half-alt", "star-of-david", "star-of-life",
      "stars", "starship", "starship-freighter", "star-shooting", "steak", "steering-wheel",
      "step-backward", "step-forward", "stethoscope", "sticky-note", "stocking", "stomach",
      "stop", "stop-circle", "stopwatch", "stopwatch-20", "store", "store-alt",
      "store-alt-slash", "store-slash", "stream", "street-view", "stretcher", "strikethrough",
      "stroopwafel", "subscript", "subway", "suitcase", "suitcase-rolling", "sun",
      "sun-cloud", "sun-dust", "sunglasses", "sun-haze", "sunrise", "sunset",
      "superscript", "surprise", "swatchbook", "swimmer", "swimming-pool", "sword",
      "sword-laser", "sword-laser-alt", "swords", "swords-laser", "synagogue", "sync",
      "sync-alt", "syringe", "table", "tablet", "tablet-alt", "tablet-android",
      "tablet-android-alt", "table-tennis", "tablet-rugged", "tablets", "tachometer", "tachometer-alt",
      "tachometer-alt-average", "tachometer-alt-fast", "tachometer-alt-fastest", "tachometer-alt-slow", "tachometer-alt-slowest", "tachometer-average",
      "tachometer-fast", "tachometer-fastest", "tachometer-slow", "tachometer-slowest", "taco", "tag",
      "tags", "tally", "tanakh", "tape", "tasks", "tasks-alt",
      "taxi", "teeth", "teeth-open", "telescope", "temperature-down", "temperature-frigid",
      "temperature-high", "temperature-hot", "temperature-low", "temperature-up", "tenge", "tennis-ball",
      "terminal", "text", "text-height", "text-size", "text-width", "th",
      "theater-masks", "thermometer", "thermometer-empty", "thermometer-full", "thermometer-half", "thermometer-quarter",
      "thermometer-three-quarters", "theta", "th-large", "th-list", "thumbs-down", "thumbs-up",
      "thumbtack", "thunderstorm", "thunderstorm-moon", "thunderstorm-sun", "ticket", "ticket-alt",
      "tilde", "times", "times-circle", "times-hexagon", "times-octagon", "times-square",
      "tint", "tint-slash", "tire", "tired", "tire-flat", "tire-pressure-warning",
      "tire-rugged", "toggle-off", "toggle-on", "toilet", "toilet-paper", "toilet-paper-alt",
      "toilet-paper-slash", "tombstone", "tombstone-alt", "toolbox", "tools", "tooth",
      "toothbrush", "torah", "torii-gate", "tornado", "tractor", "trademark",
      "traffic-cone", "traffic-light", "traffic-light-go", "traffic-light-slow", "traffic-light-stop", "trailer",
      "train", "tram", "transgender", "transgender-alt", "transporter", "transporter-1",
      "transporter-2", "transporter-3", "transporter-empty", "trash", "trash-alt", "trash-restore",
      "trash-restore-alt", "trash-undo", "trash-undo-alt", "treasure-chest", "tree", "tree-alt",
      "tree-christmas", "tree-decorated", "tree-large", "tree-palm", "trees", "triangle",
      "triangle-music", "trophy", "trophy-alt", "truck", "truck-container", "truck-couch",
      "truck-loading", "truck-monster", "truck-moving", "truck-pickup", "truck-plow", "truck-ramp",
      "trumpet", "tshirt", "tty", "turkey", "turntable", "turtle",
      "tv", "tv-alt", "tv-music", "tv-retro", "typewriter", "ufo",
      "ufo-beam", "umbrella", "umbrella-beach", "underline", "undo", "undo-alt",
      "unicorn", "union", "universal-access", "university", "unlink", "unlock",
      "unlock-alt", "upload", "usb-drive", "usd-circle", "usd-square", "user",
      "user-alien", "user-alt", "user-alt-slash", "user-astronaut", "user-chart", "user-check",
      "user-circle", "user-clock", "user-cog", "user-cowboy", "user-crown", "user-edit",
      "user-friends", "user-graduate", "user-hard-hat", "user-headset", "user-injured", "user-lock",
      "user-md", "user-md-chat", "user-minus", "user-music", "user-ninja", "user-nurse",
      "user-plus", "user-robot", "users", "users-class", "users-cog", "users-crown",
      "user-secret", "user-shield", "user-slash", "users-medical", "users-slash", "user-tag",
      "user-tie", "user-times", "user-unlock", "user-visor", "utensil-fork", "utensil-knife",
      "utensils", "utensils-alt", "utensil-spoon", "vacuum", "vacuum-robot", "value-absolute",
      "vector-square", "venus", "venus-double", "venus-mars", "vest", "vest-patches",
      "vhs", "vial", "vials", "video", "video-plus", "video-slash",
      "vihara", "violin", "virus", "viruses", "virus-slash", "voicemail",
      "volcano", "volleyball-ball", "volume", "volume-down", "volume-mute", "volume-off",
      "volume-slash", "volume-up", "vote-nay", "vote-yea", "vr-cardboard", "wagon-covered",
      "walker", "walkie-talkie", "walking", "wallet", "wand", "wand-magic",
      "warehouse", "warehouse-alt", "washer", "watch", "watch-calculator", "watch-fitness",
      "water", "water-lower", "water-rise", "waveform", "waveform-path", "wave-sine",
      "wave-square", "wave-triangle", "webcam", "webcam-slash", "weight", "weight-hanging",
      "whale", "wheat", "wheelchair", "whistle", "wifi", "wifi-1",
      "wifi-2", "wifi-slash", "wind", "window", "window-alt", "window-close",
      "window-frame", "window-frame-open", "window-maximize", "window-minimize", "window-restore", "windsock",
      "wind-turbine", "wind-warning", "wine-bottle", "wine-glass", "wine-glass-alt", "won-sign",
      "wreath", "wrench", "x-ray", "yen-sign", "yin-yang",];

    const brands: string[] = [
      "500px (brand)", "accessible-icon (brand)", "accusoft (brand)", "acquisitions-incorporated (brand)", "adn (brand)", "adversal (brand)",
      "affiliatetheme (brand)", "airbnb (brand)", "algolia (brand)", "alipay (brand)", "amazon (brand)", "amazon-pay (brand)",
      "amilia (brand)", "android (brand)", "angellist (brand)", "angrycreative (brand)", "angular (brand)", "apper (brand)",
      "apple (brand)", "apple-pay (brand)", "app-store (brand)", "app-store-ios (brand)", "artstation (brand)", "asymmetrik (brand)",
      "atlassian (brand)", "audible (brand)", "autoprefixer (brand)", "avianex (brand)", "aviato (brand)", "aws (brand)",
      "bandcamp (brand)", "battle-net (brand)", "behance (brand)", "behance-square (brand)", "bimobject (brand)", "bitbucket (brand)",
      "bitcoin (brand)", "bity (brand)", "blackberry (brand)", "black-tie (brand)", "blogger (brand)", "blogger-b (brand)",
      "bluetooth (brand)", "bluetooth-b (brand)", "bootstrap (brand)", "btc (brand)", "buffer (brand)", "buromobelexperte (brand)",
      "buy-n-large (brand)", "buysellads (brand)", "canadian-maple-leaf (brand)", "cc-amazon-pay (brand)", "cc-amex (brand)", "cc-apple-pay (brand)",
      "cc-diners-club (brand)", "cc-discover (brand)", "cc-jcb (brand)", "cc-mastercard (brand)", "cc-paypal (brand)", "cc-stripe (brand)",
      "cc-visa (brand)", "centercode (brand)", "centos (brand)", "chrome (brand)", "chromecast (brand)", "cloudflare (brand)",
      "cloudscale (brand)", "cloudsmith (brand)", "cloudversify (brand)", "codepen (brand)", "codiepie (brand)", "confluence (brand)",
      "connectdevelop (brand)", "contao (brand)", "cotton-bureau (brand)", "cpanel (brand)", "creative-commons (brand)", "creative-commons-by (brand)",
      "creative-commons-nc (brand)", "creative-commons-nc-eu (brand)", "creative-commons-nc-jp (brand)", "creative-commons-nd (brand)", "creative-commons-pd (brand)", "creative-commons-pd-alt (brand)",
      "creative-commons-remix (brand)", "creative-commons-sa (brand)", "creative-commons-sampling (brand)", "creative-commons-sampling-plus (brand)", "creative-commons-share (brand)", "creative-commons-zero (brand)",
      "critical-role (brand)", "css3 (brand)", "css3-alt (brand)", "cuttlefish (brand)", "dailymotion (brand)", "d-and-d (brand)",
      "d-and-d-beyond (brand)", "dashcube (brand)", "deezer (brand)", "delicious (brand)", "deploydog (brand)", "deskpro (brand)",
      "dev (brand)", "deviantart (brand)", "dhl (brand)", "diaspora (brand)", "digg (brand)", "digital-ocean (brand)",
      "discord (brand)", "discourse (brand)", "dochub (brand)", "docker (brand)", "draft2digital (brand)", "dribbble (brand)",
      "dribbble-square (brand)", "dropbox (brand)", "drupal (brand)", "dyalog (brand)", "earlybirds (brand)", "ebay (brand)",
      "edge (brand)", "edge-legacy (brand)", "elementor (brand)", "ello (brand)", "ember (brand)", "empire (brand)",
      "envira (brand)", "erlang (brand)", "ethereum (brand)", "etsy (brand)", "evernote (brand)", "expeditedssl (brand)",
      "facebook (brand)", "facebook-f (brand)", "facebook-messenger (brand)", "facebook-square (brand)", "fantasy-flight-games (brand)", "fedex (brand)",
      "fedora (brand)", "figma (brand)", "firefox (brand)", "firefox-browser (brand)", "firstdraft (brand)", "first-order (brand)",
      "first-order-alt (brand)", "flickr (brand)", "flipboard (brand)", "fly (brand)", "font-awesome (brand)", "font-awesome-alt (brand)",
      "font-awesome-flag (brand)", "fonticons (brand)", "fonticons-fi (brand)", "fort-awesome (brand)", "fort-awesome-alt (brand)", "forumbee (brand)",
      "foursquare (brand)", "freebsd (brand)", "free-code-camp (brand)", "fulcrum (brand)", "galactic-republic (brand)", "galactic-senate (brand)",
      "get-pocket (brand)", "gg (brand)", "gg-circle (brand)", "git (brand)", "git-alt (brand)", "github (brand)",
      "github-alt (brand)", "github-square (brand)", "gitkraken (brand)", "gitlab (brand)", "git-square (brand)", "gitter (brand)",
      "glide (brand)", "glide-g (brand)", "gofore (brand)", "goodreads (brand)", "goodreads-g (brand)", "google (brand)",
      "google-drive (brand)", "google-pay (brand)", "google-play (brand)", "google-plus (brand)", "google-plus-g (brand)", "google-plus-square (brand)",
      "google-wallet (brand)", "gratipay (brand)", "grav (brand)", "gripfire (brand)", "grunt (brand)", "guilded (brand)",
      "gulp (brand)", "hacker-news (brand)", "hacker-news-square (brand)", "hackerrank (brand)", "hips (brand)", "hire-a-helper (brand)",
      "hive (brand)", "hooli (brand)", "hornbill (brand)", "hotjar (brand)", "houzz (brand)", "html5 (brand)",
      "hubspot (brand)", "ideal (brand)", "imdb (brand)", "innosoft (brand)", "instagram (brand)", "instagram-square (brand)",
      "instalod (brand)", "intercom (brand)", "internet-explorer (brand)", "invision (brand)", "ioxhost (brand)", "itch-io (brand)",
      "itunes (brand)", "itunes-note (brand)", "java (brand)", "jedi-order (brand)", "jenkins (brand)", "jira (brand)",
      "joget (brand)", "joomla (brand)", "js (brand)", "jsfiddle (brand)", "js-square (brand)", "kaggle (brand)",
      "keybase (brand)", "keycdn (brand)", "kickstarter (brand)", "kickstarter-k (brand)", "korvue (brand)", "laravel (brand)",
      "lastfm (brand)", "lastfm-square (brand)", "leanpub (brand)", "less (brand)", "line (brand)", "linkedin (brand)",
      "linkedin-in (brand)", "linode (brand)", "linux (brand)", "lyft (brand)", "magento (brand)", "mailchimp (brand)",
      "mandalorian (brand)", "markdown (brand)", "mastodon (brand)", "maxcdn (brand)", "mdb (brand)", "medapps (brand)",
      "medium (brand)", "medium-m (brand)", "medrt (brand)", "meetup (brand)", "megaport (brand)", "mendeley (brand)",
      "microblog (brand)", "microsoft (brand)", "mix (brand)", "mixcloud (brand)", "mixer (brand)", "mizuni (brand)",
      "modx (brand)", "monero (brand)", "napster (brand)", "neos (brand)", "nimblr (brand)", "node (brand)",
      "node-js (brand)", "npm (brand)", "ns8 (brand)", "nutritionix (brand)", "octopus-deploy (brand)", "odnoklassniki (brand)",
      "odnoklassniki-square (brand)", "old-republic (brand)", "opencart (brand)", "openid (brand)", "opera (brand)", "optin-monster (brand)",
      "orcid (brand)", "osi (brand)", "page4 (brand)", "pagelines (brand)", "palfed (brand)", "patreon (brand)",
      "paypal (brand)", "penny-arcade (brand)", "perbyte (brand)", "periscope (brand)", "phabricator (brand)", "phoenix-framework (brand)",
      "phoenix-squadron (brand)", "php (brand)", "pied-piper (brand)", "pied-piper-alt (brand)", "pied-piper-hat (brand)", "pied-piper-pp (brand)",
      "pied-piper-square (brand)", "pinterest (brand)", "pinterest-p (brand)", "pinterest-square (brand)", "playstation (brand)", "product-hunt (brand)",
      "pushed (brand)", "python (brand)", "qq (brand)", "quinscape (brand)", "quora (brand)", "raspberry-pi (brand)",
      "ravelry (brand)", "react (brand)", "reacteurope (brand)", "readme (brand)", "rebel (brand)", "reddit (brand)",
      "reddit-alien (brand)", "reddit-square (brand)", "redhat (brand)", "red-river (brand)", "renren (brand)", "replyd (brand)",
      "researchgate (brand)", "resolving (brand)", "rev (brand)", "rocketchat (brand)", "rockrms (brand)", "r-project (brand)",
      "rust (brand)", "safari (brand)", "salesforce (brand)", "sass (brand)", "schlix (brand)", "scribd (brand)",
      "searchengin (brand)", "sellcast (brand)", "sellsy (brand)", "servicestack (brand)", "shirtsinbulk (brand)", "shopify (brand)",
      "shopware (brand)", "simplybuilt (brand)", "sistrix (brand)", "sith (brand)", "sketch (brand)", "skyatlas (brand)",
      "skype (brand)", "slack (brand)", "slack-hash (brand)", "slideshare (brand)", "snapchat (brand)", "snapchat-ghost (brand)",
      "snapchat-square (brand)", "soundcloud (brand)", "sourcetree (brand)", "speakap (brand)", "speaker-deck (brand)", "spotify (brand)",
      "squarespace (brand)", "stack-exchange (brand)", "stack-overflow (brand)", "stackpath (brand)", "staylinked (brand)", "steam (brand)",
      "steam-square (brand)", "steam-symbol (brand)", "sticker-mule (brand)", "strava (brand)", "stripe (brand)", "stripe-s (brand)",
      "studiovinari (brand)", "stumbleupon (brand)", "stumbleupon-circle (brand)", "superpowers (brand)", "supple (brand)", "suse (brand)",
      "swift (brand)", "symfony (brand)", "teamspeak (brand)", "telegram (brand)", "telegram-plane (brand)", "tencent-weibo (brand)",
      "themeco (brand)", "themeisle (brand)", "the-red-yeti (brand)", "think-peaks (brand)", "tiktok (brand)", "trade-federation (brand)",
      "trello (brand)", "tripadvisor (brand)", "tumblr (brand)", "tumblr-square (brand)", "twitch (brand)", "twitter (brand)",
      "twitter-square (brand)", "typo3 (brand)", "uber (brand)", "ubuntu (brand)", "uikit (brand)", "umbraco (brand)",
      "uncharted (brand)", "uniregistry (brand)", "unity (brand)", "unsplash (brand)", "untappd (brand)", "ups (brand)",
      "usb (brand)", "usps (brand)", "ussunnah (brand)", "vaadin (brand)", "viacoin (brand)", "viadeo (brand)",
      "viadeo-square (brand)", "viber (brand)", "vimeo (brand)", "vimeo-square (brand)", "vimeo-v (brand)", "vine (brand)",
      "vk (brand)", "vnv (brand)", "vuejs (brand)", "watchman-monitoring (brand)", "waze (brand)", "weebly (brand)",
      "weibo (brand)", "weixin (brand)", "whatsapp (brand)", "whatsapp-square (brand)", "whmcs (brand)", "wikipedia-w (brand)",
      "windows (brand)", "wix (brand)", "wizards-of-the-coast (brand)", "wodu (brand)", "wolf-pack-battalion (brand)", "wordpress (brand)",
      "wordpress-simple (brand)", "wpbeginner (brand)", "wpexplorer (brand)", "wpforms (brand)", "wpressr (brand)", "xbox (brand)",
      "xing (brand)", "xing-square (brand)", "yahoo (brand)", "yammer (brand)", "yandex (brand)", "yandex-international (brand)",
      "yarn (brand)", "y-combinator (brand)", "yelp (brand)", "yoast (brand)", "youtube (brand)", "youtube-square (brand)",
      "zhihu (brand)",];

    const icons: string[] = [];
    standard.forEach(icon => {
      icons.push(icon, `${icon} (solid)`, `${icon} (light)`, `${icon} (duotone)`);
    });
    icons.push(...brands);
    icons.sort();

    return icons;

  }




  public static ReportCompilerAttachmentNumberingStyle(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "1", DisplayText: "1, 2, 3, etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "A", DisplayText: "A, B, C, etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "a", DisplayText: "a, b, c, etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "I", DisplayText: "I, II, III, etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "i", DisplayText: "i, ii, iii, etc." } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerParagraphNumberingStyle(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "1.", DisplayText: "1. 2. 3. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "1)", DisplayText: "1) 2) 3) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "A.", DisplayText: "A. B. C. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "A)", DisplayText: "A) B) C) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "a.", DisplayText: "a. b. c. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "a)", DisplayText: "a) b) c) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "I.", DisplayText: "I. II. III. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "I)", DisplayText: "I) II) III) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "i.", DisplayText: "i. ii. iii. etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "i)", DisplayText: "i) ii) iii) etc." } as m5core.PickListSelectionViewModel);
    list.push({ Value: "*", DisplayText: "Solid Bullet" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "o", DisplayText: "Outline Bullet" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerAutomaticallySelectLibraryText(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "A", DisplayText: "Always" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "N", DisplayText: "Never" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "L", DisplayText: "When library entry configured for automatic selection" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "O", DisplayText: "When only one item suggested" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "E", DisplayText: "When library entry configured for automatic selection or only one item suggested" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerAttachmentSectionHeadingType(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "ExternalAssetId", DisplayText: "Document Id" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Title", DisplayText: "Document Title" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "FriendlyName", DisplayText: "Document File Name" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerAssetReportTemplateAssetScope(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    list.push({ Value: "ReportTemplate", DisplayText: "Report Template" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttachmentCoverPageTemplate", DisplayText: "Attachment Cover Page Template" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AttachmentImageLayoutTemplate", DisplayText: "Attachment Image Layout Template" } as m5core.PickListSelectionViewModel);
    return list;
  }

  public static ReportCompilerReportSectionConfigurationTextColumnPropertyName(language?: string): m5core.PickListSelectionViewModel[] {
    var list: m5core.PickListSelectionViewModel[] = [];
    for (var i = 1; i <= 10; i++) {
      list.push({ Value: `Value${Helper.pad(i, 2, "0")}`, DisplayText: `Value${Helper.pad(i, 2, "0")}` } as m5core.PickListSelectionViewModel);
    }
    list.push({ Value: "Tags", DisplayText: "Tags" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "ExternalTextId", DisplayText: "ExternalTextId" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Title", DisplayText: "Title" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Score", DisplayText: "Score" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "Text", DisplayText: "Text" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "PreviewText", DisplayText: "PreviewText" } as m5core.PickListSelectionViewModel);
    list.push({ Value: "AuthorLongName", DisplayText: "AuthorLongName" } as m5core.PickListSelectionViewModel);
    return list;
  }

}
