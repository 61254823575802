import { Injectable, OnInit, OnDestroy, ChangeDetectorRef, ApplicationRef, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { BaseService } from 'projects/core-lib/src/lib/services/base.service';
import { ModalService } from '../modal/modal.service';
import { AlertManager } from '../alert/alert-manager';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ModalCommonOptions } from '../modal/modal-common-options';
import { FormGroupFluentModel } from '../form/form-fluent-model';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { EventModel, EventModelTyped } from '../ux-models';
import { ApiOperationType, ApiProperties, IApiResponseWrapperTyped, Query } from 'projects/core-lib/src/lib/api/ApiModels';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiModuleCore } from 'projects/core-lib/src/lib/api/Api.Module.Core';
import { single } from 'rxjs/operators';
import { TableService } from 'projects/core-lib/src/lib/services/table.service';


@Injectable({
  providedIn: 'root'
})
export class UxService extends BaseService implements OnInit, OnDestroy {

  public refresh: number = 0;

  constructor(
    public modal: ModalService,
    protected apiService: ApiService,
    public appService: AppService,
    public tableService: TableService,
    protected applicationRef: ApplicationRef,
    protected zone: NgZone) {
    //protected changeDetector: ChangeDetectorRef) {
    // NullInjectorError: No provider for ChangeDetectorRef
    // https://stackoverflow.com/questions/48916206/nullinjectorerror-no-provider-for-changedetectorref
    // "Do not import ChangeDetectorRef into services"

    super();

  }

  //ngOnInit() {
  //}

  //ngOnDestroy() {
  //  super.ngOnDestroy();
  //}


  pickListValueGetNew(pickListId: string, parentPickListId: string = "", parentPickListValue: string = "", parentPickListValueId: number = null): m5core.PickListValueEditViewModel {
    const model = new m5core.PickListValueEditViewModel();
    model.Enabled = true;
    model.DisplayOrder = 100;
    model.PickListId = pickListId;
    model.ParentPickListId = parentPickListId;
    model.ParentPickListValue = parentPickListValue;
    model.ParentPickListValueId = parentPickListValueId;
    return model;
  }

  pickListValueGetDynamicForm(list: m5core.PickListEditViewModel, value: m5core.PickListValueEditViewModel, objectName: string = "Value"): m5web.FormEditViewModel {

    if (!list) {
      Log.errorMessage("No pick list object provided for building pick list value dynamic form.");
      return;
    }
    if (!value) {
      value = this.pickListValueGetNew(list.PickListId);
    }
    if (!value.PickListId) {
      value.PickListId = list.PickListId;
    }

    // Build the form
    const valueTab = new FormGroupFluentModel("tab", "Value");
    if (value.ParentPickListValue) {
      valueTab.HasInputString("Parent Value", "Parent Value", objectName, "ParentPickListValue");
      valueTab.Controls.slice(-1)[0].ReadOnly = true;
    }
    valueTab.HasInputString("Value", "Value", objectName, "Value", "W", true);
    if (list.HasDisplayText) {
      valueTab.HasInputString("Display Text", "Display Text", objectName, "DisplayText", "W");
    }
    if (list.HasGrouping) {
      valueTab.HasInputString("Group Text", "Group Text", objectName, "GroupText", "W");
    }
    //valueTab.HasInputCheckbox("Enabled", objectName, "Enabled");
    if (list.PickListSort === "D") {
      valueTab.HasInputNumber("Display Order", "Display Order", objectName, "DisplayOrder");
    }
    const dispTab = new FormGroupFluentModel("tab", "Display");
    dispTab.HasInputCheckbox("Enabled", objectName, "Enabled");
    dispTab.HasInputOther("Text Color", "Text Color", objectName, "TextColor", "InputColor");
    dispTab.HasInputOther("Icon", "Icon", objectName, "Icon", "InputIcon");
    dispTab.HasInputOther("Icon Color", "Icon Color", objectName, "IconColor", "InputColor");
    dispTab.HasInputString("Scope", "Scope", objectName, "Scope");
    const advTab = new FormGroupFluentModel("tab", "Advanced");
    advTab.HasInputCheckbox("Mutually Exclusive", objectName, "MutuallyExclusive");
    advTab.HasInputString("Parent List", "Parent List", objectName, "ParentPickListId");
    advTab.Controls.slice(-1)[0].ReadOnly = true;
    advTab.HasInputString("Parent Value", "Parent Value", objectName, "ParentPickListValue");
    advTab.Controls.slice(-1)[0].ReadOnly = true;
    advTab.HasInputLongString("Properties", "Properties", objectName, "Properties", 5);

    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    const tabset = new FormGroupFluentModel("tabset");
    tabset.Groups.push(valueTab);
    tabset.Groups.push(dispTab);
    tabset.Groups.push(advTab);
    form.Groups.push(tabset);

    return form;

  }

  /**
  * Display add pick list value modal and if modal submitted then submit to server.
   * @param pickListId
   * @param parentPickListId
   * @param parentPickListValue
   * @param parentPickListValueId
   * @param reportErrors
   * @returns
   */
  async pickListValueAdd(pickListId: string, parentPickListId: string = "", parentPickListValue: string = "", parentPickListValueId: number = null, reportErrors: boolean = true): Promise<m5core.PickListValueEditViewModel> {

    // Get the pick list object for the specified pick list id so we know how the form should look
    let list: m5core.PickListEditViewModel = new m5core.PickListEditViewModel();
    // Start with some default settings we'll use if we don't find a list object for the stated pick list id
    list.PickListId = pickListId;
    list.Description = pickListId;
    list.Enabled = true;
    list.HasDisplayText = true;
    // Get the pick list object
    const apiProp = ApiModuleCore.PickList();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);
    apiCall.silent = true;
    const query = new Query();
    query.Filter = `PickListId = "${pickListId}"`;
    const result: IApiResponseWrapperTyped<m5core.PickListEditViewModel[]> = await this.apiService.execute(apiCall, query).pipe(single()).toPromise();
    if (result.Data.Success) {
      if (result.Data.Data.length > 0) {
        list = result.Data.Data[0];
      } else {
        Log.errorMessage(`No pick list found for ${query.Filter}.  Using default pick list object for getting new pick list value.`);
      }
    } else {
      this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      Log.errorMessage(`Error getting pick list ${pickListId}.  Using default pick list object for getting new pick list value.`);
    }

    // Get the pick list value model
    const model = this.pickListValueGetNew(pickListId, parentPickListId, parentPickListValue, parentPickListValueId);

    // Get the modal options
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    options.title = "Add Pick List Value";

    // Get the form
    const form: m5web.FormEditViewModel = this.pickListValueGetDynamicForm(list, model);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Value: m5core.PickListValueEditViewModel } = { Value: model };

    try {
      // Cancel on the modal gets raised as an error (see catch block below) so no need to check the value for success or not
      const value: EventModelTyped<{ Value: m5core.PickListValueEditViewModel }> = await this.modal.showDynamicFormModal(options, form, payload);
      // Allow value and display text to alias each other when not provided
      if (!value.data.Value.DisplayText) {
        value.data.Value.DisplayText = value.data.Value.Value;
      } else if (!value.data.Value.Value) {
        value.data.Value.Value = value.data.Value.DisplayText;
      }
      // Now add the pick list
      const apiProp: ApiProperties = ApiModuleCore.PickListValue();
      const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
      const result: IApiResponseWrapperTyped<m5core.PickListValueEditViewModel> = await this.apiService.execute(apiCall, value.data.Value).pipe(single()).toPromise();
      if (result.Data.Success) {
        this.apiService.dumpPickListCache(value.data.Value.PickListId);
        return result.Data.Data;
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        } else {
          Log.errorMessage(result);
        }
        return null;
      }
    } catch (err) {
      // When error object is EventModel then user hit cancel on modal so not an error
      if (!(err instanceof EventModel)) {
        Log.errorMessage(err);
        return null;
      }
    }

    return null;

  }




  detectChanges(tick: boolean = false) {
    if (tick) {
      this.applicationRef.tick();
    } else {
      this.zone.run(() => this.refresh++);
    }
  }

}
