<div class="form-group {{inputFormGroupClass}} {{outerClass}}">
  <label *ngIf="( !vertical || required || label || labelPrefix || labelSuffix )"
         for="{{inputControlId}}"
         class="{{inputLabelClass}}"
         ngbTooltip="{{( tooltip | translate ) || tooltip}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)"
               ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    <ng-container *ngIf="labelButton">
      <ib-action-button [button]="labelButton"
                        [data]="labelButtonData"
                        [cargo]="labelButtonCargo"></ib-action-button>
    </ng-container>
    <ng-container *ngIf="!labelButton">
      {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
    </ng-container>
  </label>
  <div class="{{inputWrapperClass}}">
    <div *ngIf="prefixShow && ( prefixText || prefixIcon )"
         class="input-group-prepend"
         [ngClass]="{'clickable': isPrefixClickable()}">
      <span class="input-group-text {{prefixClass}}"
            ngbTooltip="{{(prefixTooltip | translate) || prefixTooltip}}"
            (click)="firePrefixClick($event)">
        {{( prefixText | translate ) || prefixText}}
        <ib-icon icon="{{prefixIcon}}"></ib-icon>
      </span>
    </div>
    <input type="{{inputType}}"
           class="form-control {{inputSize}} {{controlClass}}"
           id="{{inputControlId}}"
           name="{{name}}"
           placeholder="{{( placeholder | translate ) || placeholder}}"
           ngbTooltip="{{(tooltip | translate) || tooltip}}"
           [ngbTypeahead]="typeaheadSearch"
           pattern="{{pattern}}"
           [maxlength]="maxlength"
           [minlength]="minlength"
           [autofocus]="autofocus ? 'autofocus' : null"
           [disabled]="disabled ? 'disabled' : null"
           [readonly]="readonly ? 'readonly' : null"
           [required]="required ? 'required' : null"
           (focus)="fireFocus($event, inputControl)"
           (blur)="fireBlur($event, inputControl)"
           (keyup)="fireKeyUp($event, inputControl)"
           (change)="fireChange($event, inputControl)"
           [(ngModel)]="value"
           [ngModelOptions]="{standalone: standalone}"
           #inputControl="ngModel" />
    <div *ngIf="suffixShow && ( suffixText || suffixIcon )"
         class="input-group-append"
         [ngClass]="{'clickable': isSuffixClickable()}">
      <span class="input-group-text {{suffixClass}}"
            ngbTooltip="{{( suffixTooltip | translate ) || suffixTooltip}}"
            (click)="fireSuffixClick($event)">
        {{(suffixText | translate ) || suffixText}}
        <ib-icon icon="{{suffixIcon}}"></ib-icon>
      </span>
    </div>
    <div *ngIf="suffixShow2 && ( suffixText2 || suffixIcon2 )"
         class="input-group-append"
         [ngClass]="{'clickable': isSuffixClickable2()}">
      <span class="input-group-text  {{suffixClass2}}"
            ngbTooltip="{{( suffixTooltip2 | translate ) || suffixTooltip2}}"
            (click)="fireSuffixClick2($event)">
        {{( suffixText2 | translate ) || suffixText2}}
        <ib-icon icon="{{suffixIcon2}}"></ib-icon>
      </span>
    </div>
  </div>
  <div class="{{inputErrorWrapperClass}}"
       *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
    <small *ngFor="let errorMessage of errorMessages"
           class="form-text input-invalid input-error-message">
      {{errorMessage | translate:inputInformationValues}}
    </small>
  </div>
</div>
