<div class="form-group {{inputFormGroupClass}}">

  <label for="{{inputControlId}}" class="{{inputLabelClass}}" ngbTooltip="{{tooltip | translate}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)" ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    {{labelPrefix}}{{label | translate}}{{labelSuffix}}
    <span *ngIf="selectionCountBadgeColor" class="ml-1 badge badge-pill badge-{{selectionCountBadgeColor}}">
      <ng-container *ngIf="value">{{value.length || 0}}</ng-container>
      <ng-container *ngIf="!value">0</ng-container>
    </span>
  </label>

  <div class="{{inputWrapperClass}} {{layoutClass}} {{controlClass}}" *ngIf="modelIsJsonString || modelIsCsvString">
    <!--<div *ngIf="prefixText || prefixIcon" class="ui-inputgroup-addon" [ngClass]="{'clickable': isPrefixClickable()}">
      <span class="input-group-text" ngbTooltip="{{prefixTooltip | translate}}" (click)="firePrefixClick($event)">
        {{prefixText | translate}}
        <ib-icon icon="{{prefixIcon}}"></ib-icon>
      </span>
    </div>-->
    <ib-multiSelect id="{{inputControlId}}"
                    name="{{name}}"
                    ngbTooltip="{{tooltip | translate}}"
                    [disabled]="( disabled || readonly )"
                    [required]="required ? 'required' : null"
                    (focus)="fireFocus($event, inputControl)"
                    (blur)="fireBlur($event, inputControl)"
                    (onPanelHide)="fireBlur($event, inputControl)"
                    (keyup)="fireKeyUp($event, inputControl)"
                    (onChange)="fireChange($event, inputControl)"
                    [defaultLabel]="placeholder"
                    [options]="selectItems"
                    [style]="{width:'100%'}"
                    [panelStyle]="{'margin-left': '8px'}"
                    [styleClass]="multiSelectClasses"
                    dropdownIcon="pi pi-caret-down"
                    [baseZIndex]="2000"
                    scrollHeight="250px"
                    [maxSelectedLabels]="maxSelectedLabels"
                    [selectedItemsWordwrap]="selectedItemsWordwrap"
                    [selectionLimit]="selectionLimit"
                    [filter]="true"
                    filterPlaceHolder="Search Items"
                    [showHeader]="true"
                    appendTo="body"
                    [(ngModel)]="list"
                    [ngModelOptions]="{standalone: standalone}"
                    #inputControl="ngModel">
      <ng-template let-item let-i="index" pTemplate="item">
        <div *ngIf="item.GroupHeader" style="color: black; font-weight: bolder; font-size: larger; opacity:1 !important; display:inline;">{{item.label}}</div>
        <div *ngIf="!item.GroupHeader" style="display:inline;">{{item.label}}</div>
      </ng-template>
      <p-header *ngIf="headerIcon || headerText || headerActionButton">
        <div class="clearfix mb-2">
          <div class="float-left" *ngIf="headerIcon || headerText">
            <ib-icon [icon]="headerIcon" [label]="headerText"></ib-icon>
          </div>
          <div class="float-right" *ngIf="headerActionButton">
            <ib-action-button [button]="headerActionButton" [data]="value" [control]="inputControl"></ib-action-button>
          </div>
        </div>
      </p-header>
      <p-footer *ngIf="footerActionButton">
        <div class="clearfix">
          <div class="float-left">
            &nbsp;
          </div>
          <div class="float-right" *ngIf="footerActionButton">
            <ib-action-button [button]="footerActionButton" [data]="value"></ib-action-button>
          </div>
        </div>
      </p-footer>
    </ib-multiSelect>
    <!--<span *ngIf="suffixText || suffixIcon"
          class="ui-inputgroup-addon"
          [ngClass]="{'clickable': isSuffixClickable()}"
          ngbTooltip="{{suffixTooltip | translate}}"
          (click)="fireSuffixClick($event)">
      {{suffixText | translate}}
      <ib-icon icon="{{suffixIcon}}"></ib-icon>
    </span>-->
    <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
      <small *ngFor="let errorMessage of errorMessages" class="form-text input-invalid input-error-message">
        {{errorMessage | translate:inputInformationValues}}
      </small>
    </div>
  </div>

  <div class="{{inputWrapperClass}} {{layoutClass}} {{controlClass}}" *ngIf="!modelIsJsonString && !modelIsCsvString">
    <div *ngIf="prefixText || prefixIcon" class="ui-inputgroup-addon" [ngClass]="{'clickable': isPrefixClickable()}">
      <span class="input-group-text" ngbTooltip="{{prefixTooltip | translate}}" (click)="firePrefixClick($event)">
        {{prefixText | translate}}
        <ib-icon icon="{{prefixIcon}}"></ib-icon>
      </span>
    </div>
    <ib-multiSelect id="{{inputControlId}}"
                    name="{{name}}"
                    ngbTooltip="{{tooltip | translate}}"
                    [disabled]="( disabled || readonly )"
                    [required]="required ? 'required' : null"
                    (focus)="fireFocus($event, inputControl)"
                    (blur)="fireBlur($event, inputControl)"
                    (onPanelHide)="fireBlur($event, inputControl)"
                    (keyup)="fireKeyUp($event, inputControl)"
                    (onChange)="fireChange($event, inputControl)"
                    [actionIcon]="(pickListAddButtonSlot > 0 ? 'plus' : '')"
                    [actionTooltip]="optionsPickListAddTooltip"
                    (onAction)="addPickListValue($event)"
                    [defaultLabel]="placeholder"
                    [options]="selectItems"
                    [style]="{width:'100%'}"
                    [panelStyle]="{'margin-left': '8px'}"
                    [styleClass]="multiSelectClasses"
                    dropdownIcon="pi pi-caret-down"
                    [baseZIndex]="2000"
                    scrollHeight="250px"
                    [maxSelectedLabels]="maxSelectedLabels"
                    [selectedItemsWordwrap]="selectedItemsWordwrap"
                    [selectionLimit]="selectionLimit"
                    [filter]="true"
                    filterPlaceHolder="Search Items"
                    [showHeader]="true"
                    appendTo="body"
                    [(ngModel)]="value"
                    [ngModelOptions]="{standalone: standalone}"
                    #inputControl="ngModel">
      <ng-template let-item let-i="index" pTemplate="item">
        <div *ngIf="item.GroupHeader" style="color: black; font-weight: bolder; font-size: larger; opacity:1 !important; display:inline;">{{item.label}}</div>
        <div *ngIf="!item.GroupHeader" style="display:inline;">{{item.label}}</div>
      </ng-template>
      <p-header *ngIf="headerIcon || headerText || headerActionButton">
        <div class="clearfix pt-1 mb-2">
          <div class="float-left" *ngIf="headerIcon || headerText">
            <ib-icon [icon]="headerIcon" [label]="headerText"></ib-icon>
          </div>
          <div class="float-right" *ngIf="headerActionButton">
            <ib-action-button [button]="headerActionButton" [data]="value" [control]="inputControl"></ib-action-button>
          </div>
        </div>
      </p-header>
      <p-footer *ngIf="footerActionButton">
        <div class="clearfix">
          <div class="float-left">
            &nbsp;
          </div>
          <div class="float-right" *ngIf="footerActionButton">
            <ib-action-button [button]="footerActionButton" [data]="value"></ib-action-button>
          </div>
        </div>
      </p-footer>
    </ib-multiSelect>
    <!--<span *ngIf="suffixText || suffixIcon"
          class="ui-inputgroup-addon"
          [ngClass]="{'clickable': isSuffixClickable()}"
          ngbTooltip="{{suffixTooltip | translate}}"
          (click)="fireSuffixClick($event)">
      {{suffixText | translate}}
      <ib-icon icon="{{suffixIcon}}"></ib-icon>
    </span>-->
    <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
      <small *ngFor="let errorMessage of errorMessages" class="form-text input-invalid input-error-message">
        {{errorMessage | translate:inputInformationValues}}
      </small>
    </div>
  </div>

</div>
