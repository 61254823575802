<!-- Some groups (e.g. tab sets) can only have groups as children.  In other cases we render controls before child groups when both are present. -->
<ng-container [ngSwitch]="groupModel.PresentationStyle">

  <!-- Hidden Text for Debug -->
  <span *ngIf="true"
        style="display:none;">
    Rendering group id {{groupModel.FormControlGroupId}} using presentation style
    "{{groupModel.PresentationStyle}}" with child group count {{groupModel.Groups?.length}}
    and child control count {{groupModel.Controls?.length}}
  </span>

  <!-- S = TabSet and embedded T = Tabs -->
  <div *ngSwitchCase="'S'"
       class="row"
       [ngClass]="{ 'design-mode': designModeStyling }">
    <div class="{{Constants.Layout.fullWidth}}">
      <div *ngIf="designModeStyling">
        <div *ngIf="designModeStyling && !designModeGroupActions"
             class="float-right design-mode-icon-nav-group"
             (click)="onDesignModeEditGroupProperties($event,groupModel)"><i class="far fa-cog"></i></div>
        <div *ngIf="designModeStyling && designModeGroupActions"
             class="float-right design-mode-icon-nav-group">
          <ib-action-button [button]="designModeGroupActions"
                            [data]="{ group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
        </div>
      </div>
      <ul ngbNav
          #nav="ngbNav"
          [destroyOnHide]="false"
          class="{{groupModel.GroupClasses}} {{isMobile ? 'nav-pills' : 'nav-tabs'}}"
          [ngClass]="{ 'design-mode': designModeStyling && false }"
          [style]="groupModel.GroupStyles | safe : 'style'"
          (navChange)="onNavChange($event)">
        <!-- TabSet cannot have child controls and child groups must be tabs and must be rendered directly here. -->
        <ng-container *ngFor="let childGroupModel of groupModel.Groups; let i = index; trackBy: groupTrackByFn">
          <li *ngIf="childGroupModel.MetaData?.Properties?.Visible"
              [ngbNavItem]="childGroupModel.FormControlGroupId"
              [ngClass]="childGroupModel.GroupClasses"
              [style]="childGroupModel.GroupStyles | safe : 'style'">
            <a ngbNavLink>
              <ib-icon [icon]="childGroupModel.Icon"
                       [tooltip]="childGroupModel.HelpText"
                       [label]="childGroupModel.Label"
                       [labelClasses]="childGroupModel.LabelClasses"
                       [labelStyles]="childGroupModel.LabelStyles"></ib-icon>
              <div *ngIf="designModeStyling"
                   class="float-right ml-2 mt-1">
                <div *ngIf="designModeStyling && !designModeGroupActions"
                     class="float-right design-mode-icon-relative"
                     (click)="onDesignModeEditGroupProperties($event,groupModel)"><i class="far fa-cog"></i></div>
                <div *ngIf="designModeStyling && designModeGroupActions"
                     class="float-right design-mode-icon-relative">
                  <ib-action-button [button]="designModeGroupActions"
                                    [data]="{ group: childGroupModel , parentGroup: groupModel }"></ib-action-button>
                </div>
              </div>
              <!-- Hidden Text for Debug -->
              <span *ngIf="true"
                    style="display:none;">
                Rendering child group id {{childGroupModel.FormControlGroupId}} using presentation style
                "{{childGroupModel.PresentationStyle}}" with child group count {{childGroupModel.Groups?.length}}
                and child control count {{childGroupModel.Controls?.length}}
              </span>
            </a>
            <ng-template ngbNavContent
                         *ngIf="designMode || !childGroupModel.LazyLoad || childGroupModel.MetaData?.Properties?.Show">
              <ng-container *ngFor="let controlModel of childGroupModel.Controls; let i = index; trackBy: controlTrackByFn">
                <ib-form-control-render [designMode]="designMode"
                                        [designModeStyling]="designModeStyling"
                                        [designModeControlActions]="designModeControlActions"
                                        [formModel]="formModel"
                                        [parentGroupModel]="groupModel"
                                        [groupModel]="childGroupModel"
                                        [controlModel]="controlModel"
                                        [mode]="mode"
                                        [data]="data"
                                        [saveCount]="saveCount"
                                        [dataChangeCount]="dataChangeCount"
                                        [formIsReadOnly]="formIsReadOnly"
                                        [loading]="loading"
                                        [working]="working"
                                        [contextResourceType]="contextResourceType"
                                        [contextResourceId]="contextResourceId"
                                        [contextResourceId2]="contextResourceId2"
                                        [context]="context"
                                        (change)="onChange($event)"
                                        (status)="onStatusChange($event)"
                                        (addSave)="onAddSave($event)"
                                        (addClose)="onAddClose($event)"
                                        (editSave)="onEditSave($event)"
                                        (editClose)="onEditClose($event)"
                                        (designModeEditControlProperties)="onDesignModeEditControlProperties($event, groupModel, controlModel)">
                </ib-form-control-render>
              </ng-container>
              <ng-container *ngFor="let grandchildGroupModel of childGroupModel.Groups; let i = index; trackBy: groupTrackByFn">
                <ib-form-group-render *ngIf="grandchildGroupModel.MetaData?.Properties?.Visible"
                                      [designMode]="designMode"
                                      [designModeStyling]="designModeStyling"
                                      [designModeGroupActions]="designModeGroupActions"
                                      [designModeControlActions]="designModeControlActions"
                                      [formModel]="formModel"
                                      [parentGroupModel]="childGroupModel"
                                      [groupModel]="grandchildGroupModel"
                                      [mode]="mode"
                                      [data]="data"
                                      [saveCount]="saveCount"
                                      [dataChangeCount]="dataChangeCount"
                                      [formIsReadOnly]="formIsReadOnly"
                                      [loading]="loading"
                                      [working]="working"
                                      [contextResourceType]="contextResourceType"
                                      [contextResourceId]="contextResourceId"
                                      [contextResourceId2]="contextResourceId2"
                                      [context]="context"
                                      (change)="onChange($event)"
                                      (status)="onStatusChange($event)"
                                      (addSave)="onAddSave($event)"
                                      (addClose)="onAddClose($event)"
                                      (editSave)="onEditSave($event)"
                                      (editClose)="onEditClose($event)"
                                      (designModeEditGroupProperties)="onDesignModeEditGroupProperties($event,groupModel)"
                                      (designModeEditControlProperties)="onDesignModeEditControlProperties($event)">
                </ib-form-group-render>
              </ng-container>
            </ng-template>
          </li>
        </ng-container>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>

  <!-- M = Menu Group and embedded menu items (note that menu is nav-pills with vertical orientation) -->
  <div *ngSwitchCase="'M'"
       class="row"
       [ngClass]="{ 'design-mode': designModeStyling }">
    <div class="{{Constants.Layout.fullWidth}}">
      <div *ngIf="designModeStyling">
        <div *ngIf="designModeStyling && !designModeGroupActions"
             class="float-right design-mode-icon-nav-group"
             (click)="onDesignModeEditGroupProperties($event,groupModel)"><i class="far fa-cog"></i></div>
        <div *ngIf="designModeStyling && designModeGroupActions"
             class="float-right design-mode-icon-nav-group">
          <ib-action-button [button]="designModeGroupActions"
                            [data]="{ group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
        </div>
      </div>
      <div class="d-flex">
        <ul ngbNav
            #menu="ngbNav"
            [destroyOnHide]="false"
            class="{{groupModel.GroupClasses}} nav-pills"
            orientation="vertical"
            [ngClass]="{ 'design-mode': designModeStyling && false }"
            [style]="groupModel.GroupStyles | safe : 'style'"
            (navChange)="onNavChange($event)">
          <!-- TabSet cannot have child controls and child groups must be tabs and must be rendered directly here. -->
          <ng-container *ngFor="let childGroupModel of groupModel.Groups; let i = index; trackBy: groupTrackByFn">
            <li *ngIf="childGroupModel.MetaData?.Properties?.Visible"
                [ngbNavItem]="childGroupModel.FormControlGroupId"
                [ngClass]="childGroupModel.GroupClasses"
                [style]="childGroupModel.GroupStyles | safe : 'style'">
              <a ngbNavLink>
                <ib-icon [icon]="childGroupModel.Icon"
                         [tooltip]="childGroupModel.HelpText"
                         [label]="childGroupModel.Label"
                         [labelClasses]="childGroupModel.LabelClasses"
                         [labelStyles]="childGroupModel.LabelStyles"></ib-icon>
                <div *ngIf="designModeStyling"
                     class="float-right ml-2 mt-1">
                  <div *ngIf="designModeStyling && !designModeGroupActions"
                       class="float-right design-mode-icon-relative"
                       (click)="onDesignModeEditGroupProperties($event,groupModel)"><i class="far fa-cog"></i></div>
                  <div *ngIf="designModeStyling && designModeGroupActions"
                       class="float-right design-mode-icon-relative">
                    <ib-action-button [button]="designModeGroupActions"
                                      [data]="{ group: childGroupModel , parentGroup: groupModel }"></ib-action-button>
                  </div>
                </div>
                <!-- Hidden Text for Debug -->
                <span *ngIf="true"
                      style="display:none;">
                  Rendering child group id {{childGroupModel.FormControlGroupId}} using presentation style
                  "{{childGroupModel.PresentationStyle}}" with child group count {{childGroupModel.Groups?.length}}
                  and child control count {{childGroupModel.Controls?.length}}
                </span>
              </a>
              <ng-template ngbNavContent
                           *ngIf="designMode || !childGroupModel.LazyLoad || childGroupModel.MetaData?.Properties?.Show">
                <ng-container *ngFor="let controlModel of childGroupModel.Controls; let i = index; trackBy: controlTrackByFn">
                  <ib-form-control-render [designMode]="designMode"
                                          [designModeStyling]="designModeStyling"
                                          [designModeControlActions]="designModeControlActions"
                                          [formModel]="formModel"
                                          [parentGroupModel]="groupModel"
                                          [groupModel]="childGroupModel"
                                          [controlModel]="controlModel"
                                          [mode]="mode"
                                          [data]="data"
                                          [saveCount]="saveCount"
                                          [dataChangeCount]="dataChangeCount"
                                          [formIsReadOnly]="formIsReadOnly"
                                          [loading]="loading"
                                          [working]="working"
                                          [contextResourceType]="contextResourceType"
                                          [contextResourceId]="contextResourceId"
                                          [contextResourceId2]="contextResourceId2"
                                          [context]="context"
                                          (change)="onChange($event)"
                                          (status)="onStatusChange($event)"
                                          (addSave)="onAddSave($event)"
                                          (addClose)="onAddClose($event)"
                                          (editSave)="onEditSave($event)"
                                          (editClose)="onEditClose($event)"
                                          (designModeEditControlProperties)="onDesignModeEditControlProperties($event, groupModel, controlModel)">
                  </ib-form-control-render>
                </ng-container>
                <ng-container *ngFor="let grandchildGroupModel of childGroupModel.Groups; let i = index; trackBy: groupTrackByFn">
                  <ib-form-group-render *ngIf="grandchildGroupModel.MetaData?.Properties?.Visible"
                                        [designMode]="designMode"
                                        [designModeStyling]="designModeStyling"
                                        [designModeGroupActions]="designModeGroupActions"
                                        [designModeControlActions]="designModeControlActions"
                                        [formModel]="formModel"
                                        [parentGroupModel]="childGroupModel"
                                        [groupModel]="grandchildGroupModel"
                                        [mode]="mode"
                                        [data]="data"
                                        [saveCount]="saveCount"
                                        [dataChangeCount]="dataChangeCount"
                                        [formIsReadOnly]="formIsReadOnly"
                                        [loading]="loading"
                                        [working]="working"
                                        [contextResourceType]="contextResourceType"
                                        [contextResourceId]="contextResourceId"
                                        [contextResourceId2]="contextResourceId2"
                                        [context]="context"
                                        (change)="onChange($event)"
                                        (status)="onStatusChange($event)"
                                        (addSave)="onAddSave($event)"
                                        (addClose)="onAddClose($event)"
                                        (editSave)="onEditSave($event)"
                                        (editClose)="onEditClose($event)"
                                        (designModeEditGroupProperties)="onDesignModeEditGroupProperties($event,groupModel)"
                                        (designModeEditControlProperties)="onDesignModeEditControlProperties($event)">
                  </ib-form-group-render>
                </ng-container>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <div [ngbNavOutlet]="menu"
             class="ml-4 flex-fill"></div>
      </div>
    </div>
  </div>

  <!-- F = FieldSet -->
  <fieldset *ngSwitchCase="'F'"
            [ngClass]="groupModel.GroupClasses"
            [style]="groupModel.GroupStyles | safe : 'style'">
    <ng-container *ngFor="let controlModel of groupModel.Controls; let i = index; trackBy: controlTrackByFn">
      <ib-form-control-render [designMode]="designMode"
                              [designModeStyling]="designModeStyling"
                              [designModeControlActions]="designModeControlActions"
                              [formModel]="formModel"
                              [parentGroupModel]="parentGroupModel"
                              [groupModel]="groupModel"
                              [controlModel]="controlModel"
                              [mode]="mode"
                              [data]="data"
                              [saveCount]="saveCount"
                              [dataChangeCount]="dataChangeCount"
                              [formIsReadOnly]="formIsReadOnly"
                              [loading]="loading"
                              [working]="working"
                              [contextResourceType]="contextResourceType"
                              [contextResourceId]="contextResourceId"
                              [contextResourceId2]="contextResourceId2"
                              [context]="context"
                              (change)="onChange($event)"
                              (status)="onStatusChange($event)"
                              (addSave)="onAddSave($event)"
                              (addClose)="onAddClose($event)"
                              (editSave)="onEditSave($event)"
                              (editClose)="onEditClose($event)"
                              (designModeEditControlProperties)="onDesignModeEditControlProperties($event, groupModel, controlModel)">
      </ib-form-control-render>
    </ng-container>
    <ng-container *ngFor="let childGroupModel of groupModel.Groups; let i = index; trackBy: groupTrackByFn">
      <ib-form-group-render *ngIf="childGroupModel.MetaData?.Properties?.Visible"
                            [designMode]="designMode"
                            [designModeStyling]="designModeStyling"
                            [designModeGroupActions]="designModeGroupActions"
                            [designModeControlActions]="designModeControlActions"
                            [formModel]="formModel"
                            [parentGroupModel]="groupModel"
                            [groupModel]="childGroupModel"
                            [mode]="mode"
                            [data]="data"
                            [saveCount]="saveCount"
                            [dataChangeCount]="dataChangeCount"
                            [formIsReadOnly]="formIsReadOnly"
                            [loading]="loading"
                            [working]="working"
                            [contextResourceType]="contextResourceType"
                            [contextResourceId]="contextResourceId"
                            [contextResourceId2]="contextResourceId2"
                            [context]="context"
                            (change)="onChange($event)"
                            (status)="onStatusChange($event)"
                            (addSave)="onAddSave($event)"
                            (addClose)="onAddClose($event)"
                            (editSave)="onEditSave($event)"
                            (editClose)="onEditClose($event)"
                            (designModeEditGroupProperties)="onDesignModeEditGroupProperties($event,groupModel)"
                            (designModeEditControlProperties)="onDesignModeEditControlProperties($event)">
      </ib-form-group-render>
    </ng-container>
  </fieldset>

  <!-- R = Row -->
  <div *ngSwitchCase="'R'"
       class="row {{groupModel.GroupClasses}}"
       [ngClass]="{ 'design-mode': designModeStyling }"
       [style]="groupModel.GroupStyles | safe : 'style'">
    <div *ngIf="designModeStyling && !designModeGroupActions"
         class="design-mode-icon"
         (click)="onDesignModeEditGroupProperties($event,groupModel)"><i class="far fa-cog"></i></div>
    <div *ngIf="designModeStyling && designModeGroupActions"
         class="design-mode-icon">
      <ib-action-button [button]="designModeGroupActions"
                        [data]="{ group: groupModel , parentGroup: parentGroupModel  }"></ib-action-button>
    </div>
    <!-- Row cannot have child controls and child groups must be columns and must be rendered directly here. -->
    <div *ngFor="let childGroupModel of groupModel.Groups; let i = index"
         class="{{getGroupColumnClasses(childGroupModel)}} {{childGroupModel.GroupClasses}}"
         [ngClass]="{ 'design-mode': designModeStyling }"
         [style]="getGroupColumnStyles(childGroupModel)">
      <div *ngIf="designModeStyling && !designModeGroupActions"
           class="design-mode-icon"
           (click)="onDesignModeEditGroupProperties($event,childGroupModel)"><i class="far fa-cog"></i></div>
      <div *ngIf="designModeStyling && designModeGroupActions"
           class="design-mode-icon">
        <ib-action-button [button]="designModeGroupActions"
                          [data]="{ group: childGroupModel , parentGroup: groupModel }"></ib-action-button>
      </div>
      <!-- Hidden Text for Debug -->
      <span *ngIf="true"
            style="display:none;">
        Rendering child group id {{childGroupModel.FormControlGroupId}} using presentation style
        "{{childGroupModel.PresentationStyle}}" with child group count {{childGroupModel.Groups?.length}}
        and child control count {{childGroupModel.Controls?.length}}
      </span>
      <ng-container *ngFor="let controlModel of childGroupModel.Controls; let i = index; trackBy: controlTrackByFn">
        <ib-form-control-render [designMode]="designMode"
                                [designModeStyling]="designModeStyling"
                                [designModeControlActions]="designModeControlActions"
                                [formModel]="formModel"
                                [parentGroupModel]="groupModel"
                                [groupModel]="childGroupModel"
                                [controlModel]="controlModel"
                                [mode]="mode"
                                [data]="data"
                                [saveCount]="saveCount"
                                [dataChangeCount]="dataChangeCount"
                                [formIsReadOnly]="formIsReadOnly"
                                [loading]="loading"
                                [working]="working"
                                [contextResourceType]="contextResourceType"
                                [contextResourceId]="contextResourceId"
                                [contextResourceId2]="contextResourceId2"
                                [context]="context"
                                (change)="onChange($event)"
                                (status)="onStatusChange($event)"
                                (addSave)="onAddSave($event)"
                                (addClose)="onAddClose($event)"
                                (editSave)="onEditSave($event)"
                                (editClose)="onEditClose($event)"
                                (designModeEditControlProperties)="onDesignModeEditControlProperties($event, groupModel, controlModel)">
        </ib-form-control-render>
      </ng-container>
      <ng-container *ngFor="let grandchildGroupModel of childGroupModel.Groups; let i = index; trackBy: groupTrackByFn">
        <ib-form-group-render *ngIf="grandchildGroupModel.MetaData?.Properties?.Visible"
                              [designMode]="designMode"
                              [designModeStyling]="designModeStyling"
                              [designModeGroupActions]="designModeGroupActions"
                              [designModeControlActions]="designModeControlActions"
                              [formModel]="formModel"
                              [parentGroupModel]="childGroupModel"
                              [groupModel]="grandchildGroupModel"
                              [mode]="mode"
                              [data]="data"
                              [saveCount]="saveCount"
                              [dataChangeCount]="dataChangeCount"
                              [formIsReadOnly]="formIsReadOnly"
                              [loading]="loading"
                              [working]="working"
                              [contextResourceType]="contextResourceType"
                              [contextResourceId]="contextResourceId"
                              [contextResourceId2]="contextResourceId2"
                              [context]="context"
                              (change)="onChange($event)"
                              (status)="onStatusChange($event)"
                              (addSave)="onAddSave($event)"
                              (addClose)="onAddClose($event)"
                              (editSave)="onEditSave($event)"
                              (editClose)="onEditClose($event)"
                              (designModeEditGroupProperties)="onDesignModeEditGroupProperties($event,groupModel)"
                              (designModeEditControlProperties)="onDesignModeEditControlProperties($event)">
        </ib-form-group-render>
      </ng-container>
    </div>
  </div>

  <!-- A = Accordion -->
  <div *ngSwitchCase="'A'"
       class="row"
       [ngClass]="{ 'design-mode': designModeStyling }">
    <div class="{{Constants.Layout.fullWidth}}">
      <div *ngIf="designModeStyling">
        <div *ngIf="designModeStyling && !designModeGroupActions"
             class="float-right design-mode-icon-nav-group"
             (click)="onDesignModeEditGroupProperties($event,groupModel)"><i class="far fa-cog"></i></div>
        <div *ngIf="designModeStyling && designModeGroupActions"
             class="float-right design-mode-icon-nav-group">
          <ib-action-button [button]="designModeGroupActions"
                            [data]="{ group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
        </div>
      </div>
      <!-- <ngb-accordion *ngIf="groupModel.Groups && groupModel.Groups.length > 0"
                     [closeOthers]="true"
                     [activeIds]="groupModel.Groups[0].FormControlGroupId"> -->
      <ngb-accordion [closeOthers]="true">
        <ng-container *ngFor="let childGroupModel of groupModel.Groups; let i = index; trackBy: groupTrackByFn">
          <ngb-panel id="{{childGroupModel.FormControlGroupId}}">
            <ng-template ngbPanelTitle>
              <ib-icon [icon]="childGroupModel.Icon"
                       [tooltip]="childGroupModel.HelpText"
                       [label]="childGroupModel.Label"
                       [labelClasses]="childGroupModel.LabelClasses"
                       [labelStyles]="childGroupModel.LabelStyles"></ib-icon>
            </ng-template>
            <ng-template ngbPanelContent>
              <ib-form-group-render *ngIf="childGroupModel.MetaData?.Properties?.Visible"
                                    [designMode]="designMode"
                                    [designModeStyling]="designModeStyling"
                                    [designModeGroupActions]="designModeGroupActions"
                                    [designModeControlActions]="designModeControlActions"
                                    [formModel]="formModel"
                                    [parentGroupModel]="groupModel"
                                    [groupModel]="childGroupModel"
                                    [mode]="mode"
                                    [data]="data"
                                    [saveCount]="saveCount"
                                    [dataChangeCount]="dataChangeCount"
                                    [formIsReadOnly]="formIsReadOnly"
                                    [loading]="loading"
                                    [working]="working"
                                    [contextResourceType]="contextResourceType"
                                    [contextResourceId]="contextResourceId"
                                    [contextResourceId2]="contextResourceId2"
                                    [context]="context"
                                    (change)="onChange($event)"
                                    (status)="onStatusChange($event)"
                                    (addSave)="onAddSave($event)"
                                    (addClose)="onAddClose($event)"
                                    (editSave)="onEditSave($event)"
                                    (editClose)="onEditClose($event)"
                                    (designModeEditGroupProperties)="onDesignModeEditGroupProperties($event,groupModel)"
                                    (designModeEditControlProperties)="onDesignModeEditControlProperties($event)">
              </ib-form-group-render>
            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>
    </div>
  </div>


  <!-- Default option to catch anything else... C = Column; B = Block are all div with class/style provided -->
  <div *ngSwitchDefault
       class="{{groupModel.GroupClasses}}"
       [ngClass]="{ 'design-mode': designModeStyling }"
       [style]="groupModel.GroupStyles | safe : 'style'">
    <div *ngIf="designModeStyling && !designModeGroupActions"
         class="design-mode-icon"
         (click)="onDesignModeEditGroupProperties($event,groupModel)"><i class="far fa-cog"></i></div>
    <div *ngIf="designModeStyling && designModeGroupActions"
         class="design-mode-icon">
      <ib-action-button [button]="designModeGroupActions"
                        [data]="{ group: groupModel , parentGroup: parentGroupModel }"></ib-action-button>
    </div>
    <ng-container *ngFor="let controlModel of groupModel.Controls; let i = index; trackBy: controlTrackByFn">
      <ib-form-control-render [designMode]="designMode"
                              [designModeStyling]="designModeStyling"
                              [designModeControlActions]="designModeControlActions"
                              [formModel]="formModel"
                              [parentGroupModel]="parentGroupModel"
                              [groupModel]="groupModel"
                              [controlModel]="controlModel"
                              [mode]="mode"
                              [data]="data"
                              [saveCount]="saveCount"
                              [dataChangeCount]="dataChangeCount"
                              [formIsReadOnly]="formIsReadOnly"
                              [loading]="loading"
                              [working]="working"
                              [contextResourceType]="contextResourceType"
                              [contextResourceId]="contextResourceId"
                              [contextResourceId2]="contextResourceId2"
                              [context]="context"
                              (change)="onChange($event)"
                              (status)="onStatusChange($event)"
                              (addSave)="onAddSave($event)"
                              (addClose)="onAddClose($event)"
                              (editSave)="onEditSave($event)"
                              (editClose)="onEditClose($event)"
                              (designModeEditControlProperties)="onDesignModeEditControlProperties($event, groupModel, controlModel)">
      </ib-form-control-render>
    </ng-container>
    <ng-container *ngFor="let childGroupModel of groupModel.Groups; let i = index; trackBy: groupTrackByFn">
      <ib-form-group-render *ngIf="childGroupModel.MetaData?.Properties?.Visible"
                            [designMode]="designMode"
                            [designModeStyling]="designModeStyling"
                            [designModeGroupActions]="designModeGroupActions"
                            [designModeControlActions]="designModeControlActions"
                            [formModel]="formModel"
                            [parentGroupModel]="groupModel"
                            [groupModel]="childGroupModel"
                            [mode]="mode"
                            [data]="data"
                            [saveCount]="saveCount"
                            [dataChangeCount]="dataChangeCount"
                            [formIsReadOnly]="formIsReadOnly"
                            [loading]="loading"
                            [working]="working"
                            [contextResourceType]="contextResourceType"
                            [contextResourceId]="contextResourceId"
                            [contextResourceId2]="contextResourceId2"
                            [context]="context"
                            (change)="onChange($event)"
                            (status)="onStatusChange($event)"
                            (addSave)="onAddSave($event)"
                            (addClose)="onAddClose($event)"
                            (editSave)="onEditSave($event)"
                            (editClose)="onEditClose($event)"
                            (designModeEditGroupProperties)="onDesignModeEditGroupProperties($event,groupModel)"
                            (designModeEditControlProperties)="onDesignModeEditControlProperties($event)">
      </ib-form-group-render>
    </ng-container>
  </div>

</ng-container>
