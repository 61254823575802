import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
import { Query, IQuery } from "projects/core-lib/src/lib/api/ApiModels";
import { PlacementArray } from "@ng-bootstrap/ng-bootstrap/util/positioning";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";


/**
 * This class holds information about an action and how it is presented to the user.
 */
export class Action {

  /**
  Internal id for this action.  Can be used to create quick list of default actions that get other settings defined later.
  */
  actionId: string = "";
  /**
  Icon to show the user when showing this action as an option.
  */
  icon: string = "";
  /**
  Label to show the user when showing this action as an option.
  */
  label: string = "";
  /**
  The type of action item.
  */
  type: "action" | "separator" | "heading" = "action";
  /**
  Context color to use when showing this action as an option if context color is supported.
  */
  contextColor: string = "default"; // default, primary, secondary, info, success, warning, danger, light, dark
  /**
  Active context color to use when showing this action as an option if active context color is supported.
  */
  contextColorActive: string = "default"; // default, primary, secondary, info, success, warning, danger, light, dark
  /**
  Context color to use for the icon.
  */
  contextColorIcon: string = ""; // default, primary, secondary, info, success, warning, danger, light, dark
  /**
  Description to show the user for more information about the action.  Could be tied to an info button, tooltip, etc.
  */
  description: string = "";
  /**
  When defined this represents options available for this current action.  See optionsDisplay for how these get presented
  but they are typically going to be a button group or submenu.
  */
  options: Action[] = [];
  /**
  An indicator of how to display any child options.
  */
  optionsDisplay: "none" | "buttongroup" = "buttongroup";
  /**
  Cargo object that can hold other information about the action.
  */
  cargo: any = {};
  /**
  The action to fire when the user selects this action.  The value passed as a parameter is our EventModel object with
  the action attached as the data property.  Other information may be attached to the cargo property.
  */
  action: (event?: EventModel) => any;
  /**
  An optional visible function that will accept a data object and return true or false if the action should be visible.
  This allows support for showing and hiding actions base on suitability of the data.  Note that the function should
  anticipate a null data object.
  */
  visible: (data?: any, cargo?: any) => boolean;
  /**
  An optional active function that will accept a data object and return true or false if the action is considered active.
  This allows support for showing an action differently if it is currently considered active.
  */
  active: (data?: any, cargo?: any) => boolean;

  /**
  An optional function that will use data and/or cargo objects to get the label to use.  The function should anticipate
  that either or both of these values could be null.  The function will be called on init or when data or cargo values
  are changed.
  */
  getLabel: (data?: any, cargo?: any) => string;

  /**
  An optional function that will use data and/or cargo objects to get the icon to use.  The function should anticipate
  that either or both of these values could be null.  The function will be called on init or when data or cargo values
  are changed.
  */
  getIcon: (data?: any, cargo?: any) => string;

  /**
  An optional function that will allow updating the action object based on data and/or cargo objects.  The function should anticipate
  that either or both of these values could be null.  The function will be called on init or when data or cargo values
  are changed.
  */
  update: (action: Action, data?: any, cargo?: any) => void;

  constructor(actionId: string = "", label: string = "", icon: string = "", contextColor: string = "default", action?: (event?: EventModel) => any, cargo: any = {}) {
    this.actionId = actionId || "";
    this.label = label || "";
    this.icon = icon || "";
    this.contextColor = contextColor || "default";
    this.action = action;
    this.cargo = cargo;
  }

}


/**
This class is used to define a button item to be used in various places in the ui
*/
export class ButtonItem extends Action {
  size: "" | "lg" | "sm" | "xs" | "xxs" = ""; // "", lg, sm, xs
  menuItemSize: "" | "lg" | "sm" | "xs" | "xxs" = ""; // "", lg, sm, xs
  scrollHeight: "none" | "normal" | "large" | "auto" = "none";
  /**
  If the button has options this controls the menu placement for those options.  The default is
  ["bottom-left", "top-left"] but in cases where the button is close to the right of the ui
  bottom-right may be more appropriate.  Can also be an array of multiple values.
  The options include:
  "auto" | "top" | "top-left" | "top-right" | "bottom" | "bottom-left" | "bottom-right"|
  "left" | "left-top" | "left-bottom" | "right" | "right-top" | "right-bottom"
  */
  menuPlacement: PlacementArray = ["bottom-right", "top-right"]; // better default??? ["bottom-left", "top-left"];
  //menuPlacement: "top" | "top-left" | "top-right" | "bottom" | "bottom-left" | "bottom-right" | "left" | "left-top" | "left-bottom" | "right" | "right-top" | "right-bottom" | "auto" = "auto"; // "bottom-left";
  fullWidth: boolean = false;
  /**
  When defined this causes the button to be rendered as a drop down button with a list of options specified in this collection.
  If the button has an action attached to it the button will be a split drop down where the button has a default action, otherwise,
  it will be a drop down only with no default action.  Options with no icon and no label and, therefore, no ui will be rendered as a divider.
  */
  options: Action[] = [];
  /**
  Defining a button role enables automatic selection of buttons from a list of possible
  buttons in different scenarios.  This enables code to wire up possible buttons to be
  used in various scenarios and then the code that consumes it to pick the right buttons
  to show in a given scenario.  For example, the alert manager can pick buttons to display
  based on the type of alert that is generated.
  */
  role: ButtonRole = ButtonRole.Undefined;
  constructor(label: string = "", icon: string = "", contextColor: string = "default", action?: (event?: EventModel) => any, role: ButtonRole = ButtonRole.Undefined, actionId: string = "") {
    super(actionId || `button-${label}`, label, icon, contextColor, action);
    this.role = role || ButtonRole.Undefined;
  }

  public insertOption(action: Action, afterActionId: string, insertSeparator: boolean) {

    let index: number = -1;
    if (afterActionId) {
      index = this.options.findIndex(x => Helper.equals(x.actionId, afterActionId, true));
    }

    if (index === -1) {
      if (insertSeparator && this.options.length > 0) {
        this.options.push(new Action()); // separator
      }
      this.options.push(action);
    } else {
      if (insertSeparator) {
        // ++ since we want to be after the specified afterActionId
        index++;
        this.options.splice(index, 0, new Action()); // separator
      }
      // ++ since we want to be after the specified afterActionId
      index++;
      this.options.splice(index, 0, action);
    }

  }

}

export enum ButtonRole {
  Undefined,
  All,
  VersionConflict
}



export class DataListOptions {

  /**
  Identifies the data list and can be used for storage in browser storage or user preferences to remember user settings.
  */
  public dataListId: string = "";

  /**
  The API to use for this data list.  If not specified then must be manually set.
  */
  public apiPropertiesName: string = "";

  /**
  When true no api interaction is performed and data must be provided by parent component.
  */
  public suppressApiInteraction: boolean = false;

  /**
  If using a custom template this is the url for that template.
  */
  public templateUrl: string = "";

  /**
  When true search control is displayed.
  */
  public canSearch: boolean = true;
  /**
  When true the rows per page control is displayed.
  */
  public canPickRowsPerPage: boolean = true;
  /**
  When true the pagination control is displayed.
  */
  public canPage: boolean = true;

  /**
  The query object for this list.
  */
  public query: IQuery = new Query();

  /**
  User friendly title for the list.  May be used as control header and/or page title depending on control.
  */
  public listTitle: string = "";

  /**
  Event to fire when list is loaded.
  */
  public listLoadedAction: (rows: any[]) => any;

  /**
  Name of the action to take when row is clicked.
  Typical options: none, edit, view
  */
  public rowClickActionName: string = "edit";

  /**
  Row click action to fire.
  */
  public rowClickAction: (row: any, index: number) => any;

  /**
  Columns to display in the list.
  */
  public columns: DataListColumn[] = [];

  /**
  Actions available in the row actions button.
  */
  public rowActions: Action[] = [];

  /**
  Row action mode for how to display row actions.  Options include:
  menu - drop down menu of possible actions
  icons - list of icons of possible actions
  buttons - list of buttons of possible actions
  */
  public rowActionMode: string = "menu";

  /**
  Button to use for list actions (if any).  Most common scenario is adding a new object.
  Actions on objects in the list are found under rowActions.
  */
  public actionButton: ButtonItem = null;

  /**
  Pick lists to make available in the list controller.
  */
  public pickList: any = {};

  /**
  Optional properties.
  */
  public properties: any = {};

}

export class DataListColumn {

  /**
  Label used as column header.
  */
  public label: string = "";

  /**
  Object property name for what data to show in the column.
  */
  public propertyName: string = "";

  /**
  Flag if the column is sortable.
  */
  public canSort: boolean = true;

  /**
  Optional filter to apply to the property value.
  */
  public filter: string = "";

  /**
  Typically blank but could be "checkbox"
  */
  public controlType: string = "";

  /**
  Optional function to call to get the display value when more complicated than a reference to a property name.
  */
  public getValue: (value: any) => any = null; // if assigned returns html string to display

  constructor(label: string = "", propertyName: string = "", canSort: boolean = true, filter: string = "", controlType: string = "") {
    this.label = label || "";
    this.propertyName = propertyName || "";
    this.canSort = canSort;
    this.filter = filter || "";
    this.controlType = controlType || "";
  }

}


export class CommonDataEditorOptions {

  /**
  When true the component is only available for a validated user.
  */
  public requiresValidatedUser: boolean = true;

  /**
  If the component requires a certain contact type that can be defined here.
  */
  public requiresContactType: string = Constants.ContactType.Directory;

  /**
  When true the component is only available for a partition zero user.
  */
  public requiresPartitionZero: boolean = false;

  /**
   * Use private for access area so we use our getter/setter and we can provide some
   * debug logging as needed.
   */
  private _accessArea: string = "";
  /**
  The security access area for the controller inheriting from this base class.  This provides quick access to
  CanRead(), CanAdd(), CanEdit(), CanDelete(), CanOutput(), CanFull() helper methods.  More advanced permission checking like
  checking for related permissions, multiple permissions, etc. can be done via HasPermission() and DeniedPermission()
  methods.
  */
  public get accessArea(): string {
    return this._accessArea;
  }
  public set accessArea(area: string) {
    this._accessArea = area;
    if (this.objectFriendlyName) {
      Log.debug("orange", "Security Access Area", `${this.objectFriendlyName} security access area set to '${area}'.`);
    } else {
      Log.debug("orange", "Security Access Area", `Security access area set to '${area}'.`);
    }
  }

  /**
  Object template to use as source when adding new objects.
  */
  public objectTemplate: any = null;

  /**
   * If data being loaded and saved is attached to a property of the data object instead of the
   * object itself this is the property name that represents the data.  Most of the time the
   * data is represented by the object but in some cases like dynamic editors and forms the data
   * is stored as a property of the object as there may be multiple objects involved.
   * e.g. instead of the object being a BillingTransaction the object is { Customer: any, BillingTransaction: any }
   * and this objectEnvelopePropertyName is set to "BillingTransaction".
   */
  public objectEnvelopePropertyName: string = "";

  public objectFriendlyName: string = "";
  public objectKeyPropertyName: string = "";
  public objectDescriptionPropertyNames: string[] = [];

  public objectLoadProperties: any = null;

  public documentTitleMirrorHeaderText: boolean = true;
  public headerShow: boolean = true;
  public headerTextListMode: string = null;
  public headerTextAddMode: string = null;
  public headerTextEditModePrefix: string = null;
  public headerTextEditModeIncludeObjectDescription: boolean = true;
  public headerTextDownloadUploadMode: string = null;

  /**
   * When download is supported this indicates if download without filter is an option.
   * Some child components that are embedded and have a forced filter for the parent's key
   * should not have the the option of performing a download without the filter.
   * Setting downloadAllowExportWithoutFilter to false will remove the ability to download
   * without the filter.
   */
  public downloadAllowExportWithoutFilter: boolean = true;
  /**
   * Setting downloadShowThatFilterWillBeApplied to false will not show that a filter is
   * applied to the export request.  This is typically left at the default value of true
   * but embedded child components with a parent key filter applied may want this false.
   */
  public downloadShowThatFilterWillBeApplied: boolean = true;

  public listModelMatchesFullModel: boolean = false;
  public rowSelectedAction: "none" | "edit" | "view" = "edit";

  public addMethod: "inline" | "modal" | "route" = "inline";
  public editMethod: "inline" | "modal" | "route" = "inline";
  public viewMethod: "inline" | "modal" | "route" = "inline";

  /**
   * When true the back (e.g. close) button will trigger a browser back operation when there is
   * browser history to work with.  Otherwise, the form back/close button will switch to list mode.
   */
  public formCloseButtonPerformsBrowserBackOperation = true;

  ///**
  //Template url to use for both add and edit forms.
  //*/
  //public get templateUrl(): string {
  //  return this._templateUrl || this._templateUrlEditMode || this._templateUrlAddMode || "";
  //}
  //public set templateUrl(url: string) {
  //  this._templateUrl = url;
  //}
  //private _templateUrl: string = null;

  ///**
  //Template url for add forms.  If not specified the templateUrl property is returned.
  //*/
  //public get templateUrlAddMode(): string {
  //  return this._templateUrlAddMode || this._templateUrl || "";
  //}
  //public set templateUrlAddMode(url: string) {
  //  this._templateUrlAddMode = url;
  //}
  //private _templateUrlAddMode: string = null;

  ///**
  //Template url for edit forms.  If not specified the templateUrl property is returned.
  //*/
  //public get templateUrlEditMode(): string {
  //  return this._templateUrlEditMode || this._templateUrl || "";
  //}
  //public set templateUrlEditMode(url: string) {
  //  this._templateUrlEditMode = url;
  //}
  //private _templateUrlEditMode: string = null;


  /**
  Button to use for actions on add form (if any).  Typically null.
  */
  public actionButtonAddMode: ButtonItem = null;

  /**
  Button to use for actions on edit form (if any).  Typically null or a delete button.
  */
  public actionButtonEditMode: ButtonItem = null;

  /**
  Button to use for actions on view form (if any).  Typically null or a delete button.
  */
  public actionButtonViewMode: ButtonItem = null;

  /**
   * Text to use as alias for 'Copy'.  If a different verb is more appropriate for
   * copy operation specify that here.
   */
  public copyAlias: string = "Copy";

  /**
   * If this function is assigned it should accept a data object and return a string that is appropriate for the description.
   * Can be left null and data model meta data will be utilized to determine the object description.
   */
  public getObjectDescription: (value: any) => string = null;

  /**
   * If this function is assigned it should accept a data object and return a string that is appropriate for link text for the object.
   * Can be left null and data model meta data will be utilized to determine the object description and use that for link text.
   */
  public getObjectLinkText: (value: any) => string = null;

  /**
   * If this function is assigned it should accept a data object and return a string that is appropriate for the question to ask
   * the user to confirm for deleting the object.
   * Can be left null and data model meta data will be utilized to determine the question to ask.
   */
  public getDeleteObjectMessage: (value: any) => string = null;

  /**
   * If this function is assigned then before any save function it will be checked to determine if the object can be saved or not.
   */
  public validateAction: (value: any) => any = null; // if assigned returns true or false to indicate object can be saved

  ///**
  //Attaching any callback methods or callback data here enables them to be shared with modal options when using modal editor
  //*/
  //public callback: any = {}; // Attach callback methods or data (typically under ".data" property) here

  /**
  When true edit submissions have overwrite changes set to true.  This should typically be false.
  */
  public overwriteChanges: boolean = false;

  /**
   * When true get operations in advance of edit/view will ignore cache.  This should normally be false but
   * in some scenarios where up-to-date values are mandatory this should be set to true.
   */
  public cacheIgnore: boolean = false;



  /**
   * When JSON is uploaded the properties listed here must all be truthy in the object for it to be
   * accepted as valid input.
   */
  public uploadRequiredPropertiesAll = [];

  /**
   * When JSON is uploaded at least one of the properties listed here must be truthy in the object for it to be
   * accepted as valid input.
   */
  public uploadRequiredPropertiesAtLeastOne = [];

  /**
   * The object description to use for upload modals.  If not present it will default to objectFriendlyName.
   */
  public uploadObjectDescription: string = "";

  /**
   * A function to fire when JSON has been uploaded.  This can perform custom actions and validation of the
   * uploaded data.  Parameters include the event that was fired, the string contents of the upload, and the parsed
   * object from the JSON string.  If the function returns true then the upload processing continues.  If the
   * function returns false then the upload processing is aborted.  It is the responsibility of this function to
   * alert the user to the reason the upload processing was aborted.
   */
  public uploadAction: ($event: any, contents: string, uploadedObject: any) => boolean;

  /**
   * The JSON uploaded will have the primary key set to null and the meta data set to a new meta data object
   * in preparation for the object to be added.  If there are other data transformations that are needed
   * for things like child object PK and FK set to null, etc. this function should be assigned to accomplish
   * that.  The uploaded object is passed in and a modified uploaded object is expected as the return value.
   */
  public uploadTransformObjectForAdd: (uploadedObject: any) => any;

  /**
   * The JSON uploaded will have the primary key and meta data set to the primary key and meta data of the
   * current object in preparation for the object to be updated.  If there are other data transformations that
   * are needed for things like FK values being set, child objects PK and FK set, removed child objects to be
   * set in MetaData.DeletedDataObjects, etc. this function should be assigned to accomplish that.
   * The uploaded object and current object are passed in and a modified uploaded object is expected as the
   * return value.
   */
  public uploadTransformObjectForEdit: (uploadedObject: any, currentObject: any) => any;

}



export class ContactCacheModel {
  public ContactId: number = null;
  public ContactName: string = "";
  public ContactEmail: string = "";
}



export class TimeLineItemModel {
  Title: string = "";
  Description: string = "";
  SortOrder: string = "";
  Icon: string = "";
  IconStyle: string = "";
  CanEdit: boolean = false;
  ModelProperty: string = "";
}


export class FormMenuViewModel {
  MenuName: string = "";
  Icon: string = "";
  Url: string = "";
  FormId: number = 0;
  Menu: FormMenuViewModel[] = [];
}


export class AttributeLayoutTabViewModel {
  PageName: string = "";
  AttributeSetIds: number[] = [];
  Menu: AttributeLayoutMenuViewModel[] = [];
}

export class AttributeLayoutMenuViewModel {
  MenuName: string = "";
  AttributeSetIds: number[] = [];
  Menu: AttributeLayoutMenuViewModel[] = [];
}

export class contactPickerSettings {
  size: string = "80-percent";
  contactTypes: string = "";
  parentContactId: number = null;
  addressTypeFilter: string = "";
  showAddressType: boolean = true;
  addressTypeCaption: string = "Address Type";
  selectedContactId: number = null;
  selectedContactType: string = "";
  selectedContactName: string = "";
  defaultContactId: number = null;
  defaultContactType: string = "";
  defaultContactName: string = "";
  canClearContact: boolean = true;
  canAddContact: boolean = true;
  addressTypes: m5core.PickListSelectionViewModel[] = [];
  successButtonCallback: any = (result: any) => { };
  cancelButtonCallback: any = (result: any) => { };
}



export class EventModel {

  /**
  The type of event. For example: click, focus, blur, etc.  This could be browser events,
  custom component events, etc.
  */
  public eventType: string = "";

  /**
  The native event object.
  */
  public event: any;

  /**
  If the event carries any data that is stored here.
  */
  public data: any;

  /**
  If the event carries meta-data that we want separate from data that is stored here.
  */
  public cargo: any;

  /**
  The element that triggered the event.
  */
  public element: EventElementModel;


  constructor(eventType: string, event: any, data: any = null, element: EventElementModel = null, cargo: any = null) {
    this.eventType = eventType;
    this.event = event;
    this.data = data;
    this.element = element;
    this.cargo = cargo;
  }

}

export class EventModelTyped<T> {

  /**
  The type of event. For example: click, focus, blur, etc.  This could be browser events,
  custom component events, etc.
  */
  public eventType: string = "";

  /**
  The native event object.
  */
  public event: any;

  /**
  If the event carries any data that is stored here.
  */
  public data: T;

  /**
  If the event carries meta-data that we want separate from data that is stored here.
  */
  public cargo: any;

  /**
  The element that triggered the event.
  */
  public element: EventElementModel;


  constructor(eventType: string, event: any, data: any = null, element: EventElementModel = null, cargo: any = null) {
    this.eventType = eventType;
    this.event = event;
    this.data = data;
    this.element = element;
    this.cargo = cargo;
  }

}

export class EventElementModel {

  /**
  The type of element triggering the event.  For example: input, button, icon, etc.
  */
  public type: string = "";

  /**
  The id of the element triggering the event.
  */
  public id: string = "";

  /**
  The name of the element triggering the event.
  */
  public name: string = "";

  /**
  The label used on the element triggering the event.  In instances where no name is specified this may provide insight into the element that triggered the event.
  */
  public label: string = "";

  /**
  An alternate label used on the element triggering the event.  In instances where no name is specified this may provide insight into the element that triggered the event.
  This may be an icon or other text depending on the type of element.
  */
  public alternateLabel: string = "";

  constructor(type: string = "", id: string = "", name: string = "", label: string = "", alternateLabel: string = "") {
    this.type = type || "";
    this.id = id || "";
    this.name = name || "";
    this.label = label || "";
    this.alternateLabel = alternateLabel || "";
  }

}
