import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { UxService } from '../../services/ux.service';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { takeUntil } from 'rxjs/operators';
import { textChangeRangeIsUnchanged } from 'typescript';
import Tourguide from "tourguidejs";


@Component({
  selector: 'ib-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.css'],
  encapsulation: ViewEncapsulation.None // Use to disable CSS Encapsulation for this component
})
export class HelpButtonComponent extends BaseComponent implements OnInit {


  /**
   * The help topic to use for the help menu.  Note that we have this passed in as an input
   * property instead of just listening to this.appService.helpLinkMonitor() because this
   * button is not only used in our nav header but also on modals, etc. where we may have
   * a specific help topic assigned to us.
   */
  @Input() helpTopic: m5web.HelpLinkEditViewModel = null;

  @Input() dropDownWrapperClass: string = "";
  @Input() dropDownButtonClass: string = "";

  helpOtherLinks: { icon?: string, label?: string, url?: string, startingVersion?: string, endingVersion?: string }[] = [];
  public get showHelpIndex(): boolean {
    if (!this.appService.appInfoOrDefault?.Branding?.SupportUrlIndex) {
      return false;
    }
    if (this.appService.helpLink && !this.appService.helpLink.IncludeHelpIndex) {
      return false;
    }
    return true;
  }

  public get helpIndexLink(): string {
    return this.appService.helpLinkAppendSupportToken(this.appService.appInfoOrDefault?.Branding?.SupportUrlIndex);
  }

  public get showHelpOtherLinks(): boolean {
    try {
      if (!AppConfig.supportUrlOther || AppConfig.supportUrlOther.length === 0) {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
    if (this.appService.helpLink && !this.appService.helpLink.IncludeSystemHelpLinks) {
      return false;
    }
    return true;
  }


  public get showHelpContactSupport(): boolean {
    if (!this.appService.appInfoOrDefault?.Branding?.SupportEmailAddress) {
      return false;
    }
    if (this.appService.helpLink && !this.appService.helpLink.IncludeContactSupport) {
      return false;
    }
    return true;
  }

  public get helpContactSupportLink(): string {
    if (!this.appService.appInfoOrDefault?.Branding?.SupportEmailAddress) {
      return "";
    }
    return `mailto:${this.appService.appInfoOrDefault?.Branding?.SupportEmailAddress}`;
  }


  /**
   * True if we have help links for the current context.  This is updated when helpTopic is assigned.
   */
  public hasHelpLinks: boolean = false;

  /**
   * Helps us know if help badges should be displayed.
   */
  public now: Date = new Date();
  public helpSpotlight: boolean = false;

  protected userHelpDisableAutoOpen: any = {};


  constructor(
    protected appService: AppService,
    protected uxService: UxService) {

    super();

    this.configHelpStatic();

  }

  // ngOnInit(): void {
  //   super.ngOnInit();
  // }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.helpTopic) {
      // helpTopic may be null but our config method anticipates that
      this.configHelpTopic();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.appService.preferenceObjectGet(Constants.ContactPreference.HelpDisableAutoOpen).pipe(takeUntil(this.ngUnsubscribe)).subscribe((preference: any) => {
      if (preference) {
        this.userHelpDisableAutoOpen = preference;
      }
    });
  }

  protected configHelpStatic(): void {

    // See if we have other links and if they're valid based on our version
    this.helpOtherLinks = [];
    try {
      if (!AppConfig.supportUrlOther || AppConfig.supportUrlOther.length === 0) {
        return;
      }
    } catch (err) {
      console.log(err);
      return;
    }
    AppConfig.supportUrlOther.forEach(link => {
      if (!link.startingVersion && !link.endingVersion) {
        // No version restrictions for this link
        this.helpOtherLinks.push(link);
      } else {
        // Check to see if our version is valid for this link
        if (this.appService?.status?.currentState?.versionRunning) {
          if ((!link.startingVersion || link.startingVersion <= this.appService.status.currentState.versionRunning) &&
            (!link.endingVersion || link.endingVersion >= this.appService.status.currentState.versionRunning)) {
            // Our version is valid for this link
            this.helpOtherLinks.push(link);
          }
        } else {
          // We don't know what our version is.  Show the link or not?
          this.helpOtherLinks.push(link);
        }
      }
    });

  }

  protected configHelpTopic(): void {

    this.now = new Date();
    this.helpSpotlight = false;

    if (!this.helpTopic) {
      this.hasHelpLinks = false;
      return;
    } else if (!this.helpTopic.Links) {
      this.hasHelpLinks = false;
      return;
    }

    this.hasHelpLinks = (this.helpTopic.Links.length > 0);
    if (!this.hasHelpLinks) {
      return;
    }

    // See if we have any auto-open help links or if we want to spotlight help changes
    let openedMessage: boolean = false;
    let openedTour: boolean = false;
    this.helpTopic.Links.forEach((link: m5web.HelpLinkItemEditViewModel) => {
      if (link.BadgeUntil) {
        // Normalize string to date object for html template ngIf date compare
        link.BadgeUntil = Helper.getDateFromString(link.BadgeUntil);
      }
      if (link.HelpMenuSpotlightUntil && Helper.getDateFromString(link.HelpMenuSpotlightUntil) > this.now) {
        this.helpSpotlight = true;
      }
      let open: boolean = link.AutoOpen;
      if (!open && link.AutoOpenWhenUrlContains) {
        if (Helper.contains(location.toString(), link.AutoOpenWhenUrlContains, true)) {
          open = true;
        }
      }
      if (link.AutoOpenCanBeDisabled) {
        // See if this user disabled auto open for this link
        if (this.userHelpDisableAutoOpen[`${this.helpTopic.Context}${link.HelpLinkItemId}`]) {
          open = false;
        }
      }
      if (open) {
        if (Helper.equals(link.HelpLinkItemType, "Message", true)) {
          if (openedMessage) {
            Log.errorMessage(`Auto open of message ${link.Description} was requested but we already have one open message for help context ${this.helpTopic.Context}.`);
          } else {
            this.onMessageShow(link, null);
            openedMessage = true;
          }
        } else if (Helper.equals(link.HelpLinkItemType, "Tour", true)) {
          if (openedTour) {
            Log.errorMessage(`Auto open of tour ${link.Description} was requested but we already have one open tour for help context ${this.helpTopic.Context}.`);
          } else {
            this.onTourShow(link, null);
            openedTour = true;
          }
        }
      }
    });

  }


  onMessageShow(link: m5web.HelpLinkItemEditViewModel, $event) {
    //console.error('message click', link, $event);
    const options = new ModalCommonOptions();
    if (Helper.equals(link.Message.WindowSize, "small", true)) {
      options.size = "small";
    } else if (Helper.equals(link.Message.WindowSize, "large", true)) {
      options.size = "large";
    } else if (Helper.equals(link.Message.WindowSize, "larger", true)) {
      options.size = "larger";
    } else if (Helper.equals(link.Message.WindowSize, "largest", true)) {
      options.size = "largest";
    }
    options.title = link.Message.Title;
    options.titleIcon = link.Message.TitleIcon;
    if (link.Message.TitleColor) {
      options.titleIconContextColor = link.Message.TitleColor;
      options.titleContextColor = link.Message.TitleColor;
    }
    options.message = link.Message.Text;
    if ((link.AutoOpen || link.AutoOpenWhenUrlContains) && link.AutoOpenCanBeDisabled) {
      options.featureToggleOn = false;
      options.featureToggleSupport = true;
      options.featureToggleLabel = "Don't Show This Again";
      options.featureToggleTooltip = "Do not automatically open this message again.";
      options.featureToggleEvent = (isFeatureToggleOn: boolean) => {
        this.appService.preferenceValueSet(Constants.ContactPreference.HelpDisableAutoOpen, `${this.helpTopic.Context}${link.HelpLinkItemId}`, isFeatureToggleOn);
        this.userHelpDisableAutoOpen[`${this.helpTopic.Context}${link.HelpLinkItemId}`] = isFeatureToggleOn;
      };
      if (this.userHelpDisableAutoOpen[`${this.helpTopic.Context}${link.HelpLinkItemId}`]) {
        options.featureToggleOn = true;
      }
    }
    if (link.Message.Movable) {
      this.uxService.modal.showSimpleOverlay(options);
    } else {
      this.uxService.modal.showSimpleModal(options);
    }
  }


  //Convert HelpLinkItemTourStepEditViewModel to Tourguide.js step format
  createTourStep(item: m5web.HelpLinkItemTourStepEditViewModel) {
    let singleStep: any = {};
    singleStep.useImages = false;

    //actiontarget defines which actions are active/allowed
    singleStep.actiontarget = {};
    singleStep.actiontarget.allowTargetClickEvent = item.AllowTargetClickEvent;
    singleStep.actiontarget.onTargetClickMoveToNextStep = item.OnTargetClickMoveToNextStep;
    singleStep.actiontarget.onStepClickTarget = item.OnStepClickTarget;

    singleStep.step = item.SequenceNumber;
    singleStep.title = item.Title;
    singleStep.content = item.Text;
    singleStep.selector = "";

    if (item.Selector) {
      singleStep.selector = item.Selector;
    }
    if (item.Image) {
      singleStep.useImages = true;
      singleStep.image = item.Image;
    }
    return singleStep
  }

  onTourShow(link: m5web.HelpLinkItemEditViewModel, $event) {
    let stepsArray = []
    let useImages = false;

    //Create step objects using stored settings from HelpLinkItemTourStepEditViewModel
    link.Tour.Steps.forEach(item => {
      let singleStep: any = this.createTourStep(item);
      if (singleStep.useImages) {
        useImages = true;
      }
      stepsArray.push(singleStep);
    })

    const tourguide = new Tourguide({
      //Define actions that we might want to use on a tour step
      onStep(step, type) {
        if (step.actiontarget.onStepClickTarget) {
          step.target.firstChild.click();
        }
      },
      onAction(step, e) {
        if (step.actiontarget.allowTargetClickEvent) {
          step.target.firstChild.click();
        }
        if (step.actiontarget.onTargetClickMoveToNextStep) {
          tourguide.next();
        }
      },
      root: document.body,
      preloadimages: useImages,
      steps: stepsArray
    });

    //Launch tour
    tourguide.start();
  }
}
