<ng-container *ngIf="isButtonVisible">

  <!-- just a button no options -->
  <button *ngIf="!button.options || button.options.length === 0"
          type="button"
          class="btn btn-{{button.contextColor}} btn-{{button.size}} {{blockClass}}"
          ngbTooltip="{{button.description}}"
          (click)="fireClick($event, button, null, -1)">
    <ib-icon icon="{{button.icon}}"
             label="{{button.label | translate}}"
             color="{{button.contextColorIcon}}"
             [iconOnlyWhenMobile]="true"></ib-icon>
  </button>

  <!-- button with options and no default action on the button itself -->
  <!--div style="width: 100%;"-->
  <div *ngIf="button.options && button.options.length > 0 && !button.action"
       class="d-inline-block"
       [placement]="button.menuPlacement"
       ngbDropdown
       container="body"
       display="dynamic"
       #dd="ngbDropdown">
    <button class="btn btn-{{button.contextColor}} btn-{{button.size}} {{blockClass}} dropdown-toggle"
            ngbTooltip="{{button.description | translate}}"
            [style]="buttonStyle | safe : 'style'"
            ngbDropdownAnchor
            (click)="dd.toggle()">
      <ib-icon icon="{{button.icon}}"
               label="{{button.label | translate}}"
               color="{{button.contextColorIcon}}"
               [iconOnlyWhenMobile]="true"></ib-icon>
    </button>
    <div class="dropdown-menu {{scrollHeightClass}}"
         ngbDropdownMenu>
      <div class="input-group input-group-sm"
           *ngIf="allowSearch">
        <input type="text"
               class="form-control ml-2 mr-2"
               style="max-width: 95%;"
               name="Search"
               placeholder="Search"
               [ngModelOptions]="{standalone: true}"
               [(ngModel)]="searchText">
      </div>
      <ng-container *ngFor="let option of button.options | filterPropertyValue : 'label' : searchText : '$'; let i = index">
        <ng-container *ngIf="isVisible(option)">
          <ng-container *ngIf="option.options && option.options.length > 0">
            <div class="btn-group"
                 role="group"
                 aria-label="Options"
                 style="padding: .25rem .25rem .25rem 1rem;">
              <ng-container *ngFor="let child of option.options | filterPropertyValue : 'label' : searchText : '$'; let i2 = index">
                <ng-container *ngIf="isVisible(child)">
                  <button type="button"
                          class="btn {{isActive(child)?'btn-secondary':'btn-default'}} btn-{{button.size}}"
                          style="padding: .2rem .4rem;"
                          ngbTooltip="{{child.description | translate}}"
                          container="body"
                          (click)="fireClick($event, button, child, i)">
                    <ib-icon icon="{{child.icon}}"
                             label="{{child.label | translate}}"
                             color="{{child.contextColorIcon}}"
                             [fixedWidth]="true"></ib-icon>
                  </button>
                </ng-container>
              </ng-container>
            </div>
            <span class="mr-1">{{option.label | translate}}</span>
          </ng-container>
          <ng-container *ngIf="!option.options || option.options.length === 0">
            <button *ngIf="( option.icon || option.label ) && option.type !== 'heading'"
                    class="dropdown-item btn-{{button.menuItemSize}}"
                    ngbTooltip="{{option.description | translate}}"
                    (click)="fireClick($event, button, option, i); dd.toggle();">
              <ib-icon icon="{{option.icon}}"
                       label="{{option.label | translate}}"
                       color="{{option.contextColorIcon}}"
                       [fixedWidth]="true"></ib-icon>
            </button>
          </ng-container>
          <div *ngIf="!option.icon && !option.label"
               class="dropdown-divider"></div>
          <div *ngIf="option.type === 'heading'"
               class="dropdown-header">
            <ib-icon icon="{{option.icon}}"
                     label="{{option.label | translate}}"
                     color="{{option.contextColorIcon}}"
                     [fixedWidth]="true"></ib-icon>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- button with options and also action on the button itself so split the button to get that default action -->
  <!-- not sure this div width style is desired: style="width: 100%;"-->
  <div *ngIf="button.options && button.options.length > 0 && button.action"
       class="btn-group">
    <button type="button"
            class="btn btn-{{button.contextColor}} btn-{{button.size}} {{blockClass}}"
            ngbTooltip="{{button.description | translate}}"
            (click)="fireClick($event, button, null, -1)">
      <ib-icon icon="{{button.icon}}"
               label="{{button.label | translate}}"
               color="{{button.contextColorIcon}}"
               [iconOnlyWhenMobile]="true"></ib-icon>
    </button>
    <div class="btn-group"
         [placement]="button.menuPlacement"
         ngbDropdown
         role="group"
         aria-label="Button group with nested dropdown"
         #dd="ngbDropdown">
      <!-- appDropdownAppendToBody -->
      <button class="btn btn-{{button.contextColor}} btn-{{button.size}} {{blockClass}} dropdown-toggle-split dropdown-toggle"
              ngbDropdownToggle></button>
      <div class="dropdown-menu {{scrollHeightClass}}"
           ngbDropdownMenu>
        <div class="input-group input-group-sm"
             *ngIf="allowSearch">
          <input type="text"
                 class="form-control ml-2 mr-2"
                 style="max-width: 95%;"
                 name="Search"
                 placeholder="Search"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="searchText">
        </div>
        <ng-container *ngFor="let option of button.options | filterPropertyValue : 'label' : searchText : '$'; let i = index">
          <ng-container *ngIf="isVisible(option)">
            <ng-container *ngIf="option.options && option.options.length > 0">
              <div class="btn-group"
                   role="group"
                   aria-label="Options"
                   style="padding: .25rem .25rem .25rem 1rem;">
                <ng-container *ngFor="let child of option.options | filterPropertyValue : 'label' : searchText : '$'; let i2 = index">
                  <ng-container *ngIf="isVisible(child)">
                    <button type="button"
                            class="btn {{isActive(child)?'btn-secondary':'btn-default'}} btn-{{button.size}}"
                            style="padding: .2rem .4rem;"
                            ngbTooltip="{{child.description | translate}}"
                            container="body"
                            (click)="fireClick($event, button, child, i)">
                      <ib-icon icon="{{child.icon}}"
                               label="{{child.label | translate}}"
                               color="{{child.contextColorIcon}}"
                               [fixedWidth]="true"></ib-icon>
                    </button>
                  </ng-container>
                </ng-container>
              </div>
              <span class="mr-1">{{option.label | translate}}</span>
            </ng-container>
            <ng-container *ngIf="!option.options || option.options.length === 0">
              <button *ngIf="( option.icon || option.label ) && option.type !== 'heading'"
                      class="dropdown-item btn-{{button.menuItemSize}}"
                      ngbTooltip="{{option.description | translate}}"
                      (click)="fireClick($event, button, option, i); dd.toggle();">
                <ib-icon icon="{{option.icon}}"
                         label="{{option.label | translate}}"
                         color="{{option.contextColorIcon}}"
                         [fixedWidth]="true"></ib-icon>
              </button>
            </ng-container>
            <div *ngIf="!option.icon && !option.label"
                 class="dropdown-divider"></div>
            <div *ngIf="option.type === 'heading'"
                 class="dropdown-header">
              <ib-icon icon="{{option.icon}}"
                       label="{{option.label | translate}}"
                       color="{{option.contextColorIcon}}"
                       [fixedWidth]="true"></ib-icon>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

</ng-container>
