<header class="{{nav.themeClasses}} ib-nav-top">

  <ng-container *ngIf="isMobile">
    <div class="float-left">
      <p-slideMenu #slidemenu
                   [model]="menu"
                   [popup]="true"
                   [viewportHeight]="350"></p-slideMenu>
      <button #btn
              class="btn btn-lg {{nav.themeClasses}}"
              style="margin: 5px;"
              (click)="makeVisible(); slidemenu.toggle($event)"><i class="far fa-bars"></i></button>
    </div>
  </ng-container>

  <div class="ib-nav-top-content">

    <div *ngIf="!isMobile"
         class="float-left">
      <a [routerLink]="['/']">{{title}}</a>
    </div>

    <div class="float-right">

      <!-- partition notice -->
      <div class="float-left"
           *ngIf="partitionNoticeIcon || partitionNoticeText">
        <div class="ib-nav-top-content-item">
          <span class="badge badge-{{partitionNoticeColor}}">
            <ib-icon [icon]="partitionNoticeIcon"
                     [label]="partitionNoticeText"></ib-icon>
          </span>
        </div>
      </div>


      <!-- search -->
      <div *ngIf="showSearch"
           class="float-left">
        <div *ngIf="searchConfigCount === 1"
             class="ib-nav-top-content-item pl-3 pr-3"
             style="font-size:1rem; padding-top:3px;">
          <a [routerLink]="['/' , 'search' , searchConfigs[0].ExternalSearchConfigurationId || searchConfigs[0].SearchConfigurationId]"><i class="fal fa-search"></i></a>
        </div>
        <div *ngIf="searchConfigCount > 1"
             class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #search="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  ngbTooltip="Search"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="search.toggle()">
            <i class="fal fa-search"></i>
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <button class="dropdown-item"
                    [routerLink]="['/' , 'search' , config.ExternalSearchConfigurationId || config.SearchConfigurationId]"
                    *ngFor="let config of searchConfigs">{{config.Description}}</button>
          </div>
        </div>
      </div>



      <!-- support -->
      <div *ngIf="showHelpMenu"
           class="float-left">
        <ib-help-button [helpTopic]="helpTopic"
                        dropDownWrapperClass="ib-nav-top-content-item"
                        dropDownButtonClass="ib-nav-top-content-button">
        </ib-help-button>
      </div>


      <!-- page history -->
      <div *ngIf="nav.showPageHistory && !isMobilePortrait"
           class="float-left">
        <div class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #pageHistory="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  ngbTooltip="History"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="pageHistory.toggle()">
            <i class="fal fa-history"></i>
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <button class="dropdown-item"
                    [routerLink]="[history.url]"
                    *ngFor="let history of nav.pageHistory.list">{{history.label}}</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    (click)="nav.pageHistory.clear()"><i class="fal fa-power-off"></i>&nbsp;&nbsp;Clear History</button>
            <div class="dropdown-divider"></div>
            <button *ngIf="nav.showBookmarkOption"
                    class="dropdown-item"
                    (click)="bookmarkPage()"><i class="fal fa-bookmark fa-fw"></i>&nbsp;&nbsp;Bookmark this page</button>
            <button *ngIf="nav.showSiteMapOption"
                    class="dropdown-item"
                    [routerLink]="['/system/site-map']"><i class="fal fa-sitemap fa-fw"></i>&nbsp;&nbsp;Site Map</button>
          </div>
        </div>
      </div>


      <!-- recent cases -->
      <div *ngIf="nav.showRecentCases && !isMobile"
           class="float-left">
        <div class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #recentCases="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  [ngbTooltip]="recentCasesTooltip"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="recentCases.toggle()">
            <i class="fal fa-folder-open"></i>
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <button class="dropdown-item"
                    [routerLink]="[case.url]"
                    *ngFor="let case of nav.recentCases.list">{{case.label}}</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    (click)="nav.recentCases.clear()"><i class="fal fa-power-off"></i>&nbsp;&nbsp;Clear</button>
          </div>
        </div>
      </div>


      <!-- recent customers -->
      <div *ngIf="nav.showRecentCustomers && !isMobile"
           class="float-left">
        <div class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #recentCustomers="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  ngbTooltip="Recent Customers"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="recentCustomers.toggle()">
            <i class="fal fa-address-card"></i>
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <button class="dropdown-item"
                    [routerLink]="[customer.url]"
                    *ngFor="let customer of nav.recentCustomers.list">{{customer.label}}</button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item"
                    (click)="nav.recentCustomers.clear()"><i class="fal fa-power-off"></i>&nbsp;&nbsp;Clear</button>
          </div>
        </div>
      </div>


      <!-- user options -->
      <div class="float-left">
        <div class="ib-nav-top-content-item d-inline-block"
             ngbDropdown
             placement="bottom-right"
             #userOptions="ngbDropdown">
          <button class="btn ib-nav-top-content-button"
                  [ngStyle]="{'padding-left': ( taskStatus.TotalCount ? '0.25rem' : 'inherit' ) }"
                  [ngbTooltip]="userName"
                  placement="left"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (click)="userOptions.toggle()">
            <ng-container *ngIf="!taskStatus.TotalCount">
              <ib-avatar *ngIf="user"
                         [contactId]="user.ContactId"
                         name="{{user.ContactName}}"
                         email="{{user.ContactEmail}}"
                         [tooltip]="false"
                         tooltipPlacement="top"
                         [size]="30"
                         [inline]="true"
                         (click)="userOptions.toggle();">
              </ib-avatar>
              <i *ngIf="!user"
                 class="fal fa-user-cog"></i>
            </ng-container>
            <span *ngIf="taskStatus.TotalCount">
              <ib-avatar *ngIf="user"
                         [contactId]="user.ContactId"
                         name="{{user.ContactName}}"
                         email="{{user.ContactEmail}}"
                         [tooltip]="false"
                         tooltipPlacement="top"
                         [size]="30"
                         [inline]="true"
                         (click)="userOptions.toggle();">
              </ib-avatar>
              <i *ngIf="!user"
                 class="fal fa-user-cog"></i>
              <span class="badge badge-pill badge-danger"
                    [ngClass]="{'badge-danger': taskStatus.TotalCountHighPriority, 'badge-info': !taskStatus.TotalCountHighPriority}"
                    style="font-size:xx-small; position: absolute; top: 10px; right: 20px;">
                {{taskStatus.TotalCount | number}}
              </span>
            </span>
            <!-- <i *ngIf="!taskStatus.TotalCount" class="fal fa-user-cog"></i>
            <span *ngIf="taskStatus.TotalCount" class="fa-stack fa-1x">
              <i class="fal fa-user-cog fa-stack-1x"></i>
              <span class="badge badge-pill badge-danger"
                [ngClass]="{'badge-danger': taskStatus.TotalCountHighPriority, 'badge-info': !taskStatus.TotalCountHighPriority}"
                style="font-size:xx-small; position: absolute; top: 0; right: 0;">
                {{taskStatus.TotalCount | number}}
              </span>
            </span> -->
          </button>
          <div ngbDropdownMenu
               aria-labelledby="dropdownManual">
            <ng-container *ngIf="nav.isLoggedIn">
              <button *ngIf="nav.showProfileOption"
                      class="dropdown-item"
                      [routerLink]="['/profile']"><i class="fal fa-user fa-fw"></i>&nbsp;&nbsp;Profile</button>
              <button *ngIf="nav.showMyAppSettingsOption"
                      class="dropdown-item"
                      [routerLink]="['/settings/my-app-settings']"><i class="fal fa-user-cog fa-fw"></i>&nbsp;&nbsp;My App Settings</button>
              <button *ngIf="nav.showTasksOption"
                      class="dropdown-item"
                      [routerLink]="['/tasks']">
                <i class="fal fa-check-square fa-fw"></i>&nbsp;&nbsp;Tasks
                <span *ngIf="taskStatus.TotalCountNotHighPriority"
                      class="badge badge-pill badge-info ml-1">{{taskStatus.TotalCountNotHighPriority | number}}</span>
                <span *ngIf="taskStatus.TotalCountHighPriority"
                      class="badge badge-pill badge-danger ml-1">{{taskStatus.TotalCountHighPriority | number}}</span>
              </button>
              <!-- when mobile portrait we hide history menu which is where sitemap normally lives so if mobile show it here -->
              <button *ngIf="isMobilePortrait && nav.showSiteMapOption"
                      class="dropdown-item"
                      [routerLink]="['/system/site-map']"><i class="fal fa-sitemap fa-fw"></i>&nbsp;&nbsp;Site Map</button>
              <div *ngIf="nav.showProfileOption || nav.showTasksOption || nav.showBookmarkOption"
                   class="dropdown-divider"></div>
              <button class="dropdown-item"
                      (click)="logout()"><i class="fal fa-sign-out fa-fw"></i>&nbsp;&nbsp;Logout</button>
            </ng-container>
            <ng-container *ngIf="!nav.isLoggedIn">
              <button class="dropdown-item"
                      [routerLink]="['/login']"><i class="fal fa-sign-in fa-fw"></i>&nbsp;&nbsp;Login</button>
            </ng-container>
          </div>
        </div>
      </div>


    </div>

    <div class="clearfix"></div>
  </div>
</header>
