<div *ngIf="htmlSignInPageTop"
     [innerHTML]="htmlSignInPageTop | safe : 'html'">
</div>
<div class="row"
     style="padding-top:20px;">
  <div class="{{Constants.Layout.percent25Offset}} {{Constants.Layout.split2column}}">
    <div class="card">
      <h5 class="card-header">Please Sign In</h5>
      <div class="card-body">
        <div *ngIf="htmlSignInAbove"
             [innerHTML]="htmlSignInAbove | safe : 'html'">
        </div>
        <ib-input-text name="Login"
                       label="Login"
                       [autofocus]="true"
                       [(ngModel)]="data.Login"></ib-input-text>
        <ib-input-text name="Password"
                       label="Password"
                       type="password"
                       (keyUpIsEnter)="login($event)"
                       [(ngModel)]="data.Password"></ib-input-text>
        <ib-input-checkbox name="RememberMe"
                           label="Remember Me"
                           [(ngModel)]="data.RememberMe"></ib-input-checkbox>
        <ib-input-wrapper label="">
          <ib-button color="success"
                     label="Login"
                     (click)="login($event)"></ib-button>
        </ib-input-wrapper>
        <div *ngIf="errorMessage"
             class="alert alert-danger"
             role="alert">
          {{errorMessage}}
        </div>
        <ib-input-wrapper label="">
          <a [routerLink]="['/login/forgot']">Forgot login?</a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a [routerLink]="['/login/password-reset']">Forgot password?</a>
        </ib-input-wrapper>
        <ib-input-wrapper *ngIf="showSignUpOption"
                          label="">
          Don't have an account? <a [routerLink]="['/subscriptions/new']">Sign Up Now</a>
        </ib-input-wrapper>
        <div *ngIf="htmlSignInBelow"
             [innerHTML]="htmlSignInBelow | safe : 'html'">
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="htmlSignInPageBottom"
     [innerHTML]="htmlSignInPageBottom | safe : 'html'">
</div>
