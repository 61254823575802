<ng-container *ngIf="typeahead">
  <ng-template #rt let-r="result" let-t="term">
    <ib-icon icon="{{r}}"></ib-icon>&nbsp;&nbsp;
    <ngb-highlight [result]="r" [term]="t"></ngb-highlight>
  </ng-template>
  <div class="{{inputWrapperClass}}">
    <div class="input-group-prepend">
      <span class="input-group-text" style="min-width:40px;">
        <ib-icon icon="{{icon}}"></ib-icon>
      </span>
    </div>
    <input type="text"
           class="form-control"
           id="iconpicker"
           name="Icon Picker"
           placeholder="{{placeholder | translate}}"
           [ngbTypeahead]="typeaheadSearch" [resultTemplate]="rt" [editable]="false"
           [disabled]="disabled ? 'disabled' : null"
           [readonly]="readonly ? 'readonly' : null"
           [(ngModel)]="icon"
           [ngModelOptions]="{standalone: true}"
           (change)="iconChanged($event)"
           #inputControl="ngModel" />
  </div>
</ng-container>
<ib-dropdown *ngIf="!typeahead"
             [options]="icons" [(ngModel)]="icon" [filter]="true" [readonly]="readonly" [disabled]="disabled"
             [virtualScroll]="true" [itemSize]="25" [style]="{'width':'100%'}"
             (onChange)="iconChanged($event)"
             (onClick)="clicked($event)">
  <ng-template let-item pTemplate="selectedItem">
    <i [class]="item.icon"></i>&nbsp;&nbsp;
    <span style="vertical-align:middle">{{item.label}}</span>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class="ui-helper-clearfix" style="position: relative;height:25px;">
      <i [class]="item.icon"></i>&nbsp;&nbsp;
      <span style="vertical-align:middle">{{item.label}}</span>
    </div>
  </ng-template>
</ib-dropdown>
